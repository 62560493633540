<form ngFormModel="form" (ngSubmit)="submit()" novalidate>
    <h2>
        Password Expired 
        <gol-loading-feedback *ngIf="loading" [inline]="true"></gol-loading-feedback> 
    </h2>    
    <div class="error" *ngIf="serverError">
        <p>{{serverError}}</p>
    </div>
    <div [formGroup]="passwordGroup">
        <div class="form-group">
            <label for="password">New Password</label>
            <input id="password" name="password" type="password" [formControl]="password" class="form-control"
                [(ngModel)]="newPassword" placeholder="New Password" autocomplete="off" />
            <div *ngIf="form.submitted && password.invalid" class="error" >
                <p *ngIf="password.hasError('required')">Required</p>
                <p *ngIf="password.hasError('passwordValidation')">
                    {{passwordValidationMessage(password.errors['passwordValidation'])}}
                </p>
            </div>
        </div>

        <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input id="confirmPassword" name="confirmPassword" type="password" [formControl]="confirmPassword"
                class="form-control" [(ngModel)]="confirmNewPassword" placeholder="Confirm Password"
                autocomplete="off" />
            <div *ngIf="form.submitted && (confirmPassword.invalid || passwordGroup.invalid)" class="error" >
                <p *ngIf="confirmPassword.hasError('required')">Required</p>
                <p *ngIf="passwordGroup.hasError('noMatch')">Passwords do not match</p>
            </div>
        </div>
    </div>
    <div class="buttonContainer">
        <button class="btn btn-primary" type="submit">Update Password</button>
    </div>
</form>

<rol-modal-dialog [show]="showConfirmation" (close)="showConfirmation = false">
    <div class="modal-body">
        <h1>Your password has been changed.</h1>
        <p>Click the Ok button to return to the login page.</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit" (click)="successAndReturn()">Ok</button>
    </div>
</rol-modal-dialog>