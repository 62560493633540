import { Injectable } from '@angular/core';
import { AuthenticatedHttp } from '../services/authenticated-http.service';
import { CONFIG } from '../../environments/environment';
import { ChangeLogModel } from '../models/changelog';
import * as _ from 'lodash';
import { CustomErrorHandler } from './custom-error-handler';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ChangeLogService  {

    private resourceUrl = CONFIG.apiBaseUri + 'changelog';

    constructor(private http: AuthenticatedHttp, private errorHandler:CustomErrorHandler) {
    }

    public getChangeLogs(id: number): Promise<ChangeLogModel[]> {
        const url = `${this.resourceUrl}?RefValue=${id}&RefType=ApplicationId`;
        return this.http.get(url, { headers: this.getJsonRequestHeaders() })
            .toPromise()
            .then((response) => {
                return _.map(response, v => new ChangeLogModel(v));
            })
            .catch(err => Promise.reject(this.errorHandler.handleError));
    }

    public saveChangeLog(log: ChangeLogModel){
        const url = `${this.resourceUrl}`;

        return this.http.post(url, JSON.stringify(log), { headers: this.getJsonRequestHeaders() })
        .toPromise()
        .then(response => {
            return response;
        })
        .catch(err => Promise.reject(this.errorHandler.handleError));
    }

    protected getJsonRequestHeaders() {
        return new HttpHeaders({ 'content-type': 'application/json' });
    }
}    