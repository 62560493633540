import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl } from '@angular/forms';

export function validateCheckboxFactory() {
  return (c: FormControl) => {
    if (!c.value) {
      return { required: true };
    } else {
      return null;
    }
  };
}

@Directive({
  selector: '[golValidateCheckbox][ngModel],[validate-checkbox][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CheckboxValidatorDirective), multi: true }
  ]
})
export class CheckboxValidatorDirective {

  validator: Function;

  constructor() {
    this.validator = validateCheckboxFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}
