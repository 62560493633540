import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../../../models/user';
import { TopForm } from '../../../form/top-form';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { PasswordChange } from '../../../models/password-change';
import { validatePasswordFactory } from '../../../form/validation/password-validator.directive';
import { passwordMatcher } from '../../../form/validation/password-matcher.validator';

@Component({
    selector: 'gol-preferences-password',
    templateUrl: 'preferences-password.component.html',
    styleUrls: ['../account-preferences.component.css']
})
export class PreferencesPasswordComponent implements OnInit {
    contactInfoForm: TopForm;
    changePasswordForm: TopForm;
    oldPassword: FormControl;
    newPasswordGroup: FormGroup;
    newPassword: FormControl;
    confirmPassword: FormControl;
    model: User;
    password: PasswordChange;
    passwordChangeComplete = false;
    contactInfoChangeComplete = false;
    changePasswordError: string;
    updateInfoError: string;

    constructor(private userService: UserService, private authService: AuthService) {
        this.model = new User(userService.GetUser());
        this.password = new PasswordChange();
    }

    ngOnInit() {
        this.resetChangePasswordForm();
        window.scrollTo(0, 0);
    }

    passwordValidationMessage(errorObj) {
        let message = 'Password is invalid: ';
        // tslint:disable-next-line: forin
        for (const error in errorObj) {
            message += errorObj[error] + '; ';
        }
        return message;
    }

    resetChangePasswordForm() {
        this.passwordChangeComplete = false;
        this.password = new PasswordChange();

        this.changePasswordForm = new TopForm();
        this.oldPassword = new FormControl('', [Validators.required]);
        this.newPassword = new FormControl('', [Validators.required, validatePasswordFactory()]);
        this.confirmPassword = new FormControl('', [Validators.required]);
        this.newPasswordGroup = new FormGroup({
            'newPassword': this.newPassword,
            'confirmPassword': this.confirmPassword
        }, passwordMatcher);

        this.changePasswordError = null;
        this.changePasswordForm.addControl('oldPassword', this.oldPassword);
        this.changePasswordForm.addControl('newPasswordGroup', this.newPasswordGroup);
    }

    changePasswordSubmit() {
        this.authService.ChangePassword(this.password)
            .then(res => {
                this.passwordChangeComplete = true;
            })
            .catch(rejected => {
                this.password.oldPassword = '';
                this.changePasswordError = rejected;
            });
    }
}
