import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TopForm } from '../top-form';
import { AuthService, IAuthService } from '../../services/auth.service';
import { CurrentApplicationService } from '../../services/current-application.service';
import { Router } from '@angular/router';
import { Application } from '../../models/application';
import { ApplicationType } from '../../models/application-type';
import { NavigationOptions } from '../../models/navigation-options';
import { Person } from '../../models/person';

@Component({
    selector: 'gol-preapproval',
    templateUrl: 'preapproval.component.html',
    styleUrls: ['preapproval.component.css']
})
export class PreApprovalComponent {
    form: TopForm;
    name: FormControl;
    code: FormControl;
    serverError = '';
    recaptchaToken = '';
    submitting: boolean;
    application: Application;

    constructor(private authService: AuthService, private currentAppService: CurrentApplicationService, private router: Router) {
        this.form = new TopForm();
        this.name = new FormControl('', Validators.required);
        this.code = new FormControl('', Validators.required);
        this.form.addControl('name', this.name);
        this.form.addControl('password', this.code);
        this.currentAppService.makeNewApp();
        this.currentAppService.getCurrentApplication('PreApprovalComponent - Cstr').then(app => {
            this.application = app;
        });
    }

    submit() {
        this.form.submitted = true;
        this.serverError = '';

        if (this.recaptchaToken === '') {
            return;
        }

        if (this.form.valid) {
            this.submitting = true;
            this.authService.ValidatePreApproval(this.code.value, this.name.value)
                .then((res) => {
                    if (res >= 0) {
                        this.proceed(res);
                    } else {
                        // tslint:disable-next-line: max-line-length
                        this.serverError = 'The information you entered is invalid or may have expired.  Prescreened Offer Acceptance Codes are case sensitive please verify your information.  If you continue to have problems, please contact our customer service team at 888-395-8505.  If you would like to complete an application, please click "Apply Here" at the top of the page.';
                    }
                })
                .catch((error) => {
                    this.serverError = error;
                })
                .then(() => {
                    this.submitting = false;
                });
        }
    }

    cancel() {
        this.router.navigateByUrl('/home');
    }

    recaptchaResolved(token: string) {
        this.recaptchaToken = token;
    }

    proceed(stagingId: number) {
        this.application.entity = null;
        this.application.appType = ApplicationType.Single;
        const startpage = NavigationOptions.AppPages(this.application, true)[0];
        this.application.currentPage = startpage.alias;
        this.application.maxPageReached = startpage.alias;
        this.application.preApprovalStagingId = stagingId;
        this.application.preApprovalCode = this.code.value;
        this.currentAppService.setCurrentApplication(this.application);
        this.currentAppService.getPreApprovalStagingForApplication(stagingId)
            .then(res => {
                this.application.borrowers.push(res);

                // due to complex types being pass by reference, I need to state the values explicitly
                this.application.preApprovalBorrower = new Person();
                this.application.preApprovalBorrower.fullName.firstName = res.fullName.firstName;
                this.application.preApprovalBorrower.fullName.lastName = res.fullName.lastName;
                this.application.preApprovalBorrower.fullName.middleName = res.fullName.middleName;
                this.application.preApprovalBorrower.fullName.suffix = res.fullName.suffix;
                this.application.preApprovalBorrower.isPreApproved = res.isPreApproved;

                this.application.preApprovalBorrower.mailingAddress.city = res.mailingAddress.city;
                this.application.preApprovalBorrower.mailingAddress.line1 = res.mailingAddress.line1;
                this.application.preApprovalBorrower.mailingAddress.line2 = res.mailingAddress.line2;
                this.application.preApprovalBorrower.mailingAddress.state = res.mailingAddress.state;
                this.application.preApprovalBorrower.mailingAddress.zipcode = res.mailingAddress.zipcode;
                this.application.preApprovalBorrower.physicalAddress = this.application.preApprovalBorrower.mailingAddress;
                this.application.preApprovalBorrower.physicalAddress.isPhysicalAddress();

                this.router.navigate([startpage.route]);
            });
    }
}
