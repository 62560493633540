import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../../../../environments/environment';

@Component({
    selector: 'gol-preferences-email',
    templateUrl: 'preferences-email.component.html',
    styleUrls: ['../../../../app/dashboard/preferences/account-preferences.component.css', 'preferences-email.component.css']
})
export class PreferencesEmailComponent implements OnInit {
    b2cFrameSource: string =  CONFIG.b2cEmailChangeFrame.src;
    b2cFrameHeight: string = CONFIG.b2cEmailChangeFrame.height;

    constructor() { }

    ngOnInit() {
        window.scroll(0, 0);
    }
}
