import { Component, OnInit } from '@angular/core';
import { CONFIG } from '../../../../environments/environment';

//TODO:MFA - Don't think this will be able to extend ResetPasswordComponentV1

@Component({
  selector: "gol-reset-password",
  templateUrl: "reset-password.component.html",
  styleUrls: ["reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  shouldHideFrame = true;
  b2cFrameSource: string = CONFIG.b2cPasswordResetFrame.src;
  b2cFrameHeight: string = CONFIG.b2cPasswordResetFrame.height;

  constructor() {}

  ngOnInit() {}
}
