import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AccountHttpService } from '../../../services/account-http.service';
import { TransactionQuery } from '../../../models/accounts/transaction-query';
import { TransactionReport } from '../../../models/accounts/transaction-report';

@Component({
    selector: 'gol-transaction-report',
    templateUrl: 'transaction-report.component.html',
    styleUrls: ['transaction-report.component.css']
})
export class TransactionReportComponent implements OnInit {

    loanNumber: string;
    loading: boolean;
    query: TransactionQuery;
    transactionReport: TransactionReport;
    datesInvalid: boolean;
    error: boolean;

    constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountHttpService) { }

    ngOnInit() {
        this.loanNumber = this.route.snapshot.parent.params['accountId'];
        this.transactionReport = new TransactionReport();
        this.query = new TransactionQuery();
        this.query.LoanNumber = this.loanNumber;
        this.getTransactionReport();
    }

    clearQuery() {
        this.query.MinimumDate = undefined;
        this.query.MaximumDate = undefined;
        this.query.Type = undefined;
        this.query.Program = undefined;
        this.datesInvalid = false;
    }

    dateChange() {
        if (this.query.MinimumDate && this.query.MaximumDate && this.query.MinimumDate > this.query.MaximumDate) {
            this.datesInvalid = true;
        } else {
            this.datesInvalid = false;
        }
    }

    getTransactionReport() {
        this.error = false;
        this.loading = true;
        this.accountService.getTransactionReport(this.query)
            .then((result) => {
                this.transactionReport = result;
            })
            .catch((err) => {
                this.error = true;
            })
            .then(() => this.loading = false);
    }
}
