<div class="topPadding"></div>
<div *ngIf="!passwordExpired">
    <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="!question">
        <h2>
            Login
            <gol-loading-feedback *ngIf="submitting" [inline]="true"></gol-loading-feedback>
        </h2>
        <div class="form-group">
            <label for="email">Email</label>
            <input name="email" [formControl]="email" class="form-control" [autofocus] placeholder="Email" />
            <div class="error" *ngIf="(form.submitted || email.dirty) && email.invalid">
                <p *ngIf="email.errors['required']">Required</p>
            </div>
        </div>

        <div class="form-group">
            <label for="password">Password</label>
            <input name="password" type="password" [formControl]="password" class="form-control" placeholder="Password"
                autocomplete="off" />
            <div class="error" *ngIf="(form.submitted || password.dirty) && password.invalid">
                <p *ngIf="password.errors['required']">Required</p>
            </div>
        </div>
        <div class="error" *ngIf="serverError">
            <p>{{serverError}}</p>
        </div>
        <div class="buttonContainer">
            <button class="btn btn-primary" type="submit" [disabled]="submitting">Login</button>
            <button *ngIf="!hideCancel" type="button" class="btn btn-secondary" (click)="cancel()" data-dismiss="modal"
                [disabled]="submitting">Cancel</button>
        </div>
    </form>
    <form ngFormModel="form" novalidate *ngIf="question">
        <h2>
            Login - Security Question
            <gol-loading-feedback *ngIf="submitting" [inline]="true"></gol-loading-feedback>
        </h2>
        <gol-security-question [email]="email.value" [password]="password.value" [securityQuestion]="question" [loginMessage]="loginMessage"
            (answerUpdated)="answerUpdated($event)"></gol-security-question>
        <gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
        <div class="error" *ngIf="serverError">
            <p>{{serverError}}</p>
        </div>
        <div class="buttonContainer">
            <button class="btn btn-primary" type="submit" (click)="submitWithQuestion()"
                [disabled]="submitting">Login</button>
            <button *ngIf="!hideCancel" type="button" class="btn btn-secondary" (click)="cancel()"
                [disabled]="submitting">Cancel</button>
        </div>
    </form>


</div>
<gol-password-expired *ngIf="passwordExpired" [email]="email.value" [oldPassword]="password.value"
    (onPasswordUpdated)="onExpiredPasswordUpdated($event)"></gol-password-expired>