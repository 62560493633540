<div class="container stage main_container">
    <gol-side-nav selectedAlias="{{pageAlias}}" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Ownership Information</h1>

        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div class="row">
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div>
                <p class="text-small">All fields are required unless otherwise noted.</p>
            </div>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <div class="initial-questions">
                <div class="form-group">            
                    <label>Are there any entities who have ownership in the legal entity?</label>
                    <select [ngModel]="application && application.hasEntityOwnership" name="entitiesWithOwnership" required class="form-control" (ngModelChange)="onEntityChanged($event)">
                        <option [ngValue]="null"></option>
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>
                </div>
                <div *ngIf="application && application.hasEntityOwnership === true" class="form-group">
                    <label>Is there a Trust in the structure?</label>
                    <select [ngModel]="application.hasTrust" name="trustInOrgStructure" required class="form-control" (ngModelChange)="onTrustChanged($event)">
                        <option [ngValue]="null"></option>
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>            
                </div>
                <div *ngIf="application && application.hasEntityOwnership === true && application.hasTrust === true" class="form-group">
                    <label>Are there any owners and IO's greater than 10%?</label>
                    <select [(ngModel)]="application.hasIosWithPercent" name="iosWithPercent" required class="form-control">
                        <option [ngValue]="null"></option>
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>     
                </div>
        
                <div *ngIf="application && application.hasEntityOwnership === false || (application && application.hasEntityOwnership === true && application.hasTrust == false)" class="form-group">            
                    <label>Are there any individuals with more than 25%?</label>
                    <select [(ngModel)]="application.hasIndividualsWithPercent" name="individualsWithPercent" required class="form-control">
                        <option [ngValue]="null"></option>
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>
                </div>

                <div *ngIf="form && form.submitted && !areAllInitialQuestionsAnswered" class="error">
                    Please answer these initial ownership questions.
                </div>
            </div>

            <div class="disclaimer" *ngIf="application && areAllInitialQuestionsAnswered">
                <div *ngIf="application && application.hasEntityOwnership === true">
                    <h4>
                        Please provide a signed and dated copy of the legal entity's organizational chart including ownership 
                        percentages throughout the layers and countries associated with each entity and individual owning 
                        more than 25%.
                        
                        If there is a Trust in the Organizational Structure, please identify Entities and Individuals that own 
                        greater than 10% and complete the Trustee Certification form.
                    </h4>
                </div>
                
                <div *ngIf="application.hasEntityOwnership == false">
                    <h4>
                        Any Persons opening an account on behalf of a legal entity must provide the following information 
                        for each individual who directly or indirectly, through any contract, arrangement, understanding, 
                        relationship or otherwise any of the following:
                    </h4>
                    <ul>
                        <li>Owns more than 25% of the equity interests of the legal entity</li>
                        <li>Has more than 25% of the voting rights of the legal entity</li>
                        <li>Receives more than 25% of the profits of the legal entity</li>
                        <li>Any other individuals who regularly perform similar functions</li>
                        <li>Otherwise exercises effective control</li>
                    </ul>
                </div>

                Why is this required?
                <gol-help-tip>
                    <p class="text-small">
                        To help the government fight financial crime, Federal regulation requires certain financial 
                        institutions to obtain, verify, and record information about the beneficial owners of legal 
                        entity customers. Legal entities can be abused to disguise involvement in terrorist financing, 
                        money laundering, tax evasion, corruption, fraud, and other financial crimes. Requiring the 
                        disclosure of key individuals who own or control a legal entity (i.e., the beneficial owners) 
                        helps law enforcement investigate and prosecute these crimes.
                    </p>
                </gol-help-tip>
            </div>

            <h4>Owners:</h4>
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                <p-accordion (onClose)="tabClose($event.index)" (onOpen)="tabOpen($event.index)">
                    <p-accordionTab *ngFor="let o of application.ownersList; let i=index;" [selected]="isOpen[i]">
                        <p-header [ngClass]="{'error': form.submitted && !formGroups[i].valid, 'ng-invalid': form.submitted && !formGroups[i].valid && !isOpen[i]}">
                            <span *ngIf="!showDisplayName(o)" class="new">(new)</span>
                            <span *ngIf="showDisplayName(o)">{{o.fullName.firstName}} {{o.fullName.lastName}}</span>
                            <span width="100%">
                                {{o.ownershipPercentage/100 | percent:'1.0-2'}}
                            </span>
                            <span *ngIf="!o.isBorrower" class="actionicon" (click)="delete(i, $event)">
                                Delete
                                <i class="glyphicon fa fa-trash-o"></i>
                            </span>
                        </p-header>
                        <gol-owner-detail [owner]="o" [form]="form" [group]="formGroups[i]" [readonly]="o.isBorrower"
                            mode="borrower"></gol-owner-detail>
                    </p-accordionTab>
                </p-accordion>

                <h4>
                    Total Ownership: {{application.totalOwnership()/100 | percent:'1.0-2'}}
                    <div class="alert alert-danger ng-invalid" *ngIf="!isTotalOwnershipValid()">
                        Total ownership must not be over 100%
                    </div>
                </h4>

                <div class="group">
                    <button *ngIf="areAllInitialQuestionsAnswered" type="button" class="btn btn-primary" (click)="add(false)">Add Owner</button>
                </div>

                <div class="panel signature-selection">
                    <h2>Who is authorized to sign on behalf of {{application.entity.legalName || 'this entity'}}?</h2>
                    <p>We will send this person a separate e-mail with instructions to sign the application.</p>
                    <select class="form-control" [(ngModel)]="signerSelect" (ngModelChange)="onSignerChange()" name="signee" required>
                        <option selected value="" disabled style="display:none"></option>
                        <option *ngFor="let signee of possibleSigners" [ngValue]="signee">
                            {{signee.fullName.firstName}} {{signee.fullName.lastName}}
                        </option>
                    </select>
                    <div *ngIf="form.submitted && !application.entity.signer" class="error">
                        Select someone to sign for {{application.entity.legalName || 'this entity'}}
                    </div>                    
                </div>
                
                <div class="group">
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                        (click)="previous()">Back</button>
                    <button class="btn btn-primary" type="submit">Continue</button>
                </div>
            </form>
        </div>
        <div class="col-sm-1"></div>
        <gol-contact-component></gol-contact-component>
    </div>
    <div style="clear:both"></div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>