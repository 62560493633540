import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { Vendor } from '../../models/vendor';
import { HistoryService } from '../../services/history.service';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-vendor',
    templateUrl: 'vendor.component.html',
    styleUrls: ['../styles_form.css', 'vendor-programs.component.css']
})
export class VendorComponent extends AbstractFormPage {
    isOpen: boolean[];
    accordionMap: number[];
    formGroups: FormGroup[];
    form: TopForm;
    preApproved = false;
    showModal = false;
    containerClass='';

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService, notificationService: NotificationService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'program';
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
        this.initPage();
    }

    afterInitPage() {
        this.form = new TopForm();
        if (this.application.vendors.length === 0) {
            this.add();
        } else {
            let opened = false;
            for (let i = 0; i < this.application.vendors.length; ++i) {
                if (!opened && !this.application.vendors[i].vendorName) {
                    opened = true;
                    this.isOpen.push(true);
                } else {
                    this.isOpen.push(false);
                }
                this.accordionMap.push(i);
                const group = new FormGroup({});
                this.formGroups.push(group);
                this.form.addControl(this.accordionMap.length.toString(), group);
            }
        }

        if (this.application.borrowers[0]) {
            this.preApproved = this.application.borrowers[0].isPreApproved;
        }
    }

    setClass(isSubmitted:boolean, index: number):string{
        if(isSubmitted && !(this.formGroups[index] && this.formGroups[index].valid)){
            this.containerClass = 'error';
        }
        if( !(this.formGroups[index] && this.formGroups[index].valid) && !this.isOpen[index]){
            this.containerClass = 'ng-invalid';
        }
        return this.containerClass;
    }

    add() {
        this.application.vendors.push(new Vendor());
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        this.formGroups.push(group);
        this.form.addControl(this.accordionMap.length.toString(), group);
        window.scrollTo(0, 0);
    }

    delete(i: number, e: MouseEvent) {
        this.application.vendors.splice(i, 1);
        this.isOpen.splice(i, 1);
        this.formGroups.splice(i, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === i) {
                found = true;
                this.form.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        e.preventDefault();

        if (this.application.vendors.length === 0) {
            this.add();
        }
    }

    tabOpen(e) {
        this.isOpen[this.accordionMap[e.index]] = true;
    }

    tabClose(e) {
        this.isOpen[this.accordionMap[e.index]] = false;
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    hasNonWhitespace(s) {
        return /[^\s]/g.test(s);
    }

    showDisplayName(vendor: Vendor): boolean {
        return this.hasNonWhitespace(vendor.vendorName);
    }

    getTotal(total, num): number {
        return total + num;
    }

    submit() {
        if (this.isValidForm()) {
            this.next();
        }
    }

    previous() {
        if (this.isValidForm()) {
            this.back();
        }
    }

    isValidForm() {
        const comp = this;
        comp.form.updateValueAndValidity();
        comp.form.submitted = true;
        if (comp.form.valid) {
            if (comp.form.valid && comp.application.preApprovalStagingId > 0 && !comp.comparePreApprovalData()) {
                comp.showModal = true;
                return false;
            }
            return true;
        } else {
            comp.scrollToError();
            return false;
        }
    }


    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string) {
        return new Promise<boolean>((resolve, reject) => {
            const comp = this;
            if (comp.isValidForm()) {
                comp.application.currentPage = newPageAlias;
                resolve(comp.save());
            } else {
                reject(false);
            }
        });
    }

    comparePreApprovalData() {
        const defaults = this.application.preApprovalVendor;
        if (this.application.vendors[0].vendorId !== defaults.vendorId) {
            return false;
        }

        if (this.application.vendors[0].creditLimit > 200000) {
            return false;
        }

        return true;
    }

    restorePreApprovalDefaults() {
        const defaults = this.application.preApprovalVendor;

        if (this.application.vendors[0]) {
            this.application.vendors.splice(0, 1);
            this.application.vendors.push(defaults);
        }

        this.application.vendors[0].creditLimit = 0;
        this.showModal = false;
    }

    ProceedWithoutPreApproval() {
        this.application.preApprovalStagingId = null;
        this.application.borrowers[0].isPreApproved = false;
        this.submit();
    }
}
