import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { Commitment } from '../../../../app/models/accounts/commitment'; // TODO:MFA
import { AccountHttpService } from '../../../../app/services/account-http.service'; // TODO:MFA
import { UserService } from '../../../../app/services/user.service'; // TODO:MFA
import { User } from '../../../../app/models/user'; // TODO:MFA
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-account-summary',
    templateUrl: '../../../../app/dashboard/home/account-summary/account-summary.component.html', // TODO:MFA
    styleUrls: ['../../../../app/dashboard/home/account-summary/account-summary.component.css'] // TODO:MFA
})
export class AccountSummaryComponent implements OnInit {
    accounts: Commitment[];
    linking: boolean;
    loading: boolean;
    user: User;
    @Output() hasAccount: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() loadingAccounts: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private router: Router, private accountService: AccountHttpService, private userService: UserService, private notifySvc: NotificationService) { }

    ngOnInit(): void {
        this.user = this.userService.GetUser();
        if (this.user != null) {
            this.getAccountSummaries();
        }
    }

    accountLinked(linkedData: Object) {
        const cifno = linkedData['cifno'];
        if (cifno > 0) {
            this.linking = false;
            this.loading = true;
            this.user.cifno = cifno;
            this.userService.SetUser(this.user);
            this.accountService.linkAccount(linkedData['accountNumber'], linkedData['ssn'], linkedData['zip'])
                .then((result) => {
                    if (result) {
                        this.getAccountSummaries();
                    }
                });
        }
    }

    getAccountSummaries() {
        this.loading = true;
        this.loadingAccounts.emit(true);
        this.accountService.getAccounts()
            .then(accts => {
                this.accounts = accts;
                if (this.accounts?.length > 0) {
                    this.hasAccount.emit(true);
                }
                this.loading = false;
                this.loadingAccounts.emit(false);
            })
            .catch(err => {
                this.loading = false;
                this.loadingAccounts.emit(false);

                const wait = new Promise(resolve => setTimeout(resolve, 1000));
                wait.then(res => {
                    this.notifySvc.push({
                        severity: 'error',
                        summary: 'Error retrieving accounts',
                        detail: err?.statusText == 'Unauthorized' || err?.status == 401 ? 'Authorization Failed' : 'There was a problem communicating with the server.',
                        life: 12000
                    });
                });

                if (err?.statusText == 'Unauthorized' || err?.status == 401){
                     this.userService.Logout();
                     this.router.navigate(['/home']);
                }
            });
    }

    getUserRelationship(account: Commitment): string {
        if (this.user && this.user.cifno) {
            const userCifno = `${this.user.cifno}`;

            // tslint:disable-next-line: triple-equals
            if (account.AccountHolder.CifNo == userCifno) {
                return account.AccountHolder.Relationship || 'Primary Borrower';
            }

            // tslint:disable-next-line: triple-equals
            if (account.Relationships.find(customer => customer.CifNo == userCifno)) {
                return account.Relationships.find(customer => customer.CifNo == userCifno)
                    .Relationship;
            }

            if (account.OwnershipRelationships) {
                return account.OwnershipRelationships.find(customer => customer.CifNo == userCifno)
                    .Relationship;
            }
        }
        return '';
    }
}
