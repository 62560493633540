import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CONFIG } from '../../environments/environment';
import { EventModel } from '../models/event';


@Injectable()
export class LoggingService {
    constructor(private http: HttpClient) { }

    recordError(path: string, error: string, stackTrace: string, userId: string): Promise<void> {
        const apiUrl = CONFIG.apiBaseUri + 'Logger/Error';
        return this.record(apiUrl, path, error, stackTrace, userId);
    }

    recordInfo(path: string, error: string, stackTrace: string, userId: string): Promise<void> {
        const apiUrl = CONFIG.apiBaseUri + 'Logger/Info';
        return this.record(apiUrl, path, error, stackTrace, userId);
    }

    recordWarn(path: string, error: string, stackTrace: string, userId: string): Promise<void> {
        const apiUrl = CONFIG.apiBaseUri + 'Logger/Warn';
        return this.record(apiUrl, path, error, stackTrace, userId);
    }

    private record(apiUrl: string, path: string, error: string, stackTrace: string, userId: string): Promise<void> {
        const event = new EventModel();
        try {
            event.website = CONFIG.websiteName;
            event.path = path;
            event.error = error;
            event.stackTrace = stackTrace;
            event.userId = userId;
            event.userAgent = navigator.userAgent;

            return this.http.post(apiUrl, event, { responseType: 'text' })
                .toPromise()
                .then(_ => null);
        } catch(err) {
            // failure to log should be silent.
            console.error('An error occurred in the logging service.');
            return new Promise<void>(() => null);
        }
    }
}
