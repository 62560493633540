import { FullName } from '../../models/fullname';
import { Component, Input, forwardRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { NgModel, NgForm, FormGroup } from '@angular/forms';
import { TopForm } from '../top-form';

@Component({
    selector: 'gol-fullname-component',
    templateUrl: 'fullname.component.html',
})
export class FullNameComponent implements AfterViewInit {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;
    @Input() form: TopForm;
    @Input() group: FormGroup;

    @Input()
    Title: string = 'Full Name';
    @Input()
    disabled: boolean = false;

    @Input()
    fullName: FullName;
    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            useGroup.addControl(this.Title + "_" + item.name, item.control);
        });
    }
}
