<div class="container stage main_container">
    <gol-side-nav selectedAlias="{{pageAlias}}" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Review Application</h1>
        </div>
    </div>
    <hr />
    <div class="row">
    </div>
    <div class="row">
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application" class="summary-form">
                <div *ngIf="showEntity()" class="summary-section">
                    <h2>Entity Information</h2>
                    <ul class="border-layout">
                        <li><label>Legal Name</label> 
                            <span class="summary-indent">{{application.entity.legalName}}</span></li>
                        <li>
                            <label><span *ngIf='!application.entity.mailingAddress.isPhysicalAddress()'>Mailing </span>
                                Address</label> 
                                <span class="summary-indent">{{application.entity.mailingAddress.toString()}}</span>
                        <li>
                        <li *ngIf='!application.entity.mailingAddress.isPhysicalAddress()'>
                            <label>Physical Address</label> 
                            <span class="summary-indent">{{application.entity.physicalAddress.toString()}}</span>
                        </li>
                        <li><label>TaxId</label> 
                            <span class="summary-indent">{{application.entity.taxId}}</span></li>
                        <li><label>Type</label> 
                            <span class="summary-indent">{{application.entity.entityType}}</span></li>
                        <li><label>Phone</label> 
                            <span class="summary-indent">{{application.entity.phoneNumber}}</span></li>
                        <li><label>State of Organization</label> 
                            <span class="summary-indent">{{ application.entity.stateOfOrganization}}</span></li>
                        <li><label>Date of Organization</label> 
                            <span class="summary-indent">{{displayDate(application.entity.dateOfOrganization)}}</span>
                        </li>
                    </ul>
                    <h3 class="head-space">Owners - {{application.totalOwnership()}}% recorded ownership
                        <select name="ownerName" class="form-control" [(ngModel)]="selectedOwner" *ngIf="ownersToDisplay.length >= 1">
                            <option *ngFor="let owner of ownersToDisplay" [ngValue]="owner">{{owner.fullName.toString()}} - {{owner.ownershipPercentage}}%</option>
                        </select>
                    </h3>
                    <ul *ngIf="selectedOwner" class="border-layout">
                        <li><label>Name</label> 
                            <span class="summary-indent">{{selectedOwner.fullName.toString()}}</span></li>
                        <li><label>Date of Birth</label> 
                            <span class="summary-indent">{{displayDate(selectedOwner.dateOfBirth)}}</span></li>
                        <li><label>Country of Permanent Residence</label> 
                            <span class="summary-indent">{{selectedOwner.countryOfPermanentResidence}}</span></li>
                        <li *ngIf="!selectedOwner.isControllingParty"><label>Ownership</label> 
                            <span class="summary-indent">{{selectedOwner.ownershipPercentage/100 | percent:'1.0-2'}}</span></li>
                        <li><label>Company Role/Title</label> 
                            <span class="summary-indent">{{selectedOwner.signerTitle}}</span></li>
                        <li><label>Email</label> 
                            <span class="summary-indent">{{selectedOwner.email}}</span></li>
                    </ul>
                </div>
                <div *ngIf="showEntity() && application.entity.signer && application.entity.signerTitle" class="summary-section">
                    <h2>Signer</h2>
                    <p class="emphasize">
                        <span class="summary-indent">{{application.entity.signer.fullName}} ({{application.entity.signerTitle}})
                        will sign for {{application.entity.legalName}}</span></p>
                    <ul *ngIf="entityIsOther(application)" class="border-layout">
                        <li><label>Name</label> 
                            <span class="summary-indent">{{application.entity.signer.fullName.toString()}}</span></li>
                        <li><label>Physical Address</label> 
                            <span class="summary-indent">{{getOwnerAddress(application.entity.signer).toString()}}</span>
                        </li>
                        <li><label>Social Security Number</label> 
                            <span class="summary-indent">{{application.entity.signer.ssn}}</span></li>
                        <li><label>Email</label> 
                            <span class="summary-indent">{{application.entity.signer.email}}</span></li>
                        <li><label>Date of Birth</label> 
                            <span class="summary-indent">{{displayDate(application.entity.signer.dateOfBirth)}}</span></li>
                    </ul>
                </div>
                <div class="summary-section">
                    <h3 class="head-space">
                        Borrower Information
                        <select name="borrowerName" class="form-control" [(ngModel)]="selectedBorrower" *ngIf="application.borrowers.length >= 1">
                            <option *ngFor="let borrower of application.borrowers" [ngValue]="borrower">
                                {{borrower.fullName.toString()}}</option>
                        </select>
                    </h3>
                    <ul *ngIf="selectedBorrower" class="border-layout">
                        <li><label>Name</label>
                            <span class="summary-indent">{{selectedBorrower.fullName.toString()}}</span></li>
                        <li>
                            <label><span *ngIf='!selectedBorrower.mailingAddress.isPhysicalAddress()'>Mailing </span>
                                Address</label> 
                                <span class="summary-indent">{{selectedBorrower.mailingAddress.toString()}}</span>
                        <li>
                        <li *ngIf='!selectedBorrower.mailingAddress.isPhysicalAddress()'>
                            <label>Physical Address</label> 
                            <span class="summary-indent">{{selectedBorrower.physicalAddress.toString()}}</span>
                        </li>
                        <li><label title="Social Security Number">SSN</label> 
                            <span class="summary-indent">{{selectedBorrower.ssn}}</span></li>
                        <li><label>Email</label> 
                            <span class="summary-indent">{{selectedBorrower.email}}</span></li>
                        <li><label>Date of Birth</label> 
                            <span class="summary-indent">{{displayDate(selectedBorrower.dateOfBirth)}}</span></li>
                        <li><label>Phone Number</label> 
                            <span class="summary-indent">{{selectedBorrower.phoneNumber}}</span></li>
                        <li><label title="Alternate Phone">Alt. Phone</label> 
                            <span class="summary-indent">{{selectedBorrower.alternatePhoneNumber}}</span>
                        </li>
                        <li>
                            <label>US Citizen</label>
                            <span class="summary-indent">
                            <i *ngIf="selectedBorrower.isUSCitizen" title="You are a US Citizen" class="fa fa-check-square green"></i>
                            <i *ngIf="!selectedBorrower.isUSCitizen" title="You are not a US Citizen" class="fa fa-times-circle red"></i>
                            </span>
                        </li>
                        <li *ngIf="!selectedBorrower.isUSCitizen">
                            <label>Permanent Resident</label>
                            <span class="summary-indent">
                            <i *ngIf="selectedBorrower.isPermanentResident" title="You are a Permanent Resident" class="fa fa-check-square green"></i>
                            <i *ngIf="!selectedBorrower.isPermanentResident" title="You are not a Permanent Resident" class="fa fa-times-circle red"></i>
                            </span>
                        </li>
                        <li *ngIf="!selectedBorrower.isUSCitizen">
                            <label>Country of Citizenship</label> 
                            <span class="summary-indent">{{selectedBorrower.countryOfCitizenship}}</span></li>
                        <li *ngIf="!selectedBorrower.hasBankruptcy"><label>Bankruptcy</label>
                            <span class="summary-indent"> No</span>
                        <li>
                        <li *ngIf="selectedBorrower.hasBankruptcy"><label>Bankruptcy Filing Date</label>
                            <span class="summary-indent">{{displayDate(selectedBorrower.bankruptcyFilingDate)}}</span></li>
                        <li *ngIf="selectedBorrower.hasBankruptcy"><label>Bankruptcy Discharge Date</label>
                            <span class="summary-indent">{{displayDate(selectedBorrower.bankruptcyDischargeDate)}}</span></li>
                    </ul>
                    <p *ngIf="application.borrowers.length > 1" class="notice">
                        <label>Notice:</label> We will send co-borrowers an email to review and sign the application
                        through DocuSign.
                    </p>
                </div>
                <div class="summary-section">
                    <h2>Line of Business - {{application.lineOfBusiness}}</h2>
                </div>
                <div class="summary-section">
                    <h2>Program Information</h2>
                    <ul *ngFor="let vendor of application.vendors; let i=index;" class="border-layout vendorList">
                        <li><label>Vendor</label> 
                            <span class="summary-indent">{{vendor.vendorName}}</span></li>
                        <li *ngIf="vendor.retailer"><label>Dealer</label> 
                            <span class="summary-indent">{{vendor.retailer}}</span></li>
                        <li *ngIf="vendor.retailerAddress"><label>Dealer Address</label> 
                            <span class="summary-indent">{{vendor.retailerAddress}}</span></li>
                        <li *ngIf="vendor.retailerCity"><label>Dealer City</label> 
                            <span class="summary-indent">{{vendor.retailerCity}}</span></li>
                        <li *ngIf="vendor.retailerState"><label>Dealer State</label> 
                            <span class="summary-indent">{{vendor.retailerState}}</span></li>
                        <li *ngIf="vendor.retailerZip"><label>Dealer ZipCode</label> 
                            <span class="summary-indent">{{vendor.retailerZip}}</span></li>
                        <li *ngIf="vendor.retailerPhone"><label>Dealer Phone</label> 
                            <span class="summary-indent">{{vendor.retailerPhone}}</span></li>
                        <li><label>Credit Limit</label> 
                            <span class="summary-indent">{{vendor.creditLimit | currency:'USD':'symbol':'1.0-0'}}</span></li>
                    </ul>
                    <div class="totalCredit">
                        Total Credit Requested: {{application.totalCreditLimit() | currency:'USD'}}
                    </div>
                </div>
                <div class="summary-section">
                    <h2>Financial Information</h2>
                    <div class="indent">
                        <label>Date of Balance Sheet:</label>
                        <span class="summary-indent">{{displayDate(application.balanceSheet.creationDate)}}</span>
                        <h3>Total Assets</h3>
                        <ul class="financial-items">
                            <li class="group">
                                <label>Short Term:</label>
                                <span>{{application.balanceSheet.shortTermAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Intermediate:</label>
                                <span>{{application.balanceSheet.intermediateAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Long Term:</label>
                                <span>{{application.balanceSheet.termAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span class="green">{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <h3>Total Liabilities</h3>
                        <ul class="financial-items">
                            <li class="group">
                                <label>Short Term:</label>
                                <span>{{application.balanceSheet.shortTermLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Intermediate:</label>
                                <span>{{application.balanceSheet.intermediateLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Long Term:</label>
                                <span>{{application.balanceSheet.termLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span class="red">{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>


                        <h3>Net Worth</h3>
                        <ul class="financial-items net-worth">
                            <li class="group">
                                <label>Total Assets:</label>
                                <span>{{application.balanceSheet.totalAssets | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Total Liabilities:</label>
                                <span>{{application.balanceSheet.totalLiabilities | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span [class.green]="application.balanceSheet.totalEquity >= 0" [class.red]="application.balanceSheet.totalEquity < 0">{{application.balanceSheet.totalEquity | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <h3>Total Income</h3>
                        <ul class="financial-items">
                            <li class="group">
                                <label>Gross Crop:</label>
                                <span>{{application.income.grossCropIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Gross Livestock:</label>
                                <span>{{application.income.grossLivestockIncome  | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                            <li class="group">
                                <label>Other Farm:</label>
                                <span>{{application.income.otherFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <div class="categoryTotal group">
                            <span class="green">{{application.income.totalFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>
                </div>
                <div class="summary-section attestations">
                    <div *ngIf="showEntity()">
                        <div class="error" *ngIf="form.submitted && ownership.invalid">
                            <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                        </div>
                        <label><input type="checkbox" name="ownership" [formControl]="ownership" [ngClass]="{error: form.submitted && !ownership.valid}" /> 
                            I attest that the ownership information shown above is true and accurate and all owners have been identified.
                        </label>
                    </div>

                    <div>
                        <div class="error" *ngIf="form.submitted && finances.invalid">
                            <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                        </div>
                        <label><input type="checkbox" name="finances" [formControl]="finances" [ngClass]="{error: form.submitted && !finances.valid}" />
                            I certify that all financial information displayed is true and accurate.</label>
                    </div>

                    <div *ngIf="isAnonymous">
                        <div class="error" *ngIf="form.submitted && terms.invalid">
                            <i class="fa fa-warning"></i> Error: You must check the box in order to continue.
                        </div>
                        <label><input type="checkbox" name="terms" [formControl]="terms" (change)="agreementChanged($event)" [ngClass]="{error: form.submitted && !terms.valid}" />
                            I agree to the <a class="clickable" tabindex="0" (click)="showTandC = true">Online Services
                                Agreement</a>.</label>
                    </div>
                </div>
                <div class="form-group summary-section captcha" *ngIf="isAnonymous">
                    <h2>Are you a robot?</h2>
                    <gol-re-captcha (captchaResponse)="recaptchaResolved($event)"></gol-re-captcha>
                    <div class="error" *ngIf="form.submitted && recaptchaToken == ''">
                        <p>Required</p>
                    </div>
                </div>
                <div class="summary-section notice">
                    <p><label>Notice:</label> In order to safely and securely complete your application with a digital
                        signature,
                        you will be directed to DocuSign.com where a series of questions will verify your identity and
                        allow you to electronically
                        sign and submit your application.</p>
                    <p>Co-borrowers on your application will be sent a separate email to link to DocuSign and complete
                        the same signature process.</p>
                </div>
                <div class="buttonContainer" fxLayout.xs="column">
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button" (click)="back()">Back</button>
                    <button class="btn btn-primary btn-wrap" type="submit">Submit and Sign Application</button>
                    <button *ngIf="hasSubmitErrors" class="btn btn-primary" type="button" (click)="printApp()">Print Application <i name="print" class="fa fa-print"></i></button>
                </div>
            </form>
        </div>
        <div class="col-sm-1"></div>
        <gol-contact-component></gol-contact-component>
    </div>
    <p-dialog [(visible)]="docusignError" modal="true" (onAfterHide)="closePrintDialog()">
        <ng-template pTemplate="header">
            Printing Instructions
        </ng-template>
        <div>
            <h2>We were not able to contact DocuSign</h2>
            <ol>
                <li>Please try again later or contact our customer service team.</li>
                <li>You can also <a (click)="printApp()">print</a> your document and manually sign and return it.</li>
                <li>You can Fax, email or mail your application to:
                    <ul>
                        <li class="sendOption">
                            <span>Fax:</span>
                            <div>(866) 349-3139</div>
                        </li>
                        <li class="sendOption">
                            <span>Email:</span>
                            <div>QuickLink@RaboAg.com</div>
                        </li>
                        <li class="sendOption">
                            <span>Mail:</span>
                            <div>
                                Rabo AgriFinance<br />
                                1402 Technology Parkway<br />
                                Cedar Falls, IA 50613
                            </div>
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="loading" *ngIf="printing">
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div>
            <button class="btn btn-default bottom" (click)="closePrintDialog()">Close</button>
            <button class="btn btn-primary bottom" (click)="printApp()" type="button">Print Application <i name="print" class="fa fa-print"></i></button>
        </div>
        <div style="clear:both"></div>
    </p-dialog>
</div>
<div class="loading-modal" *ngIf="waiting">
    <!-- Modal content -->
    <div class="loading-modal-content">
        Please wait while we contact DocuSign
        <div class="loading">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<p-dialog styleClass="terms-dialog" resizable="false" header="Online Services Agreement" [(visible)]="showTandC">
    <gol-terms-and-conditions></gol-terms-and-conditions>
</p-dialog>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>