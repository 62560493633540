import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TopForm } from '../../top-form';
import { formatDate } from '@angular/common';


@Component({
    selector: 'gol-security-question',
    templateUrl: 'security-question.component.html',
    styles: [`.buttonContainer { height:50px }`]
})
export class SecurityQuestionComponent {

    form: TopForm;
    @Input() securityQuestion = '';
    @Input() header: string;
    @Input() email: string;
    @Input() password: string;
    @Input() securityAnswer: string;
    answerForm: FormControl;
    @Input() serverError = '';
    @Input() loginMessage= '';
    @Output() answerUpdated: EventEmitter<string> = new EventEmitter<string>();
    lastLoginText: string;

    constructor() {
        this.form = new TopForm();
        this.answerForm = new FormControl('', Validators.required);
        this.form.addControl('securityAnswer', this.answerForm);
    }

    ngOnInit(){
       
    }
    updateAnswer(value: string) {
        this.answerUpdated.emit(value);
    }
}
