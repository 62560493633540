import { Component, OnInit } from '@angular/core';
import {
    AuthorizedPartyBase,
    authorizedPartyIdStorageKey
} from '../authorized-party-base';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountHttpService } from '../../../../services/account-http.service';
import { AuthorizedPartyService } from '../../../../services/authorized-party.service';
import { AuthorizedPartyWorkflow } from '../../../../models/authorized-party-workflow';
import { AuthorizedPartyStatus } from '../../../../models/authorized-party-status';
import { StorageService } from '../../../../services/storage.service';

@Component({
    selector: 'gol-authorized-party-in-progres',
    templateUrl: 'in-progress-detail.component.html',
    styleUrls: ['../authorized-party.style.css']
})
export class AuthorizedPartyInProgressDetailComponent extends AuthorizedPartyBase implements OnInit {

    public workflows: AuthorizedPartyWorkflow[] = [];
    public loading = false;
    public showConfirmation = false;
    public selectedWorkflow: AuthorizedPartyWorkflow = null;

    constructor(route: ActivatedRoute, accountService: AccountHttpService, private authorizedPartyService: AuthorizedPartyService,
        private router: Router, private storageService: StorageService) {
        super(route, accountService);
    }

    ngOnInit() {
        this.loading = true;

        this.loadPendingRequests();
    }

    showCancelButton(workflow: AuthorizedPartyWorkflow) {
        return workflow.Status === AuthorizedPartyStatus.Signing;
    }

    showSignButton(workflow: AuthorizedPartyWorkflow) {
        return workflow.Status === AuthorizedPartyStatus.Signing;
    }

    signButtonClick(workflow: AuthorizedPartyWorkflow) {
        this.loading = true;
        this.authorizedPartyService.getDocuSignURL(workflow.Id)
            .then((response) => {
                if (response) {
                    window.location.href = response;
                }
            })
            .catch((error) => {
                this.router.navigate(
                    ['dashboard', 'account', this.accountNumber, 'authorized-party', 'response'],
                    { queryParams: { event: 'error' } });
            });
        this.storageService.storeInSession(authorizedPartyIdStorageKey, workflow.Id + '');
    }

    cancelButtonClick(workflow: AuthorizedPartyWorkflow) {
        this.selectedWorkflow = workflow;
        this.showConfirmation = true;
    }

    cancelConfirmButtonClick() {
        this.closeDialog();
        this.loading = true;
        this.authorizedPartyService.cancelAuthorizedPartyRequest(this.selectedWorkflow.Id)
            .then(() => {
                this.loadPendingRequests();
            });
    }

    closeDialog() {
        this.showConfirmation = false;
    }

    private loadPendingRequests() {
        this.CurrentCommitment.then(c => {
            this.authorizedPartyService.getAllAuthorizedPartyWorkflows(c.AcctRefNo).then((response) => {
                if (response && response.length > 0) {
                    this.workflows = response;
                } else {
                    this.router.navigate(['dashboard', 'account', c.LoanNumber, 'authorized-party', 'add']);
                }
            })
                .catch(() => null)
                .then(() => { this.loading = false; });
        });
    }
}
