import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { Application } from '../models/application';
import { ApplicationHeader } from '../models/application-header';
import { AuthenticatedHttp } from '../services/authenticated-http.service';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CONFIG } from '../../environments/environment';
import { Person } from '../models/person';
import { Address } from '../models/address';
import { GrowerOnline } from '../models/constants';
import { LoggingService } from './logging.service';
import { ErrorHandling } from '../shared/error-handling';

@Injectable()
export class ApplicationHttpService implements IApplicationHttpService {
    private resourceUrl = CONFIG.apiBaseUri + 'Application';
    private headers = new HttpHeaders({ 'content-type': 'application/json' });

    constructor(private http: AuthenticatedHttp, private userService: UserService, private authService: AuthService, private logService: LoggingService) { }

    getApplicationHeaders() {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo('ApplicationHttpService.getApplicationHeaders start', null, null, currentUser);

        return this.http.get(this.resourceUrl + `/GetApplicationHeaders/${GrowerOnline}`)
            .toPromise()
            .then(response => Array.from(response as any[], (item) => {
                return new ApplicationHeader(item);
            }))
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.getApplicationHeaders completed, returned ${res.length} records`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError('ApplicationHttpService.getApplicationHeaders error.', err.message || err, null, currentUser);
               return this.handleError(err);
            });
    }

    getApplications() {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo('ApplicationHttpService.getApplications start', null, null, currentUser);
        const srvc = this;
        return this.http.get(this.resourceUrl)
            .toPromise()
            .then(response => Array.from(response as any[], (item) => {
                return new Application(srvc, srvc.userService, srvc.authService, item);
            }))
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.getApplications completed, returned ${res.length} records`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError('ApplicationHttpService.getApplications error.', err.message || err, null, currentUser);
               return this.handleError(err);
            });
    }

    getApplication(id: number) {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.getApplication start, Id: ${id}`, null, null, currentUser);
        const srvc = this;
        const url = `${this.resourceUrl}/${id}`;
        return this.http.get(url)
            .toPromise()
            .then(response => {
                this.logService.recordInfo(`ApplicationHttpService.getApplication completed, Id: ${id}, returned borrowers: ${response.Borrowers?.length ?? 0}`, null, null, currentUser);
                return new Application(srvc, srvc.userService, srvc.authService, response);
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.getApplication error, Id: ${id}.`, err.message || err, null, currentUser);
               return this.handleError(err);
            });
    }

    private handleError(error: any) {
        console.error('ApplicationHttpService: An error occurred', error);
        const msg = ErrorHandling.extractMessage(error);
        this.logService.recordError(`ApplicationHttpService.handleError completed`, msg, null, 'unknown');
        return Promise.reject(msg);
    }

    private getAppDTO(app: Application) {
        return {
            id: app.id,
            appType: app.appType,
            additionalOwners: app.additionalOwners,
            borrowers: app.borrowers,
            balanceSheet: app.balanceSheet,
            lineOfBusiness: app.lineOfBusiness,
            income: app.income,
            entity: app.entity,
            vendors: app.vendors,
            currentPage: app.currentPage,
            createdDate: app.createdDate,
            onlineAgreementAcceptanceDate: app.onlineAgreementAcceptanceDate,
            hasEntityOwnership: app.hasEntityOwnership,
            increasedRiskCountries: app.increasedRiskCountries,
            originatingSite: app.originatingSite,
            preApprovalStagingId: app.preApprovalStagingId,
            preApprovalCode: app.preApprovalCode,
            hasTrust: app.hasTrust,
            hasIosWithPercent: app.hasIosWithPercent,
            hasIndividualsWithPercent: app.hasIndividualsWithPercent,
            maxPageReached: app.maxPageReached
        };
    }

    private post(app: Application): Promise<number> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.post start, Application Id: ${app?.id}`, null, null, currentUser);
        return this.http
            .post(this.resourceUrl, JSON.stringify(this.getAppDTO(app)), { headers: this.headers })
            .toPromise()
            .then(result => {
                this.logService.recordInfo(`ApplicationHttpService.post completed, Application Id: ${app?.id}, returned :${result.Id} | ${result.CreatedDate}`, null, null, currentUser);
                return result;
            }, reject => {
                const msg = ErrorHandling.extractMessage(reject);
                this.logService.recordInfo(`ApplicationHttpService.post completed, Application Id: ${app?.id}`, msg, null, currentUser);
                throw new Error(msg);
            })
            .catch(err => {
                const msg = ErrorHandling.extractMessage(err);
                this.logService.recordError(`ApplicationHttpService.post error, Application Id: ${app?.id}.`, msg, null, currentUser);
                throw new Error(msg);
        });
    }

    delete(app: Application) {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.delete start, Application Id: ${app?.id}`, null, null, currentUser);
        const url = `${this.resourceUrl}/${app.id}`;

        return this.http
            .delete(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.delete completed, Application Id: ${app?.id}, returned ${res}`, null, null, currentUser);
                return;
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.delete error, Application Id: ${app?.id}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    cancel(app: Application) {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.cancel start, Application Id: ${app?.id}`, null, null, currentUser);
        const url = `${this.resourceUrl}/Cancel/${app.id}`;

        return this.http
            .post(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.cancel completed, Application Id: ${app?.id}, returned ${res}`, null, null, currentUser);
                return;
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.cancel error, Application Id: ${app?.id}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    save(app: Application): Promise<number> {
        return this.post(app);
    }

    submit(app: Application, recaptchaToken: string): Promise<string> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.submit start, Application Id: ${app?.id}, recaptcha token not empty: ${recaptchaToken && recaptchaToken.length > 0}`, null, null, currentUser);
        const url = `${this.resourceUrl}/submit/${app.id}`;

        return this.http
            .post(url, recaptchaToken ? JSON.stringify(recaptchaToken) : '', { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.submit completed, Application Id: ${app?.id}, returned ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.submit error, Application Id: ${app?.id}.`, ErrorHandling.extractMessage(err), null, currentUser);
                return this.handleError(err);
            });
    }

    getDocuSignUrl(app: Application): Promise<string> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.getDocuSignUrl start, Application Id: ${app?.id}`, null, null, currentUser);
        const url = `${this.resourceUrl}/GetDocuSignUrl/${app.id}`;

        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.getDocuSignUrl completed, Application Id: ${app?.id}, returned ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.getDocuSignUrl error, Application Id: ${app?.id}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    editSigningApplication(app: Application) {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.editSigningApplication start, Application Id: ${app?.id}`, null, null, currentUser);
        const srvc = this;
        const url = `${this.resourceUrl}/EditSigningApplication/${app.id}`;

        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then(response => {
                return new Application(srvc, srvc.userService, srvc.authService, response);
            })
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.editSigningApplication completed, Application Id: ${app?.id}, returned ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.editSigningApplication error, Application Id: ${app?.id}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }



    print(app: Application): Promise<Blob> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.print start, Application Id: ${app?.id}`, null, null, currentUser);
        const url = `${this.resourceUrl}/printable/${app.id}`;

        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                const byteChars = atob(res);
                const byteNumbers = new Array(byteChars.length);
                for (let i = 0; i < byteChars.length; i++) {
                    byteNumbers[i] = byteChars.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                const blob = new Blob([byteArray], { type: 'application/pdf' });
                this.logService.recordInfo(`ApplicationHttpService.print completed, Application Id: ${app?.id}, returned ${byteChars.length} bytes`, null, null, currentUser);
                return blob;
            }, err => {
                this.logService.recordInfo(`ApplicationHttpService.print rejected, Application Id: ${app?.id}, returned ${ErrorHandling.extractMessage(err)}`, null, null, currentUser);
                return this.handleError(err);
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.print error, Application Id: ${app?.id}.`, ErrorHandling.extractMessage(err), null, currentUser);
                return this.handleError(err);
            });
    }


    getPreApprovalStagingForApplication(stagingId: number): Promise<Person> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`ApplicationHttpService.getPreApprovalStagingForApplication start, stagingId: ${stagingId}`, null, null, currentUser);
        const url = `${this.resourceUrl}/GetPreApprovalStaging/${stagingId}`;

        return this.http.post(url, { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`ApplicationHttpService.getPreApprovalStagingForApplication completed, stagingId: ${stagingId}, returned ${res.GrowerFirstName} bytes`, null, null, currentUser);
                return this.convertStagingToPerson(res);
            })
            .catch(err => {
                this.logService.recordError(`ApplicationHttpService.getPreApprovalStagingForApplication error, stagingId: ${stagingId}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    private convertStagingToPerson(data: any): Person {
        const person = new Person();
        const address = new Address(true);
        address.city = data.GrowerCity;
        address.line1 = data.GrowerAddress;
        address.line2 = data.GrowerAddress2;
        address.state = data.GrowerState;
        address.zipcode = data.GrowerZip;

        person.mailingAddress = address;
        person.physicalAddress = address;
        person.fullName.firstName = data.GrowerFirstName;
        person.fullName.lastName = data.GrowerLastName;
        person.fullName.middleName = data.GrowerMiddle;
        person.fullName.suffix = data.GrowerSuffix;
        person.dateOfBirth = null;
        person.isPreApproved = true; //if the person was in PreApprovalStaging table (NLS) they were preapproved.
        return person;
    }
}

export interface IApplicationHttpService {
    getApplications();
    getApplication(id: number);
    save(app: Application): Promise<number>;
    submit(app: Application, recaptchaToken: string): Promise<string>;
    delete(app: Application);
    print(app: Application);
    getPreApprovalStagingForApplication(stagingId: number): Promise<Person>;
}

