<div [ngClass.gt-xs]="'programPanel'">
    <div class="card group">
        <div class="control-group autocomplete-wrapper" *ngIf="!vendor.forceVendor">
            <label>Which program are you wanting to utilize for the financing? <gol-help-tip>Select an option from the
                    list.</gol-help-tip></label>
            <label class="sub-text">(i.e. is there a specific brand of seed, or chemical, financing program you want to
                participate in?)</label>
            <gol-loading-feedback *ngIf="loadingVendors"></gol-loading-feedback>
            <gol-autocomplete *ngIf="!loadingVendors" DisplayTitle="Vendor" [forceSelection]="true"
                [itemFactory]=topVendorsFactory [maxSize]="4" [form]="form" [group]="group" [value]="vendor.vendorName"
                [update]="getVendorUpdate()" [showErrors]="true" [required]="true"></gol-autocomplete>
        </div>
        <div class="control-group autocomplete-wrapper" *ngIf="vendor.forceVendor">
            <label>{{vendor.vendorName}}</label>
        </div>
        <div *ngIf="vendor.vendorId > 0" class="control-group">
            <label>Where do you purchase the products specific to this financing program?</label>
            <label class="sub-text">Start typing the name and select from the list displayed. If your dealer isn't
                returned, enter as much information as you can.</label>
            <gol-loading-feedback *ngIf="loadingRetailers"></gol-loading-feedback>
            <div *ngIf="!loadingRetailers">
                Name: <gol-autocomplete DisplayTitle="Retailer" [itemFactory]=vendorDealersFactory [maxSize]="4"
                    [form]="form" [group]="group" [value]="vendor.retailer" [update]="getRetailerUpdate()"
                    [showErrors]="true" [required]="false"></gol-autocomplete>
                Address: <input type="text" class="form-control" [(ngModel)]="vendor.retailerAddress"
                    (ngModelChange)="checkForRetailerChange()" name="address">
                City: <input type="text" class="form-control" [(ngModel)]="vendor.retailerCity"
                    (ngModelChange)="checkForRetailerChange()" name="city">
                State: <select name="state" class="form-control" [(ngModel)]="vendor.retailerState"
                    (ngModelChange)="checkForRetailerChange()">
                    <option *ngFor="let s of states" value="{{s.abbreviation}}">{{s.abbreviation}} - {{s.name}}</option>
                </select>
                ZIP Code: <input type="text" class="form-control" [formControl]="zip" [(ngModel)]="vendor.retailerZip"
                    (ngModelChange)="checkForRetailerChange()" name="zip" [textMask]="{mask: zipMask, guide:false}"
                    maxlength="10">
                <div class="error" *ngIf="(form.submitted || zip.dirty) && zip.invalid">
                    <p>Invalid ZIP Code</p>
                </div>
                Phone: <input type="text" class="form-control" [formControl]="phone" [(ngModel)]="vendor.retailerPhone"
                    (ngModelChange)="checkForRetailerChange()" name="phone" [textMask]="{mask: phoneMask, guide:false}"
                    maxlength="14">
                <div class="error" *ngIf="(form.submitted || phone.dirty) && phone.invalid">
                    <p>Invalid phone number</p>
                </div>
            </div>
        </div>
        <div class="control-group creditLimit">
            <label>How much credit limit would you like for this program?</label>

            <input type="text" class="form-control" [formControl]="creditLimit" [(ngModel)]="vendor.creditLimit"
                (ngModelChange)="vendor.creditLimit = _utilities.toNumber($event)" name="creditLimit"
                [textMask]="{mask: creditMask, guide:false}" maxlength="14">
            <div class="error" *ngIf="(form.submitted || creditLimit.dirty) && creditLimit.invalid">
                <p *ngIf="creditLimit.errors['required']">Required</p>
                <p *ngIf="creditLimit.hasError('validateNumber') && creditLimit.errors['validateNumber'].NAN">Invalid
                    number</p>
                <p *ngIf="creditLimit.hasError('validateNumber') && creditLimit.errors['validateNumber'].min">Must be
                    greater than 0</p>
                <p *ngIf="creditLimit.hasError('validateNumber') && creditLimit.errors['validateNumber'].max">Cannot be
                    greater than $999,999,999</p>
            </div>
        </div>
    </div>
</div>