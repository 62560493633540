import { Component, OnInit, AfterViewInit, ViewChildren } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';

import { OrganizationType } from '../../models/organization-type';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-entity-information',
    templateUrl: 'entity-information.component.html',
    styleUrls: ['../styles_form.css']
})
export class EntityInformationComponent extends AbstractFormPage implements OnInit, AfterViewInit {
    form: TopForm;
    organizationTypes: OrganizationType[] = OrganizationType.getValidTypes();
    taxIDMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    today: Date = new Date();
    minDateOfOrg: Date = new Date(1880, 0, 1);

    @ViewChildren(NgModel) inputChildren;

    ngAfterViewInit() {
        const addables = ['legalName', 'taxIdNumber', 'orgDate'];

        this.inputChildren.changes.subscribe((items) => {
            items.map((item) => {
                if (addables.indexOf(item.name) > -1) {
                    this.form.addControl(item.name, item.control);
                }
            });
        });
    }

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService, notificationService: NotificationService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'entity';
        this.initPage();
    }

    ngOnInit() {
        this.form = new TopForm();
    }

    isValidForm() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        if (this.form.valid) {
            return true;
        } else {
            this.scrollToError();
            return false;
        }
    }

    submit() {
        if (this.isValidForm()) {
            this.next();
        }
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string) {
        return new Promise<boolean>((resolve, reject) => {
            const comp = this;
            if (comp.isValidForm()) {
                comp.application.currentPage = newPageAlias;
                resolve(comp.save());
            } else {
                reject(false);
            }
        });
    }
}
