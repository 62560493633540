import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { CurrentRegistrationService as CurrentRegistrationServiceV1 } from '../../../app/services/current-registration.service';
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService as AuthServiceV1} from '../../../app/services/auth.service';
import { RegistrationLinkAccountComponent as RegistrationLinkAccountComponentV1 } from '../../../app/registration/profile/registration-linkaccount.component'

@Component({
    selector: 'gol-registration-linkaccount',
    templateUrl: '../../../app/registration/profile/registration-linkaccount.component.html',
    styleUrls: ['../../../app/registration/css/styles_form.css']
})
export class RegistrationLinkAccountComponent extends RegistrationLinkAccountComponentV1{

    constructor(public routerV1: Router, 
        private authServiceV1: AuthServiceV1, 
        private registrationServiceV1: CurrentRegistrationServiceV1,
        private registrationServiceV2: CurrentRegistrationService,
        ) {
        super(routerV1, authServiceV1, registrationServiceV1)
    }

    skip(triggered: boolean) {
        if (triggered) {
            this.registree.isExistingCustomer = false;
            this.registrationServiceV2.setCurrentRegistree(this.registree);
            //TODO:MFA
            this.registrationServiceV2.gotoNextPage();
        }
    }

    continue(linkedData: any) {
        const cifno = linkedData['cifno'];
        if (cifno > 0) {
            this.registree.cifNo = cifno;
            this.registree.accountNumber = linkedData['accountNumber'];
            this.registree.zip = linkedData['zip'];
            this.registree.ssn = linkedData['ssn'];
            this.registrationServiceV2.setCurrentRegistree(this.registree);
            //TODO:MFA
            this.registrationServiceV2.gotoNextPage();
        }
    }
}
