import { Component, OnInit, AfterViewInit, QueryList, ViewChildren, Input, ChangeDetectorRef } from '@angular/core';
import { Application } from '../../models/application';
import { CurrentApplicationService } from '../../services/current-application.service';
import { Router } from '@angular/router';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { NgForm, NgModel, FormControl, FormGroup, Validators } from '@angular/forms';
import { validateCheckboxFactory } from '../validation/checkbox-required.directive';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { UtilitiesService } from '../../services/utilities.service';
import { IItemFactory } from '../reusables/autocomplete/autocomplete.component';
import { TopVendorsFactory, VendorDealersFactory } from '../../services/program-item-factories';
import { VendorService } from '../../services/vendor.service';
import { Vendor, TopVendors } from '../../models/vendor';
import { HistoryService } from '../../services/history.service';
import { States } from '../../models/state-list';
import { zipMask } from '../reusables/address/zip.component';
import { phoneMask } from '../reusables/phone/phone.component';
import { totalIncomeMaxNLS } from '../financial-validation';
import numberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
    selector: 'gol-vendor-programs',
    templateUrl: 'vendor-programs.component.html',
    styleUrls: ['../styles_form.css', 'vendor-programs.component.css']
})
export class VendorProgramsComponent implements OnInit, AfterViewInit {
    @ViewChildren(NgModel) inputChildren;

    @Input() application: Application;
    @Input() vendor: Vendor;
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() vendorNumber: number;

    loadingVendors: boolean = false;
    loadingRetailers: boolean = false;
    topVendorsFactory: IItemFactory;
    vendorDealersFactory: IItemFactory;
    _utilities: UtilitiesService;
    zip: FormControl;
    phone: FormControl;
    creditLimit: FormControl;
    preApprovalSponsorId: number;
    preApprovalChanged: boolean;
    states = States;

    creditMask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });
    zipMask = zipMask;
    phoneMask = phoneMask;

    constructor(utilities: UtilitiesService, private vendorService: VendorService, private cdr: ChangeDetectorRef) {
        this._utilities = utilities;
    }

    ngOnInit() {
        this.addAdditionalControls();
    }

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.group.addControl(item.name, item.control);
        });

        this.loadingVendors = true;
        this.vendorService.GetVendors(this.application ? `ApplicationId:${this.application.id}` : null) //this initializes the service's vendors and filteredVendors lists, will not repopulate if called again
            .then((res) => {
                this.loadingVendors = false;
            }).catch(err => {
                console.log('There was an error loading Vendors');
            }).then(() => {
                this.loadingVendors = false;
            });

        this.filterVendors(); //sets the service's filteredVendors to a subset of vendors matching existing application's vendors
        this.topVendorsFactory = new TopVendorsFactory(this.vendorService);

        if (this.application.preApprovalStagingId > 0) {
            this.vendorService.GetSponsoringDealerForPreApproval(this.application.preApprovalStagingId)
                .then(res => {
                    if (res) {
                        const vendorControl = this.group.get('Vendor');
                        vendorControl.setErrors(null);

                        this.preApprovalSponsorId = res.rowId;
                        this.preApprovalChanged = false;
                        this.vendor.vendorId = res.rowId;
                        this.vendor.vendorName = res.name;
                        if (this.application.preApprovalVendor == null){
                            if (this.application.vendors.filter(x => x.vendorId == res.rowId).length > 0){
                                this.application.preApprovalVendor =this.application.vendors.filter(x => x.vendorId == res.rowId)[0]
                            }else{
                                this.application.preApprovalVendor = new Vendor();
                                this.application.preApprovalVendor.vendorId = res.rowId;
                                this.application.preApprovalVendor.creditLimit = this.vendor.creditLimit;
                            }
                        }
                    }
                    this.getRetailersForVendor(this.vendor.vendorId);
                })
                .catch(err => {
                    console.error('Error in GetSponsoringDealerForPreApproval');
                });
        } else {
            if (this.vendor && this.vendor.vendorId > 0) {
                const vendorControl = this.group.get('Vendor');
                vendorControl.setErrors(null);
                this.getRetailersForVendor(this.vendor.vendorId);
            }
        }

        this.cdr.detectChanges();
    }

    addAdditionalControls() {
        this.zip = new FormControl('', Validators.compose([Validators.maxLength(10), Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]));
        this.zip.updateValueAndValidity();
        this.group.addControl('zip', this.zip);

        const numberValidation = validateNumberFactory(false, 0, 1, totalIncomeMaxNLS, this._utilities);
        this.creditLimit = new FormControl('', [Validators.required, numberValidation]);
        this.creditLimit.updateValueAndValidity();
        this.group.addControl('creditLimit', this.creditLimit);

        this.phone = new FormControl('', Validators.compose([Validators.maxLength(14), Validators.minLength(14)]));
        this.phone.updateValueAndValidity();
        this.group.addControl('phone', this.phone);

        if (this.vendor.retailerPhone) {
            this.vendor.retailerPhone = this._utilities.formatPhoneNumber(this.vendor.retailerPhone);
        }
    }

    filterVendors() {
        const selectedVendors = this.application.vendors.map(v => v.vendorId);
        this.vendorService.filterSelectedVendors(selectedVendors);
    }

    getVendorUpdate() {
        const component = this;
        const service = this.vendorService;
        return function (value) {
            if (component.vendor) {
                const vendor = service.vendors.find(v => v.getShortName() === value);
                if (vendor) {
                    const vendorControl = component.group.get('Vendor');
                    vendorControl.setErrors(null);
                    component.vendor.vendorId = vendor.rowId;
                    component.vendor.vendorName = vendor.name;
                    component.filterVendors();
                    component.getRetailersForVendor(vendor.rowId);
                } else {
                    const vendorControl = component.group.get('Vendor');
                    vendorControl.setErrors({ 'required': true });
                    component.vendor.vendorId = 0;
                    component.vendor.vendorName = value;
                }
                if (component.application.preApprovalStagingId > 0) {
                    if (!vendor || vendor.rowId !== component.preApprovalSponsorId) {
                        component.preApprovalChanged = true;
                    } else {
                        component.preApprovalChanged = false;
                    }
                }
                component.clearRetailer();
            }
        };
    }

    clearRetailer() {
        this.vendor.retailerId = 0;
        this.vendor.retailer = '';
        this.vendor.retailerAddress = '';
        this.vendor.retailerCity = '';
        this.vendor.retailerState = '';
        this.vendor.retailerZip = '';
        this.vendor.retailerPhone = '';
    }

    getRetailersForVendor(vendorId: number) {
        this.loadingRetailers = true;
        const nearZipCode = this.getZipCode();
        this.vendorService.GetRetailersForVendor(vendorId, nearZipCode, this.application ? `ApplicationId:${this.application.id}` : null)
            .then((res) => {
                this.vendorDealersFactory = new VendorDealersFactory(this.vendorService);
                this.loadingRetailers = false;
            })
            .catch(err=>{
                console.log('Error in get retailers for vender.',err)
            }).then(() => {
                this.loadingRetailers = false;
            });
    }

    getRetailerUpdate() {
        const component = this;
        const service = this.vendorService;

        return function (value) {
            if (component.vendor) {
                const retailer = service.vendorRetailers.find(v => v.getItemName() === value);
                if (retailer) {
                    component.vendor.retailerId = retailer.rowId;
                    component.vendor.retailer = retailer.name;
                    component.vendor.retailerAddress = retailer.address.line1;
                    component.vendor.retailerCity = retailer.address.city;
                    component.vendor.retailerState = retailer.address.state;
                    component.vendor.retailerZip = retailer.address.zipcode;
                    if (retailer.phoneNumber) {
                        component.vendor.retailerPhone = component._utilities.formatPhoneNumber(retailer.phoneNumber);
                    }
                } else {
                    component.clearRetailer();
                    component.vendor.retailer = value;
                }
            }
        };
    }

    checkForRetailerChange() {
        if (this.loadingRetailers === false && this.vendor.retailerId > 0) {
            const retailer = this.vendorService.vendorRetailers.find(v => v.rowId === this.vendor.retailerId);
            if (retailer) {
                if (retailer.address.line1 !== this.vendor.retailerAddress ||
                    retailer.address.city !== this.vendor.retailerCity ||
                    retailer.address.state !== this.vendor.retailerState ||
                    retailer.address.zipcode !== this.vendor.retailerZip ||
                    (retailer.phoneNumber
                        && (this._utilities.formatPhoneNumber(retailer.phoneNumber)
                            !== this._utilities.formatPhoneNumber(this.vendor.retailerPhone)))
                ) {
                    this.vendor.retailerId = 0;
                }
            } else {
                this.vendor.retailerId = 0;
            }
        }
    }

    greaterThanZeroValidator() {
        const comp = this;
        return function (c: FormControl) {
            if (c.value || c.value === 0) {
                const number = comp._utilities.toNumber(c.value);
                if (number != null && number < 1) {
                    return { lessThanZero: true };
                }
            }
            return null;
        };
    }

    private getZipCode(): string {
        if (this.application.entity) {
            return this.application.entity.mailingAddress.zipcode;
        }

        return this.application.borrowers[0].mailingAddress.zipcode;
    }
}
