import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HeaderComponent as HeaderComponentV1 } from '../../../../app/form/reusables/view/header.component';

import { UserService } from '../../../../app/services/user.service';
import { CurrentApplicationService } from '../../../../app/services/current-application.service';
import { AccountHttpService } from '../../../../app/services/account-http.service';
import { AuthService } from '../../../services/auth.service';

const $ = window['$'];

@Component({
    selector: 'gol-header-component',
    templateUrl: '../../../../app/form/reusables/view/header.component.html',
    styleUrls: ['../../../../app/form/styles_form.css']
})
export class HeaderComponent extends HeaderComponentV1 {
    //TODO:MFA
    constructor(private router_Temp: Router, private userService_Temp: UserService, private currentAppService_Temp: CurrentApplicationService,
        private accountService_Temp: AccountHttpService,
        private authService: AuthService) { 
            super(router_Temp, userService_Temp, currentAppService_Temp, accountService_Temp);
    }

    // TODO:MFA - Overriding base class logout
    logout() { 
        this.authService.Logout();
    }
}
