<div class="row">
    <nav id="progress-navigation" class="RaboBlue-bg radius-zero">
        <div class="container-fluid">
            <i class="fa mobile-chevron {{closeProgressNav ? 'fa-chevron-down' : 'fa-chevron-up'}}"
                (click)="closeProgressNav = !closeProgressNav"></i>
            <ul class="nav justify-content-center" [ngClass]="{closed: closeProgressNav}">
                <li class="nav-item" *ngFor="let option of options; let i=index"
                    (click)="navigate('..'+option.route, option.alias);">
                    <i class="fa {{isDisabled(option.alias) ? 'fa-circle-o' : 'fa-circle'}}"></i>
                    <a tabindex="0"
                        [ngClass]="{selected:isSelected(option.alias), disabled:isDisabled(option.alias)}" fxLayoutAlign ="space-around">{{option.name}}</a>
                </li>
            </ul>
        </div>
    </nav>
</div>