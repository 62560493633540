import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TopForm } from '../../top-form';
import { validateCheckboxFactory } from '../../validation/checkbox-required.directive';
import { validateEmailFactory } from '../../validation/email-validator.directive';
import { User } from '../../../models/user';
import { AuthService, IAuthService } from '../../../services/auth.service';
import { AccountHttpService } from '../../../services/account-http.service';
import { UserService } from '../../../services/user.service';
import { Commitment } from '../../../models/accounts/commitment';
import { Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { DatePipe, formatDate } from '@angular/common';


@Component({
    selector: 'gol-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent {

    @Input() hideCancel = false;
    form: TopForm;
    email: FormControl;
    password: FormControl;
    serverError = '';
    question: string;
    loginMessage: string;
    loginDateUtc: Date;
    answer: string;
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    submitting: boolean;
    loading: boolean;
    passwordExpired: boolean;

    constructor(private authService: AuthService, private userService: UserService,
        private accountService: AccountHttpService, private router: Router) {
        this.form = new TopForm();
        this.email = new FormControl('', Validators.required);
        this.password = new FormControl('', Validators.required);
        this.form.addControl('email', this.email);
        this.form.addControl('password', this.password);
    }

    async submit() {
        this.form.submitted = true;
        this.serverError = '';
        if (this.form.valid) {
            this.submitting = true;
            await this.authService.GetSecurityQuestion(this.email.value, this.password.value, null)
                .then(async (question) => {
                    if (question) {
                        this.question = question;
                        this.answer = '';

                        /*********Now that we're reasonably sure of their identity, display last login date message*********/
                        await this.authService.GetLastLoginMessage(this.email.value) 
                        .then((result) => {
                            const {message}:{message:string} = JSON.parse(result);
                            this.loginMessage = message;
                        }).catch((err)=>{
                            this.serverError = err;
                        });
                    } else {
                        this.closeEvent.emit(false);
                        this.accountService.credentials.email = this.email.value;
                        this.accountService.credentials.password = this.password.value;
                        this.router.navigate(['/resetsecurityquestions'], { skipLocationChange: true });
                    }
                   
                })
                .catch((rejected) => {
                    this.serverError = rejected;
                    if (rejected === 'Password expired') {
                        this.passwordExpired = true;
                    }
                })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }

    submitWithQuestion() {
        this.form.submitted = true;
        this.serverError = '';
        if (this.form.valid) {
            this.submitting = true;
            this.authService.Login(this.email.value, this.password.value, this.question, this.answer)
                .then(() => {
                    this.securityQuestionPassed();
                })
                .catch((rejected) => {
                    this.setFailedLogin(rejected);
                })
                .finally(() => {
                    this.submitting = false;
                });
        }
    }

    cancel() {
        this.closeEvent.emit(false);
    }

    securityQuestionPassed() {
        this.checkContactAccountsEnabled();
    }

    answerUpdated(value: string) {
        this.answer = value;
    }

    checkContactAccountsEnabled() {
        const user = this.userService.GetUser();
        if (!user) {
            this.closeEvent.emit(true);
        } else {
            if (user.cifno) {
                this.loading = true;
                this.accountService.getCustomerContactDetails(user.cifno)
                    .then((results) => {
                        this.loading = false;
                        if (results.AccountsDisabled) {
                            this.userService.Logout();
                            this.setFailedLogin('There is an issue with your account.  Please contact customer service for more details.');
                        } else {
                            this.checkAccountStatuses(user);
                        }
                    });
            } else {
                this.closeEvent.emit(true);
            }
        }
    }

    checkAccountStatuses(user: User) {
        let result = true;
        this.loading = true;
        this.accountService.getAllCommitments()
            .then((results) => {
                this.loading = false;
                const allCommitments = results;
                if (allCommitments && allCommitments.length > 0) {
                    user.hasBadCreditAccounts = allCommitments.some(x => this.isBadCreditAccount(x));
                    user.hasGoodCreditAccounts = allCommitments.some(x => !this.isBadCreditAccount(x));
                    user.canApply = allCommitments.every(x => !this.stopApplying(x));

                    if (user.hasBadCreditAccounts && !user.hasGoodCreditAccounts) {
                        this.userService.Logout();
                        this.setFailedLogin('There is an issue with your account.  Please contact customer service for more details.');

                        result = false;
                    }
                }
            })
            .catch(err => { })
            .then(() => {
                if (result) {
                    this.closeEvent.emit(true);
                }
            });
    }

    onExpiredPasswordUpdated(newPassword: string) {
        this.passwordExpired = false;
        this.password.setValue(newPassword);
        this.submit();
    }

    private isBadCreditAccount(commitment: Commitment): boolean {
        return commitment.HasBankruptcy
            || commitment.HasCollections;
    }

    private stopApplying(commitment: Commitment): boolean {
        return commitment.IsDelinquent
            || commitment.HasExtension
            || commitment.HasCreditHold
            || this.isBadCreditAccount(commitment);
    }

    private setFailedLogin(message: string) {
        this.serverError = message;
        this.email.setValue('', { onlySelf: false });
        this.password.setValue('', { onlySelf: false });
        this.email.markAsPristine();
        this.password.markAsPristine();
        this.form.submitted = false;
        this.question = null;
    }
}