export class ChangeLogModel {
    Id: number;
    ChangeDate: Date;
    Form: string;
    RefType: string;
    RefValue: string;
    FieldName: string;
    OldValue: string;
    NewValue: string;
    ChangedBy: string;

    constructor(data?:any) {
        if (data) {
            this.Id = data.Id;
            this.ChangeDate = data.ChangeDate;
            this.Form = data.Form;
            this.RefType = data.RefType;
            this.RefValue = data.RefValue;
            this.FieldName = data.FieldName;
            this.OldValue = data.OldValue;
            this.NewValue = data.NewValue;
            this.ChangedBy = data.ChangedBy;
        }
    }
}