<div>
    <div class="account-summary raised">
        <h1 class="title">My Accounts</h1>
        <gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
        <div *ngIf="!loading && !user?.cifno" class="link-account">
            <div *ngIf="!linking" class="link-account-message">
                <span><i class="fa fa-link helpItem" aria-hidden="true"></i><label>Existing customer? Click below to
                        link to your account.</label></span>
                <div class="account-controls">
                    <a tabindex="0" class="btn btn-primary raised" (click)="linking = true"><i
                            class="glyphicon fa fa-plus"></i>Link To Account</a>
                </div>
            </div>
            <gol-linkaccount *ngIf="linking" [newUser]="false" (cifNo)="accountLinked($event)"
                (cancel)="linking = false">
            </gol-linkaccount>
        </div>
        <ul *ngIf="!loading" class="account-summary-list">
            <li *ngFor="let account of accounts" class="raised-card" [ngClass]="{stretch: accounts.length === 1}">
                <h2>
                    <a [routerLink]="['/dashboard/account/',account.LoanNumber]">{{account.AccountHolder.DisplayName}}
                        <span class="smaller">({{account.LoanNumber}})</span></a>
                </h2>
                <div *ngIf="account.UpcomingPaymentRequired" class="warning">Maturity Date Approaching - Payment
                    Required</div>
                <div *ngIf="account.IsPastDue" class="warning">Is Past Due - Other Fees May Apply</div>
                <div class="row account-summary-details">
                    <div class="col-sm-6">
                        <label>Type</label>
                        <p>{{getUserRelationship(account)}}</p>
                        <label>Dealers</label><br />
                        <span *ngFor="let dealer of account.DealerNames">{{dealer}}<br /></span>
                    </div>
                    <div class="col-sm-6">
                        <label>Credit Amount</label>
                        <p>{{account.DisplayCreditLimit | currency:'USD'}}</p>
                        <label>Current Balance</label>
                        <p>{{account.Balance | currency:'USD'}}</p>
                    </div>
                </div>
                <div class="account-summary-controls">
                    <button type="button" class="btn btn-secondary"
                        [routerLink]="['/dashboard/account/',account.LoanNumber]">Details</button>
                    <button *ngIf="account?.HasBalance && !account?.HasPendingPayment" type="button"
                        class="btn btn-primary raised"
                        [routerLink]="['/dashboard/account/',account.LoanNumber, 'payment']"><i
                            class="fa fa-usd fa-1x"></i>Make a Payment</button>
                </div>
            </li>
        </ul>
    </div>
</div>