import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { User } from '../../../models/user';
import { TopForm } from '../../../form/top-form';
import { UserService } from '../../../services/user.service';
import { validateEmailFactory } from '../../../form/validation/email-validator.directive';
import { ContactDetail } from '../../../models/accounts/contact-detail';
import { UserPreferences } from '../../../models/user-preferences';
import { ContactAddress } from '../../../models/accounts/contact-address';
import { ContactPhone } from '../../../models/accounts/contact-phone';
import { AccountHttpService } from '../../../services/account-http.service';
import { States } from '../../../models/state-list';

export const PhoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

@Component({
    selector: 'gol-preferences-contact',
    templateUrl: 'preferences-contact.component.html',
    styleUrls: ['../account-preferences.component.css', 'preferences-contact.component.css']
})
export class PreferencesContactComponent implements OnInit {
    contactInfoForm: TopForm;
    user: User;
    contactInfoChangeComplete = false;
    updateInfoError: string;
    contactDetails: ContactDetail;
    loading: boolean;
    error: boolean;
    zipMask = ZipMask;
    phoneMask = PhoneMask;
    states = States;
    sameAsPhysical: boolean;
    newPhone: ContactPhone;
    addingNewPhone: boolean;

    constructor(private userService: UserService, private accountService: AccountHttpService) {
    }

    ngOnInit() {
        this.newPhone = new ContactPhone();
        this.contactInfoForm = new TopForm({
            email: new FormControl('', [Validators.required, validateEmailFactory()]),
            password: new FormControl('', [Validators.required]),
            newPhoneCtrl: new FormControl(''),
        });

        this.getUserData();
    }

    getUserData() {
        window.scrollTo(0, 0);
        this.loading = true;
        this.user = new User(this.userService.GetUser());
        if (this.user.cifno) {
            this.accountService.getCustomerContactDetails(this.user.cifno)
                .then((result) => {
                    this.contactDetails = result;
                    this.checkPhysicalAddress();
                })
                .catch(() => {
                    this.error = true;
                })
                .then(() => {
                    this.loading = false;
                });
        } else {
            this.contactDetails = new ContactDetail();
            this.contactDetails.UserPreferences = new UserPreferences();
            this.contactDetails.UserPreferences.EmailAddress = this.user.email;
            this.sameAsPhysical = true;
            this.setPhysicalAddressValidators();
            this.loading = false;
        }
    }

    checkPhysicalAddress() {
        if (this.contactDetails.PhysicalAddress) {
            this.sameAsPhysical = false;
        } else {
            this.sameAsPhysical = true;
        }
        this.setPhysicalAddressValidators();
    }

    setPhysicalAddressValidators() {
        if (!this.contactDetails.PhysicalAddress) {
            this.contactDetails.PhysicalAddress = new ContactAddress();
        }
    }

    formatPhoneNumber(phoneNumber: string): string {
        return '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6, 4);
    }

    addPhone(add: boolean) {
        this.addingNewPhone = add;

        const newPhoneCtrl = this.contactInfoForm.get('newPhoneCtrl');
        if (add) {
            newPhoneCtrl.setValidators([Validators.required]);
        } else {
            newPhoneCtrl.setValidators([]);

            if (this.contactDetails.PhoneNumbers && this.contactDetails.PhoneNumbers.length > 0) {
                const primaryPhone = this.contactDetails.PhoneNumbers.find(p => p.IsPrimary).PhoneNumber;

                if (primaryPhone) {
                    this.contactDetails.PrimaryPhoneNumber = primaryPhone;
                }
            }
        }
        newPhoneCtrl.updateValueAndValidity();
    }

    contactInfoSubmit() {
        this.updateInfoError = null;
        this.contactInfoForm.submitted = true;

        if (this.contactInfoForm.valid) {
            this.loading = true;
            if (this.addingNewPhone) {
                this.contactDetails.PhoneNumbers.push(this.newPhone);
            }
            if (this.sameAsPhysical) {
                this.contactDetails.PhysicalAddress = null;
            }
            this.accountService.updateCustomerContactDetails(this.contactDetails)
                .then((res) => {
                    this.contactDetails = res;
                    const user = this.userService.GetUser();
                    user.email = this.contactDetails.UserPreferences.EmailAddress;
                    this.userService.SetUser(user);
                    this.checkPhysicalAddress();
                    this.contactInfoChangeComplete = true;
                    this.updateInfoError = null;
                })
                .catch((rejected) => {
                    this.contactDetails.Password = '';
                    this.updateInfoError = rejected.Message;
                })
                .then(() => {
                    this.loading = false;
                });
        } else {
            this.updateInfoError = 'Invalid data';
        }
    }

    closeComplete() {
        this.contactInfoChangeComplete = false;
        this.contactInfoForm.submitted = false;
        this.contactInfoForm.markAsPristine();
        this.addPhone(false);
        this.newPhone = new ContactPhone();
        window.scrollTo(0, 0);
    }
}

export function ZipMask(inputValue) {
    let array: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/];
    if (inputValue.length > 5) {
        array = array.concat(['-', /\d/, /\d/, /\d/, /\d/]);
    }
    return array;
}
