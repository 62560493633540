<div class="preference-group group raised-card">
    <h2>Communication Preferences</h2>
    <gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
    <div *ngIf="!loading && error" class="alert">
        There was an error pulling the account preferences.
    </div>
    <div *ngIf="!loading && !alertChangeComplete && !error">
        <p>We'd like to offer quicker ways to update you on your account. Please select how you would like to receive
            alerts outside of mail.</p>
        <p>You will receive alerts via the following methods when changes are made to your account, statements become
            available, if there is an upcoming payment due or if a payment is received.</p>
        <div class="choice">
            <p-checkbox label="Email" [(ngModel)]="contactDetails?.UserPreferences.EmailOptIn" binary="true">
            </p-checkbox>
            <div *ngIf="contactDetails?.UserPreferences.EmailOptIn">
                <p>Email alerts will be sent to <label>{{contactDetails?.UserPreferences.EmailAddress}}</label>. You can
                    change this in the <a routerLink="/dashboard/preferences/email">Email/Username</a> tab above.
                </p>
            </div>
        </div>
        <button class="btn btn-primary" type="button" (click)="update()" [disabled]="loading">Update</button>
    </div>
    <div *ngIf="!loading && alertChangeComplete">
        <p>Your preferences were successfully changed!</p>
        <button type="button" class="btn btn-primary" (click)="resetForm()">OK</button>
    </div>
</div>