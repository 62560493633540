import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NavigationOptionsRegistration } from '../../models/navigation-options-registration';

@Component({
    selector: 'gol-registration-navigation',
    templateUrl: 'registration-navigation.component.html',
    styleUrls: ['../css/progress-navigation.css']
})
export class RegistrationNavigationComponent {
    selectedAlias: string;
    constructor(private router: Router, private activeRoute: ActivatedRoute) {
        this.options = NavigationOptionsRegistration.RegistrationPages();

        activeRoute.url.subscribe(() => {
            activeRoute.firstChild.data.subscribe((data) => {
                this.selectedAlias = data['tab'] || this.selectedAlias;
            });
        });

    }

    options: any[];
    showingMenu = false;
    closeProgressNav = true;

    isDisabled(alias: string) {
        return this.getIndexByAlias(alias) > this.getIndexByAlias(this.selectedAlias);
    }

    isSelected(alias: string) {
        return this.getIndexByAlias(alias) === this.getIndexByAlias(this.selectedAlias);
    }

    getIndexByAlias(alias: string): number {
        return this.options.findIndex((page) => page.alias === alias);
    }

    showMenu(show: boolean) {
        this.showingMenu = show;
    }

    navigate(route: string, alias: string) {
        if (!this.isDisabled(alias)) {
            this.router.navigate([route], { relativeTo: this.activeRoute });
        } else {
            return false;
        }
    }
}
