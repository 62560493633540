import { Component } from '@angular/core';


@Component({
    selector: 'gol-contact-component',
    template: `<div class="col-sm-4 helpBox_grad text-small">
                <div class="row help_pad">
                    <div class="col-sm-1">
                        <i class="fa fa-user RaboBlue-icon" aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-11">
                        If you need assistance with this site, please contact our customer service team.
                    </div>
                </div>
                <div class="row help_pad">
                    <div class="col-sm-1">
                        <i class="fa fa-phone RaboBlue-icon" aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-11">
                        Call (888) 395-8505
                    </div>
                </div>
                <div class="row help_pad">
                    <div class="col-sm-1">
                        <i class="fa fa-envelope RaboBlue-icon" aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-11">
                        Email <a href="mailto:QuickLink@RaboAg.com">QuickLink@RaboAg.com</a>
                    </div>
                </div>
                <div class="row help_pad">
                    <div class="col-sm-1">
                        <i class="fa fa-question-circle RaboBlue-icon" aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-11">
                        Click the help icon on a page for more information.
                    </div>
                </div>
                <div class="row help_pad">
                    <div class="col-sm-1">
                        <i class="fa fa-exclamation-circle RaboBlue-icon" aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-11">
                        For applications not signed by all parties and received by Rabo AgriFinance, information entered will be deleted after 30 days.
                    </div>
                </div>
            </div>`,
    styles: [`.help_pad {
                padding:20px 0px 0px 0px;
            }
            .helpBox_grad {
                background: white; /* For browsers that do not support gradients */
                background: -webkit-linear-gradient(#D2D2D3, #F7F7F7); /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(#D2D2D3, #F7F7F7); /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(#D2D2D3, #F7F7F7); /* For Firefox 3.6 to 15 */
                background: linear-gradient(#D2D2D3, #F7F7F7); /* Standard syntax */
                margin-top:20px;
                width:375px;
                margin: 0 auto;
                padding:10px;
                max-width:375px;
            }
            .RaboBlue-icon {
                color:#000099;
                margin-right:10px;
            }
    `]
})
export class ContactComponent { }



