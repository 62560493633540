import { Router } from '@angular/router';
import { Application } from '../models/application';
import { CurrentApplicationService } from '../services/current-application.service';
import { NavigationOptions } from '../models/navigation-options';
import { UtilitiesService } from '../services/utilities.service';
import { HistoryService } from '../services/history.service';
import { NotificationService } from 'if-angular-core';

export abstract class AbstractFormPage {
    application: Application;
    pageAlias: string;

    constructor(
        private currentAppSvc: CurrentApplicationService,
        public router: Router,
        protected utilities: UtilitiesService,
        private history: HistoryService,
        public notificationService: NotificationService) {}

    initPage() {
        this.currentAppSvc.getCurrentApplication('AbstractFormPage - initPage()').then((app) => {
            // tslint:disable-next-line: max-line-length
            // if app is not defined there is a security mismatch where the user is not allowed to see the requested app, redirect to landing page.
            if (!app || app.currentPage === '') {
                return this.router.navigate(['/apply']);
            }
            // tslint:disable-next-line: max-line-length
            // trying to get to an invalid page for this application, or trying to get to a page past where the user has validated the app up to.
            this.application = app;
            if (this.pageNumber === -1) {
                this.application = null;
                return this.router.navigate(['/apply']);
            }
            if (app.currentPage) {
                if (this.pageNumber > NavigationOptions.GetPageIndexByAlias(app?.maxPageReached ?? app.currentPage, app, true)) {
                    this.application = null;
                    return this.router.navigate(['/apply']);
                }
            }

            if (this.history.isEmpty()) {
                if (this.pageNumber === 0) {
                    this.history.init(this.router.url);
                } else {
                    this.prePopulateHistory(app);
                }
            } else if (this.history.Count() == 1 && this.pageNumber !== 0) {
                this.prePopulateHistory(app);
            }
            this.afterInitPage();
        });
    }

    private prePopulateHistory(app: Application) {
        const currentPageIndex = NavigationOptions.GetPageIndexByAlias(app.currentPage, app, true);
        const visiblePages = NavigationOptions.AppPages(app, true);
        this.history.init(visiblePages[0].route);
        for (let i = 1; i <= currentPageIndex; i++) {
            this.history.forward(visiblePages[i].route);
        }
    }

    next(numberToMove?: number) {
        numberToMove = numberToMove || 1;
        const currentAlias = this.application.currentPage;
        const currentMaxPageReached = this.application.maxPageReached;
        const newPage = NavigationOptions.AppPages(this.application, true)[this.pageNumber + numberToMove];
        const newPageAlias = newPage.alias;
        this.application.currentPage = newPageAlias;
        this.application.maxPageReached = this.getMaxPageAlias(newPageAlias, this.application);

        this.save().then(success => {
            if (success) {
                this.history.forward(newPage.route);
                this.router.navigateByUrl(newPage.route);
                window.scrollTo(0, 0);
            } else {
                this.application.currentPage = currentAlias;
                this.application.maxPageReached = currentMaxPageReached;
            }
        }).catch(err => {
            this.application.currentPage = currentAlias;
            this.application.maxPageReached = currentMaxPageReached;
        });
    }

    back() {
        const currentAlias = this.application.currentPage;
        const prevPageRoute = this.history.back();
        const prevPage = NavigationOptions.AppPages(this.application, true).filter(r => r.route == prevPageRoute)[0];
        this.application.currentPage = prevPage.alias;
        this.save().then(success => {
            if (success) {
                this.router.navigateByUrl(prevPageRoute);
            } else {
                //roll back history change
                this.application.currentPage = currentAlias;
                const currPageRoute = NavigationOptions.AppPages(this.application, true).filter(r => r.alias == currPageRoute)[0].route;
                this.history.forward(currPageRoute);
            }
        });
    }

    save(): Promise<boolean> {
        return this.application.save()
            .then(id => {
                this.currentAppSvc.setCurrentApplication(this.application);
                this.afterSave(id);
                return true;
            }, err => {
                this.notificationService.push({
                    severity: 'error',
                    summary: 'Failed to save application.',
                    detail: err,
                });
                return false;
            })
            .catch((error) => {
                this.notificationService.push({
                    severity: 'error',
                    summary: 'Failed to save application.',
                    detail: error,
                });
                return false;
            });
    }

    getMaxPageAlias(pageAlias: string, app: Application): string {
        const pageIndex = NavigationOptions.GetPageIndexByAlias(pageAlias, app, true);
        const maxIndex = NavigationOptions.GetPageIndexByAlias(this.application?.maxPageReached ?? '', app, true);

        return pageIndex > maxIndex ? pageAlias : this.application.maxPageReached;
    }

    get pageNumber() {
        return NavigationOptions.GetPageIndexByAlias(this.pageAlias, this.application, true);
    }

    toNumber(value) {
        return this.utilities.toNumber(value);
    }

    goToPage(path: string) {
        const newPage = NavigationOptions.AppPages(this.application, true).filter(p => p.route == path)[0];
        const newPageAlias = newPage.alias;
        this.application.currentPage = newPageAlias;
        this.application.maxPageReached = this.getMaxPageAlias(newPageAlias, this.application);
        this.history.forward(path);
        this.router.navigateByUrl(path);
    }

    get showBack() {
        return this.history.Count() > 1;
    }

    afterInitPage() {
        // virtual method to override
    }

    afterSave(id: number){
        // virtual method to override
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string): Promise<boolean>{
        // virtual method to override
        throw console.error('validateSaveFunction not implemented.');
    }

    scrollToError() {
        const element: any = document.querySelector('.ng-invalid');
        if (element) {
            element.scrollIntoView();
            window.scrollBy(0, -100);
        }
    }
}
