<div class="container stage main_container">
    <gol-side-nav selectedAlias="income" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Financial Information - Balance Sheet</h1>
        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div>
        <div class="row">
            <div class="col-sm-6">
                <p class="text-small">All fields are required unless otherwise noted.</p>
            </div>
        </div>
        <div class="row">
            <div fxFlex.gt-md="60">
                <form *ngIf="!review" ngFormModel="form" (ngSubmit)="nextQuestion()" novalidate class="panel-section">
                    <gol-simple-question-component *ngIf="questions && currentIsSimple" [form]="form"
                        [questionIndex]="currentIndex" [question]="currentQuestion" [Characters]="characters">
                    </gol-simple-question-component>
                    <gol-complex-question-component *ngIf="questions && !currentIsSimple" [form]="form"
                        [questionIndex]="currentIndex" [question]="currentQuestion" [Characters]="characters">
                    </gol-complex-question-component>
                    <div *ngIf="!questions">
                        There are no questions currently configured for this wizard.
                    </div>
                    <div class="buttonContainer group" fxLayoutAlign="space-evenly">
                        <button *ngIf="!editing" class="btn btn-secondary back-btn" type="button"
                            (click)="wizardBack()">Back</button>
                        <button type="submit" class="btn btn-primary">{{(editing) ? 'Update' : 'Next'}}</button>
                    </div>
                </form>
                <div *ngIf="review" class="group">
                    <gol-financial-summary [application]="application" [characters]="characters" [questions]="questions"
                        (navigateWizard)="navigateWizard($event)"></gol-financial-summary>
                    <div class="buttonContainer group">
                        <button class="btn btn-secondary back-btn" type="button" (click)="wizardBack()" fxFlex.lt-md="40">Back</button>
                        <button type="button" class="btn btn-primary" (click)="submit()" fxFlex.lt-md="40">Continue</button>
                </div>
                </div>
            </div>
            <div class="col-sm-1"></div>
            <gol-contact-component></gol-contact-component>
        </div>
    </div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>