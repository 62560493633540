<div class="preference-group group raised-card">
    <h2>Communication Preferences</h2>
    <gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
    <div *ngIf="!loading && error" class="alert">
        There was an error pulling the account preferences.
    </div>
    <div *ngIf="!loading && !alertChangeComplete && !error">
        <p>We'd like to offer quicker ways to update you on your account. Please select how you would like to receive
            alerts outside of mail.</p>
        <p>You will receive alerts via the following methods when changes are made to your account, statements become
            available, if there is an upcoming payment due or if a payment is received.</p>
        <div class="choice">
            <p-checkbox label="Email" [(ngModel)]="contactDetails?.UserPreferences.EmailOptIn" binary="true">
            </p-checkbox>
            <div *ngIf="contactDetails?.UserPreferences.EmailOptIn">
                <p>Email alerts will be sent to <label>{{contactDetails?.UserPreferences.EmailAddress}}</label>. You can
                    change this in the <a routerLink="/dashboard/preferences/contact">Contact Information</a> tab above.
                </p>
            </div>
        </div>
        <!--Commenting this out until SMS Messaging capabilities are established. - 6/19/2018 A. Mulchek-->
        <!--<div class="choice">
            <p-checkbox label="Text (SMS) Messaging" [(ngModel)]="contactDetails?.UserPreferences.TextOptIn" binary="true"></p-checkbox>
            <div *ngIf="contactDetails?.UserPreferences.TextOptIn">
                <p>Please enter a phone number to receive Text (SMS) alerts to, or select from your existing mobile numbers:</p>
                <div class="phoneSelection">
                    <mat-radio-group class="mat-radio-group" [(ngModel)]="contactDetails.UserPreferences.PhoneNumber">
                        <span *ngFor="let phoneNumber of contactDetails?.PhoneNumbers; let i=index">
                            <mat-radio-button *ngIf="phoneNumber.IsMobile" class="mat-radio-button" value="{{phoneNumber.PhoneNumber}}">
                                ({{phoneNumber.PhoneNumber.substr(0, 3)}}) {{phoneNumber.PhoneNumber.substr(3, 3)}}-{{phoneNumber.PhoneNumber.substr(6, 4)}}
                            </mat-radio-button>
                        </span>
                        <mat-radio-button class="mat-radio-button" value="newNumberRadio">New Number</mat-radio-button>
                    </mat-radio-group>
                    <form *ngIf="contactDetails.UserPreferences.PhoneNumber == newNumberRadio" ngFormModel="form" (ngSubmit)="update()" novalidate class="newNumberForm">
                        <phone-component *ngIf="contactDetails.UserPreferences.PhoneNumber == newNumberRadio" Title="New Phone Number" name="newNumber" [(ngModel)]="newNumber" [form]="form" [isrequired]="true"></phone-component>
                    </form>
                </div>
            </div>
        </div>
        <p class="alert" *ngIf="textNumberError">Please select a valid phone number for Text (SMS) Messaging</p>-->
        <button class="btn btn-primary" type="button" (click)="update()" [disabled]="loading">Update</button>
    </div>
    <div *ngIf="!loading && alertChangeComplete">
        <p>Your preferences were successfully changed!</p>
        <button type="button" class="btn btn-primary" (click)="resetForm()">OK</button>
    </div>
</div>