import { Injectable } from '@angular/core';
import { Application } from '../models/application';
import { ApplicationHttpService } from './application-http.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { Person } from '../models/person';
import { ErrorHandling } from '../shared/error-handling';
import { LoggingService } from './logging.service';

@Injectable()
export class CurrentApplicationService {
    constructor(
        private appHttpService: ApplicationHttpService,
        private userService: UserService,
        public authService: AuthService,
        private storageService: StorageService,
        private logService: LoggingService) {
    }
    private currentApp: Application;
    private appStorageKey = 'currentApplication';
    private appLock: boolean = false;

    setCurrentApplication(app: Application) {
        this.currentApp = app;
        if (!app) {
            this.storageService.deleteFromLocal(this.appStorageKey);
        } else {
            this.storageService.storeInLocal(this.appStorageKey, app.id);
        }
    }

    hasCurrentApplication() {
        return !!(this.userService.GetUser() && (this.currentApp || this.storageService.getFromLocal(this.appStorageKey)));
    }

    getCurrentApplication(callingComponent: string = ''): Promise<Application> {
        if (!this.currentApp) {
           return this.getCurrentApplicationRecursive(callingComponent);
        }
        return Promise.resolve(this.currentApp);
    }

    private  getCurrentApplicationRecursive(callingComponent: string = '', timesCalled:number = 0): Promise<Application> {
        const numTimes = timesCalled + 1;
        // if debugging, uncomment following line to track recursion.
        // console.log('getCurrentApplication start from ', callingComponent +  ` ${numTimes}`);
        if (this.appLock === true) {
            const wait = new Promise(resolve => setTimeout(resolve, 1000));
            return wait.then(res => {
                return this.getCurrentApplicationRecursive(callingComponent, numTimes);
            });
        }

        if (!this.currentApp) {
            this.appLock = true;
            const storageAppId = this.storageService.getFromLocal(this.appStorageKey);
            if (storageAppId && this.userService.GetUser()) {
                return new Promise((resolve) => {
                    this.appHttpService.getApplication(+storageAppId)
                        .then(app => {
                            this.setCurrentApplication(app);
                            this.appLock = false;
                            this.logService.recordError(`CurrentApplicationService.getCurrentApplicationRecursive completed`, null, null, 'unknown');
                            resolve(this.currentApp);
                        })
                        .catch(reason => {
                            const msg = ErrorHandling.extractMessage(reason);
                            this.logService.recordError(`CurrentApplicationService.getCurrentApplicationRecursive error`, msg, null, 'unknown');
                            this.makeNewApp();
                            this.appLock = false;
                            resolve(this.currentApp);
                        });
                });
            } else {
                this.makeNewApp();
                this.appLock = false;
            }
        }
        this.appLock = false;
        return Promise.resolve(this.currentApp);
    }

    makeNewApp(data?) {
        this.currentApp = new Application(this.appHttpService, this.userService, this.authService, data);
    }

    getPreApprovalStagingForApplication(stagingId: number): Promise<Person> {
        return new Promise((resolve, reject) => {
            this.appHttpService.getPreApprovalStagingForApplication(stagingId)
                .then(res => {
                    return resolve(res);
                });
        });
    }
}
