<gol-fullname-component name="fullname" [fullName]="owner.fullName"
    Title="{{mode === 'signer' ? 'Signer Information' : 'Owner Name'}}" [form]="form" [group]="group"
    [disabled]="readonly"></gol-fullname-component>

<div class="form-group">
    <gol-date-box [(ngModel)]="owner.dateOfBirth" label="{{getTitlePrefix()}}Date of Birth" golDateRangeValidator
        [min]="minDob" [max]="maxDob" required name="dob" #dob="ngModel" [disabled]="readonly"></gol-date-box>
    <div *ngIf="(form.submitted || dob.dirty) && dob.invalid" class="error">
        <p *ngIf="dob.errors['required']">A date is required</p>
        <p *ngIf="dob.errors['invalidDate']">That is not a valid date</p>
        <p *ngIf="dob.errors['precedesMin']">Please select a date after {{dob.errors['precedesMin'].toDateString()}}</p>
        <p *ngIf="dob.errors['exceedsMax']">Please select a date before {{dob.errors['exceedsMax'].toDateString()}}</p>
    </div>
</div>

<div class="form-group">
    <label for="countryOfPermanentResidence">Country of Permanent Residence</label>
    <select [(ngModel)]="owner.countryOfPermanentResidence" 
            name="countryOfPermanentResidence" 
            class="form-control"
            #countryOfPermanentResidence="ngModel" 
            [disabled]="readonly"
            required>
        <option *ngFor="let x of addressCountries" value="{{x}}">{{x}}</option>
    </select>
    <div *ngIf="(form.submitted || countryOfPermanentResidence.dirty) && countryOfPermanentResidence.invalid" class="error">
        Required
    </div>
</div>
    
<div class="form-group" *ngIf="!owner.isControllingParty">
    <label for="ownerPercent">Ownership Percentage</label>
    <input name="ownerPercent" class="form-control" [(ngModel)]="owner.ownershipPercentage"
        placeholder="Ownership Percentage" required #ownerPercent="ngModel" validate-number min="0" max="100"
        allowDecimal="2" [textMask]="{mask: hundredMask, guide:false}" />
    <div class="postfix">%</div>
    <div class="error" *ngIf="(form.submitted || ownerPercent.dirty) && ownerPercent.invalid">
        <p *ngIf="ownerPercent.errors['required']">Required</p>
        <p *ngIf="ownerPercent.errors['validateNumber']">Ownership Percentage is invalid. Must be non negative numeric
            value between 0 and 100</p>
    </div>
</div>

<div class="form-group">
    <label for="signerTitle">Company Role/Title</label>
    <input #signerTitle="ngModel" name="signerTitle" class="form-control" [(ngModel)]="owner.signerTitle" required
        placeholder="Title">
    <div *ngIf="(form.submitted || signerTitle.dirty) && signerTitle.invalid" class="error">
        <p *ngIf="signerTitle.errors">Required</p>
    </div>
</div>

<div class="form-group">
    <label>Email</label>
    <input name="email" class="form-control" [(ngModel)]="owner.email" placeholder="Email" required type="email"
        #email="ngModel" validate-email />
    <div class="error" *ngIf="(form.submitted ||email.dirty) && email.invalid">
        <p *ngIf="email.errors['required']">Required</p>
        <p *ngIf="email.errors['validateEmail']">Email is invalid</p>
    </div>
</div>
