import { Person } from './person';
import { Entity } from './entity';
import { BalanceSheet } from './balance-sheet';
import { Income } from './income';
import { IApplicationHttpService } from '../services/application-http.service';
import { Vendor } from './vendor';
import { IUserService } from '../services/user.service';
import { ApplicationType } from './application-type';
import { IAuthService } from '../services/auth.service';
import { GrowerOnline } from './constants';
import { ErrorHandling } from '../shared/error-handling';

export class Application {
    id: any;
    appType: ApplicationType = ApplicationType.Single;
    additionalOwners: Person[] = [];
    borrowers: Person[] = [];
    balanceSheet: BalanceSheet = new BalanceSheet;
    vendors: Vendor[] = [new Vendor()];
    income: Income = new Income;
    entity: Entity = null;
    status = 'Draft';
    currentPage = '';
    lineOfBusiness = '';
    createdDate: Date;
    onlineAgreementAcceptanceDate: Date;
    preApprovalStagingId: number = -1;
    preApprovalCode: string = '';
    preApprovalBorrower: Person;
    preApprovalVendor: Vendor;
    hasEntityOwnership: boolean = null;
    increasedRiskCountries: string[];
    originatingSite = GrowerOnline;
    hasTrust: boolean = false;
    hasIosWithPercent: boolean = false;
    hasIndividualsWithPercent: boolean = false;
    maxPageReached: string = '';

    constructor(
        private appHttpService: IApplicationHttpService,
        private userService: IUserService,
        private authService: IAuthService,
        appData?: any) {
        if (appData) {
            this.id = appData.ID;
            this.status = appData.Status;
            this.appType = appData.AppType;
            this.borrowers = Array.from(appData.Borrowers, (data) => new Person(data));
            this.vendors = Array.from(appData.Vendors, (data) => new Vendor(data));
            this.entity = appData.Entity ? new Entity(appData.Entity) : null;
            this.balanceSheet = new BalanceSheet(appData.BalanceSheet);
            this.income = new Income(appData.Income);
            this.lineOfBusiness = appData.LineOfBusiness;
            this.createdDate = new Date(appData.CreatedDate);
            this.currentPage = appData.CurrentPage;
            this.onlineAgreementAcceptanceDate = appData.OnlineAgreementAcceptanceDate;
            this.preApprovalCode = appData.PreApprovalCode;
            this.hasEntityOwnership = appData.HasEntityOwnership;
            this.increasedRiskCountries = appData.IncreasedRiskCountries;
            this.originatingSite = appData.OriginatingSite;
            this.preApprovalStagingId = appData.PreApprovalStagingId;
            this.hasTrust = appData.HasTrust;
            this.hasIosWithPercent = appData.HasIosWithPercent;
            this.hasIndividualsWithPercent = appData.HasIndividualsWithPercent;
            this.maxPageReached = appData.MaxPageReached;

            if (this.entity) {
                for (let i = 0; i < this.entity.ultimateBeneficialOwners.length; ++i) {
                    if (!this.beneficiaryIsBorrower(this.entity.ultimateBeneficialOwners[i])) {
                        this.additionalOwners.push(this.entity.ultimateBeneficialOwners[i]);
                    }
                }

                if (this.entity.signer) {
                    this.entity.signer.isBorrower = this.beneficiaryIsBorrower(this.entity.signer);
                }
            }

            if (appData.Borrowers && appData.Borrowers.length > 0) {
                if (appData.Borrowers.filter(x => x.isPreApproved).length > 0) {
                    this.preApprovalBorrower =  new Person(appData.Borrowers.filter(x => x.isPreApproved)[0]);
                } else if (this.preApprovalStagingId > 0) {
                    this.preApprovalBorrower = new Person(appData.Borrowers[0]);
                }
            }
        }
    }

    private beneficiaryIsBorrower(person: Person): boolean {
        return !!this.borrowers.find((borrower) => {
            return borrower.email === person.email && borrower.fullName.toString() === person.fullName.toString();
        });
    }

    save() {
        if (this.appType === ApplicationType.Entity) {
            this.entity.ultimateBeneficialOwners = this.ownersList.filter(ol => ol.isControllingParty === false);
            this.entity.controllingParties = this.ownersList.filter(ol => ol.isControllingParty === true);
        }

        if (!this.userService.GetUser()) {
            return new Promise<number>((resolve, reject) => {
                this.authService.CreateAnonymousUser()
                    .then(() => this.callHttpSave())
                    .then(resolve)
                    .catch((reason) => {
                        reject(new Error('Could not create anonymous user' + reason));
                    });
            });
        } else {
            return this.callHttpSave();
        }
    }


    private callHttpSave() {
        return new Promise<number>((resolve, reject) => {
            this.appHttpService.save(this)
                .then((data: any) => {
                    this.id = data.Id;
                    this.createdDate = new Date(data.CreatedDate);
                    resolve(this.id);
                }, err =>{
                    reject(err);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }

    submit(recaptchaToken: string) {
        return new Promise<string>((resolve, reject) => {
            this.save()
                .then(() => {
                    this.appHttpService.submit(this, recaptchaToken)
                    .then(url => {
                        resolve(url);
                    }).catch(err => {
                        reject(ErrorHandling.extractMessage(err));
                    });
                });
        });
    }

    get ownersList(): Person[] {
        return this.borrowers.concat(this.additionalOwners);
    }

    totalOwnership() {
        let total = 0;
        const list = this.ownersList;
        for (let i = 0; i < list.length; i++) {
            total += +list[i].ownershipPercentage;
        }
        return total;
    }

    borrowerOwnership() {
        let total = 0;
        for (let i = 0; i < this.borrowers.length; i++) {
            total += +this.borrowers[i].ownershipPercentage;
        }
        return total;
    }

    totalCreditLimit() {
        const total = this.vendors.reduce((a, b) => a + b.creditLimit, 0);
        return total;
    }

    print() {
        return new Promise<Blob>((resolve, reject) => {
            this.appHttpService.print(this)
                .then((blob) => {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, 'Rabo AgriFinance New Application.pdf');
                    } else {
                        const fileURL = URL.createObjectURL(blob);
                        window.open(fileURL, '_blank');
                    }
                    resolve(blob);
                }, err=> {
                    reject(err);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }
}


