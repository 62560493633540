import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthorizedPartyService } from '../../../../services/authorized-party.service';
import {
    AuthorizedPartyBase,
    authorizedPartyIdStorageKey
} from '../authorized-party-base';
import { TopForm } from '../../../../form/top-form';
import { AuthorizedPartyRequest } from '../../../../models/authorized-party-request';
import { dateRangeValidator } from '../../../../form/validation/date-range-validator.directive';
import { validateIsNotPOBox } from '../../../../form/validation/is-not-po-box';
import { validateEmailFactory } from '../../../../form/validation/email-validator.directive';
import { AccountHttpService } from '../../../../services/account-http.service';
import { StorageService } from '../../../../services/storage.service';
import { LookupService } from '../../../../services/lookup.service';

import * as moment from 'moment';

@Component({
    selector: 'gol-add-authorized-party',
    templateUrl: 'add-form.component.html',
    styleUrls: ['../authorized-party.style.css', '../../../../form/styles_form.css', '../../../../form/summary/waiting.css']
})
export class AuthorizedPartyAddFormComponent extends AuthorizedPartyBase implements OnInit {
    form: TopForm;
    authorizedPartyReq: AuthorizedPartyRequest = new AuthorizedPartyRequest();

    maxDob: Date = moment().subtract(18, 'years').toDate();
    minDob: Date = new Date(1880, 0, 1);

    public checkForm = true;
    public success = false;
    public waitingOnDocuSign = false;
    public submitted = false;

    public ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    citizenshipCountries: string[] = null;

    constructor(private authorizedPartyService: AuthorizedPartyService,
        route: ActivatedRoute, accountService: AccountHttpService, private storageService: StorageService, private router: Router,
        private lookupService: LookupService) {
        super(route, accountService);

        this.authorizedPartyReq.USCitizen = true;
    }

    ngOnInit() {
        this.form = new TopForm({
            firstname: new FormControl('', [Validators.required]),
            middlename: new FormControl('', []),
            lastname: new FormControl('', [Validators.required]),
            suffix: new FormControl('', []),
            dob: new FormControl('', [Validators.required, dateRangeValidator(this.minDob, this.maxDob)]),
            ssn: new FormControl('', [Validators.required, Validators.pattern(/^\d{3}-\d{2}-\d{4}$/)]),
            streetaddress: new FormControl('', [Validators.required, validateIsNotPOBox()]),
            city: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, validateEmailFactory()]),
            uscitizen: new FormControl(true, [Validators.required]),
            permanentresident: new FormControl('', [this.requiredIfNotUSCitizen()]),
            countryofcitizenship: new FormControl('', [this.requiredIfNotUSCitizen()])
        });

        this.CurrentCommitment.then((c) => {
            this.authorizedPartyReq.AcctRefNo = c.AcctRefNo;
        });

        this.lookupService.getCitizenshipCountriesExceptUSA()
            .then(resolve => {
                this.citizenshipCountries = [''].concat(resolve);
            });
    }

    public get nonUSCitizenFieldsRequired(): boolean {
        return this.authorizedPartyReq && this.authorizedPartyReq.USCitizen.toString() === 'false';
    }

    private requiredIfNotUSCitizen(): (c: FormControl) => any {
        return (c: FormControl) => {
            if (this.nonUSCitizenFieldsRequired && Validators.required(c)) {
                return { required: true };
            }
            return null;
        };
    }

    public submit(): void {
        this.form.submitted = true;

        if (this.form.valid) {
            this.checkForm = false;
            this.submitting();

            this.authorizedPartyService.addAuthorizedParty(this.authorizedPartyReq)
                .then((response) => {
                    this.storageService.storeInSession(authorizedPartyIdStorageKey, response.AuthorizedPartyRequestId + '');
                    if (!response.Error) {
                        this.submitted = true;
                        window.location.href = response.DocuSignUrl;
                    } else {
                        this.router.navigate(
                            ['dashboard', 'account', this.accountNumber, 'authorized-party', 'response'],
                            { queryParams: { 'event': response.Error } });
                    }
                })
                .catch((error) => {
                    this.storageService.storeInSession(authorizedPartyIdStorageKey, '0');
                    this.router.navigate(['dashboard', 'account', this.accountNumber, 'authorized-party', 'response'],
                        { queryParams: { 'event': 'server_error' } });
                });
        }
    }

    submitting(): void {
        this.form.disable();
        this.waitingOnDocuSign = true;
    }

    public isFormControlInvalid(control: string, specificErrorType: string = null): boolean {
        if (specificErrorType == null) {
            return this.checkForm
                && !this.form.controls[control].valid
                && ((this.form.controls[control].dirty || this.form.controls[control].touched) || this.form.submitted);
        } else {
            return this.checkForm
                && this.form.controls[control].hasError(specificErrorType)
                && ((this.form.controls[control].dirty || this.form.controls[control].touched) || this.form.submitted);
        }
    }

    public get physicalAddressRequiredInvalid(): boolean {
        return this.isFormControlInvalid('streetaddress', 'required');
    }

    public get physicalAddressIsPOBoxInvalid(): boolean {
        return this.isFormControlInvalid('streetaddress', 'isPOBox');
    }
}
