import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import * as moment from 'moment';
import { InterruptSource, InterruptArgs } from '@ng-idle/core';
import { User } from '../models/user';
import { PasswordChange } from '../models/password-change';
import { UserService } from './user.service';
import { CONFIG } from '../../environments/environment';
import { UserToken } from '../models/user-token';
import { LoggingService } from './logging.service';
import { Router } from '@angular/router';
import { NotificationService } from 'if-angular-core';


@Injectable()
export class AuthService extends InterruptSource implements IAuthService {
    private headers = new HttpHeaders({ 'content-type': 'application/json' });
    private resourceUrl = CONFIG.apiBaseUri + 'Account';

    constructor(private http: HttpClient, private userService: UserService, private logService: LoggingService, private router: Router, private notifySvc: NotificationService) {
        super(null, null);
    }

    private handleError(error: any) {
        const errorErrorMessage: string = error && error.error ? error.error.Message : null;
        let errorMessage: string = error && error.message ? error.message : null;
        let parsed: any = {};
        try {
            parsed = JSON.parse(error._body);
        } catch (err) {
            if (error.status === 401) {
                errorMessage = 'Authorization failed';
            }
        }
        const msg = errorErrorMessage || errorMessage || parsed.Message || parsed.message || 'Server error.  Please try again later.';
        this.logService.recordError(`AuthService.handleError completed`, msg, error, 'unknown');
        return Promise.reject(msg);
    }

    public Register(user: User,
        recaptchaToken: string,
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date,
        accountNumber: any,
        ssn: any,
        zip: any): Promise<boolean | Object> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.Register start, firstname: ${user?.firstName}, recaptcha not empty: ${recaptchaToken && recaptchaToken.length > 0}`, null, null, currentUser);

        const data: any = user;
        data.recaptchaToken = recaptchaToken;
        data.onlineAgreementAcceptanceDate = agreementAcceptedDate;
        data.eStatementAcceptanceDate = eStatementAcceptanceDate;
        data.achUSBankStatementAcceptanceDate = achUSBankStatementAcceptanceDate;
        data.accountNumber = accountNumber;
        data.ssn = ssn;
        data.zip = zip;

        return this.http
            .post(this.resourceUrl + '/Register', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then((res: UserToken) => {

                const respUser = new User();
                respUser.isAnonymous = false;
                respUser.email = res.userName;
                respUser.firstName = res.firstName;
                respUser.lastName = res.lastName;
                respUser.userId = res.userID;
                respUser.authToken = res.access_token;
                respUser.authExpires = moment().add(res.expires_in, 's').toDate();
                respUser.authCreated = new Date();
                respUser.cifno = res.cifno;
                this.userService.SetUser(respUser);
                this.logService.recordInfo(`AuthService.Register completed, returned: ${respUser?.firstName}`, null, null, currentUser);
                return true;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.Register error, firstname: ${user?.firstName}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public CreateAnonymousUser(): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.CreateAnonymousUser start`, null, null, currentUser);

        return this.http
            .post(this.resourceUrl + '/CreateAnonymousUser', '', { headers: this.headers })
            .toPromise()
            .then(res => {
                this.updateUserAndTokens(res, true);
                this.logService.recordInfo(`AuthService.CreateAnonymousUser completed, returned: ${true}`, null, null, currentUser);
                return true;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.CreateAnonymousUser error.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public GetSecurityQuestion(username: string, password: string, passwordResetToken: string): Promise<any> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.GetSecurityQuestion start, username: ${username}`, null, null, currentUser);
        const data = { Email: username, Password: password, ResetPasswordToken: passwordResetToken };

        return this.http
            .post(this.resourceUrl + '/GetSecurityQuestion', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(question => {
                this.logService.recordInfo(`AuthService.GetSecurityQuestion completed, username: ${username}, returned: ${true}`, null, null, currentUser);
                return question;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    let msg = '';
                    if (error.error) {
                        msg = error.error;
                    } else {
                        msg = password && password !=='' ? 'Username or password is incorrect' : 'Invalid user or reset token has expired';
                    }
                    this.logService.recordError(`AuthService.GetSecurityQuestion error, username: ${username}.`, msg, null, currentUser);
                    return Promise.reject(msg);
                }
                this.logService.recordError(`AuthService.GetSecurityQuestion error, username: ${username}.`, error.message || error, null, currentUser);
                return this.handleError(error);
            });
    }

    public GetLastLoginMessage(username: string): Promise<any> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.GetLastLoginMessage start, username: ${username}`, null, null, currentUser);

        const data = { Email: username,};
        const browserDate = new Date();
        const offsetMinutes = -1 * browserDate.getTimezoneOffset(); //javascript treats the offset direction the opposite way that dotnet does.
        return this.http
            .get(this.resourceUrl + `/GetLastLoginMessage/?email=${username}&utcOffsetMinutes=${offsetMinutes}`, { headers: this.headers })
            .toPromise()
            .then(result => {
                this.logService.recordInfo(`AuthService.GetLastLoginMessage completed, username: ${username}, returned: ${result}`, null, null, currentUser);
                return result;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    let msg = '';
                    if (error._body) {
                        msg = error._body;
                    } else {
                        msg = 'Unable to retrieve login message for user:' + username;
                    }
                    this.logService.recordError(`AuthService.GetLastLoginMessage error, username: ${username}.`, msg, null, currentUser);
                    return Promise.reject(msg);
                }
                this.logService.recordError(`AuthService.GetLastLoginMessage error, username: ${username}.`, error.message || error, null, currentUser);
                return this.handleError(error);
            });
    }

    public Login(username: string, password: string, securityQuestion: string, securityAnswer: string): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.Login start, username: ${username}`, null, null, currentUser);
        const data = { Email: username, Password: password, Question: securityQuestion, Answer: securityAnswer };

        return this.http
            .post(this.resourceUrl + '/Login', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.updateUserAndTokens(res, false);
                this.logService.recordInfo(`AuthService.Login completed, username: ${username}, returned: ${true}`, null, null, currentUser);
                return true;
            })
            .catch((error: any) => {
                if (error.status === 401) {
                    this.logService.recordError(`AuthService.Login error, username: ${username}.`,'Incorrect answer to security question', null, currentUser);
                    return Promise.reject('Incorrect answer to security question');
                }
                this.logService.recordError(`AuthService.Login error, username: ${username}.`, error.message || error, null, currentUser);
                return this.handleError(error);
            });
    }

    public RefreshTokens(): Promise<boolean | Object> {
        const response = new Promise((resolve, reject) => {
            const user = this.userService.GetUser();
            const currentUser = user?.email ?? 'unknown';
            this.logService.recordInfo(`AuthService.RefreshTokens start`, null, null, currentUser);

            if (user && user.refreshToken) {
                const data = { RefreshToken: user.refreshToken };
                this.http
                    .post(this.resourceUrl + '/RefreshLogin', JSON.stringify(data), { headers: this.headers })
                    .toPromise()
                    .then(res => {
                        this.updateTokens(res);
                        if (this.isAttached) {
                            const args = new InterruptArgs(this, new Date());
                            this.onInterrupt.emit(args);
                        }
                        this.logService.recordInfo(`AuthService.RefreshTokens completed, username: ${currentUser}, returned: ${true}`, null, null, currentUser);
                        resolve(true);
                    })
                    .catch(reason => {
                        this.logService.recordError(`AuthService.RefreshTokens exception`, reason.message || reason, null, currentUser);
                        reject(reason);
                    });
            } else {
                this.logService.recordError(`AuthService.RefreshTokens error`, 'Missing user or refresh token', null, currentUser);
                reject(new Error('Missing user or refresh token'));
            }
        });

        return response;
    }

    private updateUserAndTokens(token, isAnonymous): User {
        const user = this.updateTokens(token);
        user.isAnonymous = isAnonymous;
        user.email = token.userName;
        user.firstName = token.firstName;
        user.lastName = token.lastName;
        user.userId = token.userID;
        user.cifno = token.cifno;
        this.userService.SetUser(user);
        return user;
    }

    private updateTokens(token): User {
        let user = this.userService.GetUser();
        this.logService.recordInfo('AuthService.updateTokens authToken', token.access_token === '' || token.access_token == undefined ? 'Token blank or empty' : 'Token present', null, user?.email);
        this.logService.recordInfo('AuthService.updateTokens refreshToken', token.refresh_token === '' || token.refresh_token == undefined ? 'Token blank or empty' : 'Token present', null, user?.email);
        if (!user) {
            user = new User();
        }
        user.authToken = token.access_token;
        user.refreshToken = token.refresh_token;
        user.authExpires = moment().add(token.expires_in, 's').toDate();
        user.authCreated = new Date();
        this.userService.SetUser(user);
        this.userService.startIdleClock();
        return user;
    }

    public UpdateUser(user: User): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.UpdateUser start, email: ${user?.email}`, null, null, currentUser);

        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/UpdateUser', JSON.stringify(user), { headers: headers })
            .toPromise()
            .then(res => {
                this.userService.SetUser(user);
                this.logService.recordInfo(`AuthService.UpdateUser completed, email: ${user?.email}, returned: ${res}`, null, null, currentUser);
                return true;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.UpdateUser error, email: ${user?.email}.`, err.message || err, null, currentUser);
               return this.handleError(err);
            });
    }

    public ChangePassword(passwordChange: PasswordChange): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.ChangePassword start, credentials provided: ${passwordChange && passwordChange.oldPassword && passwordChange.oldPassword.length > 0}`, null, null, currentUser);
        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/ChangePassword', JSON.stringify(passwordChange), { headers: headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.ChangePassword completed, returned: ${true}`, null, null, currentUser);
                return true;
            })
            .catch((err) => {
                const modelMessage = this.GetErrorFromModelState(err);
                if (modelMessage) {
                    this.logService.recordError(`AuthService.ChangePassword error.`, modelMessage, null, currentUser);
                    return Promise.reject(modelMessage);
                }
                this.logService.recordError(`AuthService.ChangePassword error.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    private GetErrorFromModelState(err): string {
        let parsed: any = {};
        try {
            parsed = JSON.parse(err._body);
        } catch (e) {
        }
        if (parsed.ModelState && parsed.ModelState[''] && parsed.ModelState[''].length) {
            return parsed.ModelState[''][0];
        }
        return null;
    }

    public InitiatePasswordReset(email: string): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.InitiatePasswordReset start, email: ${email}`, null, null, currentUser);

        return this.http
            .post(this.resourceUrl + '/InitiatePasswordReset',
                JSON.stringify({ 'email': email, websiteName: 'GrowerOnline' }),
                { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.InitiatePasswordReset completed, email: ${email}, returned: ${true}`, null, null, currentUser);
                return true;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.InitiatePasswordReset error, email: ${email}.`, err.message || err, null, currentUser);
               return this.handleError(err);
            });
    }

    public ResetPassword(email: string, token: string, newPassword: string, question: string, answer: string): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.ResetPassword start, email: ${email}`, null, null, currentUser);

        const data = { 'Email': email, 'Token': token, 'NewPassword': newPassword, 'Question': question, 'Answer': answer };
        return this.http
            .post(this.resourceUrl + '/ResetPassword', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.ResetPassword completed, email: ${email}, returned: ${true}`, null, null, currentUser);
                return true;
            })
            .catch((err: any) => {
                if (err.status === 401) {
                    this.logService.recordError(`AuthService.ResetPassword error, email: ${email}.`,'Incorrect answer to security question', null, currentUser);
                    return Promise.reject('Incorrect answer to security question');
                }
                this.logService.recordError(`AuthService.ResetPassword error, email: ${email}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public ChangeExpiredPassword(email: string, oldPassword: string, newPassword: string): Promise<boolean> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.ChangeExpiredPassword start, email: ${email}`, null, null, currentUser);

        const data = { 'Email': email, 'OldPassword': oldPassword, 'NewPassword': newPassword };
        return this.http
            .post(this.resourceUrl + '/ChangeExpiredPassword', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.ChangeExpiredPassword completed, email: ${email}, returned: ${true}`, null, null, currentUser);
                return true;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.ChangeExpiredPassword error, email: ${email}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public CheckAvailability(email: string, username: string): Promise<string | object> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.CheckAvailability start, email: ${email}`, null, null, currentUser);

        const data = { 'Email': email, 'UserName': username };
        return this.http
            .post(this.resourceUrl + '/CheckAvailability', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.CheckAvailability completed, email: ${email}, returned: ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.CheckAvailability error, email: ${email}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public VerifyAccountDetails(accountNumber: string, last4ssn: string, zipCode: string): Promise<number | Object> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.VerifyAccountDetails start, accountNumber: ${accountNumber}`, null, null, currentUser);

        const data = { 'AccountNumber': accountNumber, 'Last4ssn': last4ssn, 'ZipCode': zipCode };
        return this.http
            .post(this.resourceUrl + '/VerifyAccountDetails', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.VerifyAccountDetails completed, accountNumber: ${accountNumber}, returned: ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.VerifyAccountDetails error, accountNumber: ${accountNumber}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
        headers = headers || new HttpHeaders();
        const usr = this.userService.GetUser();
        this.logService.recordInfo('AuthService.createAuthorizationHeader start', null, null, usr?.userId);
        if (usr) {
            if (this.isUserAuthExpired(usr)) {
                console.error('---USER AUTH EXPIRED---');
                this.logService.recordWarn('AuthService.createAuthorizationHeader expired', '---USER AUTH EXPIRED---', null, usr?.userId);
                this.userService.Logout();
                const wait = new Promise(resolve => setTimeout(resolve, 1000));
                wait.then(res => {
                    this.notifySvc.push({
                        severity: 'error',
                        summary: 'Error Refreshing Tokens',
                        detail: 'Authorization Failed - Expired',
                        life: 12000
                    });
                });
                this.router.navigateByUrl('/home');
            } else {
                this.RefreshTokens().catch((reason) => {
                    console.count('---RefreshTokens exception---');
                    this.logService.recordError('AuthService.createAuthorizationHeader exception', reason.error?.error || reason.error || reason.Message || reason.Error, null, usr?.userId);
                    this.userService.Logout();
                    const wait = new Promise(resolve => setTimeout(resolve, 1000));
                     wait.then(res => {
                        this.notifySvc.push({
                            severity: 'error',
                            summary: 'Error Refreshing Tokens',
                            detail: 'Authorization Failed',
                            life: 12000
                        });
                    });
                    this.router.navigateByUrl('/home');
                });

                headers = headers.set('Authorization', `Bearer ${this.userService.GetUser().authToken}`);
            }
        }
        this.logService.recordInfo('AuthService.createAuthorizationHeader completed', null, null, usr?.userId);
        return headers;
    }

    private isUserAuthExpired(usr: User): boolean {
        return usr.authExpires <= new Date();
    }

    public UpdateUserAcceptanceDates(
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date): Promise<boolean | Object> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.UpdateUserAcceptanceDates start, agreementAcceptedDate: ${agreementAcceptedDate}`, null, null, currentUser);

        const headers = this.createAuthorizationHeader(this.headers);

        const data = {
            'OnlineAgreementAcceptanceDate': agreementAcceptedDate,
            'EStatementAcceptanceDate': eStatementAcceptanceDate,
            'AchUSBankStatementAcceptanceDate': achUSBankStatementAcceptanceDate
        };
        return this.http
            .post(this.resourceUrl + '/UpdateUserAcceptanceDates', JSON.stringify(data), { headers: headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.UpdateUserAcceptanceDates completed, agreementAcceptedDate: ${agreementAcceptedDate}, returned: ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.UpdateUserAcceptanceDates error, agreementAcceptedDate: ${agreementAcceptedDate}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public GetUserAcceptanceDates(): Promise<any> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.GetUserAcceptanceDates start`, null, null, currentUser);

        const headers = this.createAuthorizationHeader(this.headers);

        return this.http
            .post(this.resourceUrl + '/GetUserAcceptanceDates', null, { headers: headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.GetUserAcceptanceDates completed, returned: ${JSON.stringify(res)}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.GetUserAcceptanceDates error.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }

    public ValidatePreApproval(code: string, name: string): Promise<any> {
        const currentUser = this.userService.GetUser()?.email ?? 'unknown';
        this.logService.recordInfo(`AuthService.ValidatePreApproval start, code: ${code}, name: ${name}`, null, null, currentUser);

        const data = { Code: code, LastName: name };

        return this.http
            .post(this.resourceUrl + '/ValidatePreApproval', JSON.stringify(data), { headers: this.headers })
            .toPromise()
            .then(res => {
                this.logService.recordInfo(`AuthService.ValidatePreApproval completed, code: ${code}, name: ${name}, returned: ${res}`, null, null, currentUser);
                return res;
            })
            .catch(err => {
                this.logService.recordError(`AuthService.ValidatePreApproval error, code: ${code}, name: ${name}.`, err.message || err, null, currentUser);
                return this.handleError(err);
            });
    }
}

export interface IAuthService {
    Register(user: User,
        recaptchaToken: string,
        agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date,
        accountNumber: any,
        ssn: any,
        zip: any);
    GetSecurityQuestion(username: string, password: string, passwordResetToken: string);
    Login(username: string, password: string, securityQuestion: string, securityAnswer: string);
    CreateAnonymousUser(): Promise<boolean>;
    UpdateUser(user: User): Promise<boolean>;
    ChangePassword(passwordChange: PasswordChange): Promise<boolean>;
    InitiatePasswordReset(email: string): Promise<boolean>;
    ResetPassword(email: string, token: string, newPassword: string, question: string, answer: string): Promise<boolean>;
    CheckAvailability(email: string, username: string): Promise<string | Object>;
    VerifyAccountDetails(accountNumber: string, last4ssn: string, zipCode: string): Promise<number | Object>;
    UpdateUserAcceptanceDates(agreementAcceptedDate: Date,
        eStatementAcceptanceDate: Date,
        achUSBankStatementAcceptanceDate: Date): Promise<boolean | Object>;
    GetLastLoginMessage(username: string): Promise<any> ;
}
