import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { User } from '../../../../app/models/user';
import { TopForm } from '../../../../app/form/top-form';
import { UserService } from '../../../../app/services/user.service';
import { ContactDetail } from '../../../../app/models/accounts/contact-detail';
import { ContactAddress } from '../../../../app/models/accounts/contact-address';
import { ContactPhone } from '../../../../app/models/accounts/contact-phone';
import { AccountHttpService } from '../../../services/account-http.service'; // TODO:MFA
import { States } from '../../../../app/models/state-list';

export const PhoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

@Component({
    selector: 'gol-preferences-contact',
    templateUrl: 'preferences-contact.component.html',
    styleUrls: [
        '../../../../app/dashboard/preferences/account-preferences.component.css',
        '../../../../app/dashboard/preferences/contact/preferences-contact.component.css'
    ]
})
export class PreferencesContactComponent implements OnInit {
    contactInfoForm: TopForm;
    user: User;
    contactInfoChangeComplete = false;
    updateInfoError: string;
    contactDetails: ContactDetail;
    loading: boolean;
    error: boolean;
    zipMask = ZipMask;
    phoneMask = PhoneMask;
    states = States;
    sameAsPhysical: boolean;
    newPhone: ContactPhone;
    addingNewPhone: boolean;

    constructor(private userService: UserService, private accountService: AccountHttpService) {
    }

    ngOnInit() {
        this.newPhone = new ContactPhone();
        this.contactInfoForm = new TopForm({
            newPhoneCtrl: new FormControl('', [Validators.minLength(14), Validators.maxLength(14)]),
        });

        this.getUserData();
    }

    getUserData() {
        window.scrollTo(0, 0);
        this.loading = true;
        this.user = new User(this.userService.GetUser());
        this.accountService.getCustomerContactDetails(this.user.cifno)
            .then((result) => {
                this.contactDetails = result;
                this.checkPhysicalAddress();
            })
            .catch(() => {
                this.error = true;
            })
            .then(() => {
                this.loading = false;
            });
    }

    checkPhysicalAddress() {
        if (this.contactDetails.PhysicalAddress) {
            this.sameAsPhysical = false;
        } else {
            this.sameAsPhysical = true;
        }
        this.setPhysicalAddressValidators();
    }

    setPhysicalAddressValidators() {
        if (!this.contactDetails.PhysicalAddress) {
            this.contactDetails.PhysicalAddress = new ContactAddress();
        }
    }

    formatPhoneNumber(phoneNumber: string): string {
        return '(' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6);
    }

    addPhone(add: boolean) {
        this.addingNewPhone = add;

        const newPhoneCtrl = this.contactInfoForm.get('newPhoneCtrl');
        if (add) {
            newPhoneCtrl.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(14)]);
        } else {
            newPhoneCtrl.setValidators([Validators.minLength(14), Validators.maxLength(14)]);
            newPhoneCtrl.setValue('');

            if (this.contactDetails.PhoneNumbers && this.contactDetails.PhoneNumbers.length > 0) {
                const primaryPhone = this.contactDetails.PhoneNumbers.find(p => p.IsPrimary).PhoneNumber;

                if (primaryPhone) {
                    this.contactDetails.PrimaryPhoneNumber = primaryPhone;
                }
            }
        }
        newPhoneCtrl.updateValueAndValidity();
    }

    contactInfoSubmit() {
        this.updateInfoError = null;
        this.contactInfoForm.submitted = true;

        if (this.contactInfoForm.valid) {
            this.loading = true;
            if (this.addingNewPhone) {
                this.contactDetails.PhoneNumbers.push(this.newPhone);
            }
            if (this.sameAsPhysical) {
                this.contactDetails.PhysicalAddress = null;
            }
            this.accountService.updateCustomerContactDetails(this.contactDetails)
                .then((res) => {
                    this.contactDetails = res;
                    const user = this.userService.GetUser();
                    this.userService.SetUser(user);
                    this.checkPhysicalAddress();
                    this.contactInfoChangeComplete = true;
                    this.updateInfoError = null;
                })
                .catch((rejected) => {
                    this.updateInfoError = rejected.Message;
                })
                .then(() => {
                    this.loading = false;
                });
        } else {
            this.updateInfoError = 'Invalid data';
        }
    }

    closeComplete() {
        this.contactInfoChangeComplete = false;
        this.contactInfoForm.submitted = false;
        this.contactInfoForm.markAsPristine();
        this.addPhone(false);
        this.newPhone = new ContactPhone();
        window.scrollTo(0, 0);
    }
}

export function ZipMask(inputValue) {
    let array: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/];
    if (inputValue.length > 5) {
        array = array.concat(['-', /\d/, /\d/, /\d/, /\d/]);
    }
    return array;
}
