import { Component, Input } from '@angular/core';

@Component({
    selector: 'gol-payment-navigation',
    templateUrl: 'payment-navigation.component.html',
    styleUrls: ['../../../form/reusables/view/progress-navigation.css', 'payments.component.css']
})
export class PaymentNavigationComponent {

    @Input() selectedAlias: string;

    constructor() {
        this.options = [
            { alias: 'Details', name: 'Details' },
            { alias: 'Review', name: 'Review' },
            { alias: 'Confirmation', name: 'Confirmation' }
        ];
    }

    options: any[];
    showingMenu = false;
    closeProgressNav = true;

    isDisabled(alias: string) {
        return this.getIndexByAlias(alias) > this.getIndexByAlias(this.selectedAlias);
    }

    isSelected(alias: string) {
        return this.getIndexByAlias(alias) === this.getIndexByAlias(this.selectedAlias);
    }

    getIndexByAlias(alias: string): number {
        return this.options.findIndex((page) => page.alias === alias);
    }

    showMenu(show: boolean) {
        this.showingMenu = show;
    }
}
