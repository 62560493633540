import { Component, Input, forwardRef, QueryList, AfterViewInit, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel, NgForm, FormGroup } from '@angular/forms';
import { TopForm } from '../../top-form';
import { States } from '../../../models/state-list';

export const STATE_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StateComponent),
    multi: true
};

@Component({
    selector: 'gol-state-select',
    template: `<div class="form-group">
                    <label for="state">{{Title}}</label>
                    <select id="state" name="state" class="form-control" [(ngModel)]="selectedState" required #state="ngModel" (ngModelChange)="update()" [disabled]="disabled">
                        <option *ngFor="let s of states" value="{{s.abbreviation}}">{{s.abbreviation}} - {{s.name}}</option>
                    </select>
                    <div class="error" *ngIf="(form.submitted || state.dirty) && state.invalid">
                        <p *ngIf="state.errors['required']">{{requiredErrorMessage}}</p>
                    </div>
                </div>`,
    providers: [STATE_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class StateComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    @Input() form: TopForm;
    @Input() group: FormGroup;

    @Input()
    Title: string;
    @Input()
    requiredErrorMessage = 'Required';
    @Input()
    disabled = false;
    selectedState: string;

    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    states = States;

    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            useGroup.addControl(this.Title, item.control);
        });
    }

    writeValue(value: any) {
        if (value !== this.selectedState) {
            this.selectedState = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    update() {
        this.onChangeCallback(this.selectedState);
    }


}
