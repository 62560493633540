<h2 class="questionText">
    {{question.assetQuestionText}}
</h2>
<div class="example">
    {{question.assetExample}}
</div>
<div *ngFor="let character of Characters; let i = index;" class="row question-input">
    <div class="col-md-offset-2 col-md-4">
        <label>{{character}}:</label>
    </div>
    <div class="col-md-6">
        <gol-number-input name="value{{i}}" [formControl]="assetCtrls[i]" [(ngModel)]="question.assets[i]" #asset>
        </gol-number-input>
        <div class="error" *ngIf="(form.submitted || assetCtrls[i].dirty) && assetCtrls[i].invalid">
            <p *ngIf="assetCtrls[i].errors['required']">Required</p>
            <p *ngIf="assetCtrls[i].errors['negativeNumber']">Must be a positive number</p>
            <p *ngIf="assetCtrls[i].errors['validateNumber'] && assetCtrls[i].errors['validateNumber'].max === true">
                Amount must be less than {{maxDollarValue | currency:'USD':true:'1.2-2'}}</p>
        </div>
    </div>
</div>
<div class="spacer"></div>

<h2 class="questionText">
    {{question.totalLiabilityQuestionText}}
</h2>
<div class="example">
    {{question.totalLiabilityExample}}
</div>
<div *ngFor="let character of Characters; let i = index;" class="row question-input">
    <div class="col-md-offset-2 col-md-4">
        <label>{{character}}:</label>
    </div>
    <div class="col-md-6">
        <gol-number-input name="value{{i}}" [formControl]="totalLibCtrls[i]" [(ngModel)]="question.totalLiability[i]"
            (ngModelChange)="shortTermLibCtrls[i].updateValueAndValidity();"></gol-number-input>
        <div class="error" *ngIf="(form.submitted || totalLibCtrls[i].dirty) && totalLibCtrls[i].invalid">
            <p *ngIf="totalLibCtrls[i].errors['required']">Required</p>
            <p *ngIf="totalLibCtrls[i].errors['negativeNumber']">Must be a positive number</p>
            <p
                *ngIf="totalLibCtrls[i].errors['validateNumber'] && totalLibCtrls[i].errors['validateNumber'].max === true">
                Amount must be less than {{maxDollarValue | currency:'USD':true:'1.2-2'}}</p>
        </div>
    </div>
</div>
<div class="spacer"></div>

<h2 class="questionText">
    {{question.shortTermLiabilityQuestionText}}
</h2>
<div class="example">
    {{question.shortTermLiabilityExample}}
</div>
<div *ngFor="let character of Characters; let i = index;" class="row question-input">
    <div class="col-md-offset-2 col-md-4">
        <label>{{character}}:</label>
    </div>
    <div class="col-md-6">
        <gol-number-input name="value{{i}}" [formControl]="shortTermLibCtrls[i]"
            [(ngModel)]="question.shortTermLiability[i]"></gol-number-input>
        <div class="error" *ngIf="(form.submitted || shortTermLibCtrls[i].dirty) && shortTermLibCtrls[i].invalid">
            <p *ngIf="shortTermLibCtrls[i].errors['required']">Required</p>
            <p *ngIf="shortTermLibCtrls[i].errors['termGreaterThanTotal']">Cannot be greater than loan total</p>
            <p *ngIf="shortTermLibCtrls[i].errors['negativeNumber']">Must be a positive number</p>
            <p
                *ngIf="shortTermLibCtrls[i].errors['validateNumber'] && shortTermLibCtrls[i].errors['validateNumber'].max === true">
                Amount must be less than {{maxDollarValue | currency:'USD':true:'1.2-2'}}</p>
        </div>
    </div>
</div>