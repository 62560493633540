import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { AccountHttpService } from '../../../services/account-http.service';
import { Payment } from '../../../models/accounts/payment';
import { Commitment } from '../../../models/accounts/commitment';

@Component({
    selector: 'gol-payoff',
    templateUrl: 'payoff.component.html',
    styleUrls: ['payments.component.css']
})
export class PayoffComponent implements OnInit {

    loading: boolean;
    error: boolean;
    loanNumber: string;
    account: Commitment;
    payments: Payment[] = [];
    payoffLength = 10;

    constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountHttpService) { }

    ngOnInit(): void {
        this.loading = true;
        window.scrollTo(0, 0);
        this.payments = [];
        this.accountService.getCurrentAccount(this.route.snapshot.parent)
            .then((result) => {
                this.account = result;
                this.loanNumber = result.LoanNumber;
                if (!this.account.HasPendingPayment && this.account.HasBalance) {
                    this.GetPaymentDetails();
                } else {
                    this.loading = false;
                }
            });
    }

    GetPaymentDetails() {
        this.loading = true;
        this.accountService.getPayoffDetails(this.loanNumber, this.payoffLength)
            .then((result) => {
                this.payments = result;
            })
            .catch(() => {
                this.error = true;
            })
            .then(() => {
                this.loading = false;
            });
    }

    TotalPayoffAmount(): number {
        let totalAmount = 0;
        this.payments.forEach(element => {
            totalAmount += element.PayoffAmount;
        });
        return totalAmount;
    }
}
