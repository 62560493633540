import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from './auth.service';
import { AuthServiceV2 } from '../../appshared/public-api';
import { AppVersionHelper } from '../../version-helper';

@Injectable()
export class AuthenticatedHttp {

    constructor(private http: HttpClient, private auth: AuthService, private authv2: AuthServiceV2) { }

    public post<T>(url: string, body?: any, options?: any): Observable<any> {
        options = options || {};
        options.headers = this.createAuthorizationHeader(options.headers);
        return this.http.post<T>(url, body, options);
    }
    public get<T>(url: string, options?: any): Observable<any> {
        options = options || {};
        options.headers = this.createAuthorizationHeader(options.headers);
        return this.http.get<T>(url, options);
    }
    public delete<T>(url: string, options?: any) {
        options = options || {};
        options.headers = this.createAuthorizationHeader(options.headers);
        return this.http.delete<T>(url, options);
    }

    private createAuthorizationHeader(headers: HttpHeaders) : HttpHeaders {
        if (AppVersionHelper.isVersion2()) {
            headers = this.authv2.createAuthorizationHeader(headers);
        } else {
            headers = this.auth.createAuthorizationHeader(headers);
        }

        return headers;
    }
}
