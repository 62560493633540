import { Injectable } from '@angular/core';
import { CurrentApplicationService } from '../../app/services/current-application.service';
import { Router } from '@angular/router';
import { NavigationOptionsRegistration } from '../models/navigation-options-registration';
import { Registree } from '../../app/models/register';
import { NavigationOptions } from '../../app/models/navigation-options';
import { StorageService } from '../../app/services/storage.service';

//TODO:MFA - Now using sessionStorage so that b2c component in frame can access the data
@Injectable()
export class CurrentRegistrationService {
    private appStorageKey = 'currentRegistration';
    
    constructor(private currentAppService: CurrentApplicationService, 
        public router: Router,
        private storageService: StorageService) {
    }

    setCurrentRegistree(registree: Registree) {
        this.storageService.storeObjectInSession(this.appStorageKey, registree);
    }

    getCurrentRegistree(): Registree {
        let currentRegistree: Registree = this.storageService.getObjectFromSession(this.appStorageKey);
        if (!currentRegistree) {
            currentRegistree = this.makeNewRegistree();
        }
        return currentRegistree;
    }

    makeNewRegistree() : Registree {
        const newRegistree = new Registree();
        this.setCurrentRegistree(newRegistree);
        return newRegistree;
    }

    back() {
        window.history.back();
    }

    cancel() {
        this.makeNewRegistree();
        let path = '/home';
        this.currentAppService.getCurrentApplication('CurrentRegistrationService - cancel').then((app) => {
            if (app.currentPage) {
                path = NavigationOptions.GetStartPage(app);
            }
            this.router.navigate([path]);
        });
    }

    public gotoNextPage() { //TODO:MFA - New
        const allPages = NavigationOptionsRegistration.RegistrationPages();
        const showAccountPage: boolean = this.getCurrentRegistree().isExistingCustomer;
        const availablePages = allPages.filter(p => {
            let take = true;
            if(!showAccountPage && p.alias === 'Account') {
                take = false;
            }
            return take;
        });

        const indexOfCurrentPage = availablePages.findIndex(p => this.router.url.endsWith(p.route));
        const nextPage = availablePages[indexOfCurrentPage + 1];

        this.router.navigate([nextPage.route]);
    }
}
