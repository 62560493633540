<div class="displayBox">
        <p class=MsoNormal><span lang=EN-GB>This GrowerOnline Service Electronic Delivery Disclosure and Consent
                        Agreement (“Agreement”) applies to all Communications for the products, services, and Accounts
                        offered or accessible through the GrowerOnline Service.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Definitions</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>The words “we,” “us,” and “our” refers to Rabo AgriFinance LLC (“RAF”). The
                        words “you” and “your” means you, the individual or entity that is a Borrower on an Eligible
                        Account, or the Authorized Representative of the Borrower. An “Eligible Account” is each line of
                        credit of the Borrowers that is accessible through the Service. Eligible Accounts are only
                        available for the purchase of input-related products and services. All Eligible Accounts must be
                        business accounts and must not be used for personal, family or household purposes.
                        “Communication” means any Borrower agreement or amendment thereto, monthly billing or account
                        statements, tax statements, disclosures, notices, responses to claims, transaction history,
                        privacy policies, and all other information related to the product, service, or Eligible
                        Account(s), including but not limited to information required to be provided by law.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Communications</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>By consenting to this Agreement, you agree that RAF may provide you with
                        Communications in electronic format. Communications include but are not limited to: (a)
                        Paperless Statements related to your Eligible Account(s); (b) tax statements (c) disclosures,
                        notices, and other documents required by law to be provided in connection with the Eligible
                        Account(s), such as legally required notification of changes to terms and conditions related to
                        your Eligible Account(s) or the GrowerOnline Service; (d) disclosures, notices, and other
                        documents about a change in the terms of your Eligible Account(s); (e) disclosures, notices and
                        other documents required by law to be provided in connection with opening, using or maintaining
                        online banking. Your consent to receive Communications in electronic form does not automatically
                        stop the receipt by you of paper account statements and tax documents.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Delivery Method</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>All Communications in electronic form will be provided by one or more of the
                        following methods: via email, via access to the GrowerOnline Service website, or via SMS and/or
                        text messaging.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Withdrawal of Consent for e-Communication</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You may withdraw your consent to receive Communications in electronic form
                        by contacting us at (314) 317-8000 or by changing your delivery preferences on the GrowerOnline
                        Service website.<br /> Note: If you provide an invalid email address or a previously functioning
                        email address subsequently fails to function properly, we may consider this as a withdrawal of
                        your consent to receive Communications in electronic form and your access and use of the
                        GrowerOnline Service may be terminated. </span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB></span><strong>Updating Contact Information</strong></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You are responsible for maintaining a true, accurate, and complete email
                        address, contact number, and other information related to this Disclosure and your Eligible
                        Account(s), and to promptly update any changes to this information. You may update your
                        information on the GrowerOnline Service website or by contacting us at (314) 317-8000.</span>
        </p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Hardware and Software Requirements</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>The Borrowers and Authorized Representatives are responsible for and must
                        provide all internet connections, equipment, software (other than any software provided by us),
                        hardware, and any other services necessary to access the GrowerOnline Website and the
                        Service.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Paper Copies</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You should not expect to receive a paper copy of any Communication unless
                        you request it or we otherwise deem it appropriate to do so. You can obtain a paper copy of a
                        Communication in electronic form by printing it yourself or by requesting that we mail you a
                        paper copy, provided that such request is made within a reasonable time after we first provided
                        the Communication in electronic form to you. To request a paper copy, contact us by telephone at
                        (314) 317-8000, or by logging in to the GrowerOnline Service. We may charge you a reasonable
                        service charge, of which we've provided you prior notice, for the delivery of paper copies of
                        any Communication provided to you electronically pursuant to this authorization. We reserve the
                        right, but assume no obligation, to provide a paper (instead of electronic) copy of any
                        Communication that you have authorized us to provide electronically.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>In Writing</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>All Communications in electronic form or paper format are considered “in
                        writing.”</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Federal Law</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>You acknowledge and agree that your consent to Communication in electronic
                        form is being provided in connection with a transaction affecting interstate commerce that is
                        subject to the federal Electronic Signatures in Global and National Commerce Act (the "Act"),
                        and that you and we both intend that the Act apply to the fullest extent possible to validate
                        our ability to conduct business with you by electronic means.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Changes/Termination</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>We reserve the right, at our sole discretion, to discontinue the use of
                        Communication in electronic form or to terminate or change the terms and conditions of this
                        Agreement. We will provide notice of any such changes or termination as required by law.</span>
        </p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Email Address for Accounts with Multiple or Joint
                                Owners</strong></span> </p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>For purposes of electronic notification for Paperless Statements on a joint
                        or multiple owner account, you agree that electronic notifications for Paperless Statements
                        delivered to the e-mail address of the account owner who enrolls in Paperless Statements will
                        constitute delivery to all account owners, even if all account owners may not have access to
                        such e-mail address.</span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Consent and Acceptance</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>By consenting to this Disclosure, you agree to the following
                        statements:<br /> I have read, understand and agree to be bound by the terms and conditions
                        described above and consent to receive Communications in electronic form according to the
                        process described above. <br /> I understand and agree that: (i) certain documents will continue
                        to be delivered to me via U.S. Mail and that in the future some or all of these documents may be
                        made available for me to view electronically in accordance with this Disclosure; (ii) RAF and/or
                        its agents may revoke my participation in the Paperless Statements program at any time at their
                        discretion; (iii) neither RAF, nor their agents will be liable for any loss, liability, cost,
                        expense, or claim for acting upon this authorization or arising from my use of the product or
                        services provided pursuant to this Disclosure; and (iv) inserts that may be provided along with
                        my account statements contain important information or disclosures concerning my Account and I
                        agree to review such inserts in a timely manner. </span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB><strong>Affirmative Consent</strong></span></p>
        <p class=MsoNormal><span lang=EN-GB>&nbsp;</span></p>
        <p class=MsoNormal><span lang=EN-GB>By clicking “I Agree” below, you consent to terms of this Agreement.</span>
        </p>
</div>