export const SecurityQuestions = [
    'What is your favorite brand of seed or crop protection product?',
    'What is your favorite cut of beef, pork or chicken?',
    'What is your favorite fruit or vegetable?',
    'What is your favorite website or ag news source?',
    'How many acres was your first farm?',
    'What is the make or model of your favorite tractor?',
    'What is the name of your favorite show animal?',
    'What is your favorite food?',
    'Where was the last Ag expo or convention which you attended?',
    'What is your favorite country song?',
    'Who is your favorite country singer?',
    'What country would you like to visit?',
    'What is the lowest price you remember for a bushel of corn or beans?',
    'What was the name of your childhood pet?',
    'What was the make/model/year of your first farm truck?'
];
