const migrateV2 = true;
const v2path = migrateV2 ? '' : '/v2';

export const CONFIG = {
    websiteName: 'Grower',
    apiBaseUri: 'https://api.raboag.com/',
    runAsProd: true,
    recaptchaSiteKey: '6LevSwkUAAAAAD3ZkPBaHxXp-wmLUy1p4Bcyd0XI',
    env: 'prod',
    sessionTimeoutPostLogoutRedirectUri: v2path + '/home?m=session-expired',
    b2cConfig: {
        clientId: '59df416c-c1d8-4810-acca-f329401a14c8',
        tenantId: 'ifssoprd',
        redirectUri: v2path + '/auth',
        postLogoutRedirectUri: v2path + '/home'
    },
    b2cSigninFrame: {
        src: v2path + '/login',
        height: '435px',
        policy: 'B2C_1A_Signup_Signin'
    },
    b2cUpdatePasswordFrame: {
        src: v2path + '/reset-password-login',
        height: '520px',
        policy: 'B2C_1A_PasswordUpdate',
        redirectUri: v2path + '/forgot-password-confirmation',
    },
    b2cPasswordResetFrame: {
        src: v2path + '/forgot-password-login',
        height: '500px',
        policy: 'B2C_1A_PasswordReset',
        redirectUri: v2path + '/forgot-password-confirmation'
    },
    b2cEmailChangeFrame: {
        src: v2path + '/dashboard/preferences/email-frame',
        height: '400px',
        policy: 'B2C_1A_SignInNameChange',
        redirectUri: v2path + '/dashboard/preferences/change-email-confirmation'
    },
    b2cPasswordChangeFrame: {
        src: v2path + '/dashboard/preferences/password-frame',
        height: '600px',
        policy: 'B2C_1A_PasswordChange',
        redirectUri: v2path + '/dashboard/preferences/change-password-confirmation'
    },
    enableAppV2: true,
    migrateAppV2: migrateV2,
    b2cFrameDelay: 2000 // Show a loading indicator for X ms to let the custom policy load behind the scenes
};
