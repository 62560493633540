import { Injectable } from '@angular/core';

import { VendorHeader } from '../models/vendor-header';
import { AuthenticatedHttp } from './authenticated-http.service';
import { CONFIG } from '../../environments/environment';
import { LoggingService } from './logging.service';

@Injectable()
export class VendorService {
    public vendors: VendorHeader[] = [];
    public filteredVendors: VendorHeader[] = [];
    public vendorRetailers: VendorHeader[] = [];
    public sponsoringVendor: VendorHeader;
    private apiUrl = CONFIG.apiBaseUri + 'program/';

    constructor(private http: AuthenticatedHttp, private logService: LoggingService) { }

    public filterSelectedVendors(selectedVendorIds: number[]) {
        if (this.vendors) {
            this.filteredVendors = this.vendors.filter(v => !selectedVendorIds.includes(v.rowId));
        }
    }

    public GetVendors(uniqueLogId:string = 'unknown'): Promise<boolean> {
        this.logService.recordInfo('VendorService.GetVendors start', null, null, uniqueLogId);
        if (this.vendors.length > 0) {
            return Promise.resolve(true);
        }
        return this.http.get(this.apiUrl + 'USVendors')
            .toPromise()
            .then(response => {
                this.vendors = Array.from(response as any[], (value) => {
                    return new VendorHeader(value);
                });
                this.filteredVendors = Array.from(response as any[], (value) => {
                    return new VendorHeader(value);
                });
                this.logService.recordInfo(`VendorService.GetVendors completed, returned ${response.length} records`, null, null, uniqueLogId);
                return true;
            })
            .catch(err => {
                this.logService.recordError('VendorService.GetVendors error.', err.message || err, null, uniqueLogId);
               return this.handleError(err);
            });
    }

    public GetRetailersForVendor(vendorId: number, nearZipCode: string, uniqueLogId:string = 'unknown'): Promise<boolean> {
        this.logService.recordInfo(`VendorService.GetRetailersForVendor start, vendorId: ${vendorId}`, null, null, uniqueLogId);

        return this.http.get(this.apiUrl + 'VendorRetailers?vendorId=' + vendorId + '&nearZipCode=' + nearZipCode)
            .toPromise()
            .then(response => {
                this.vendorRetailers = Array.from(response as any[], (value) => {
                    return new VendorHeader(value);
                });
                this.logService.recordInfo(`VendorService.GetRetailersForVendor completed, vendorId: ${vendorId}, returned ${response.length} records`, null, null, uniqueLogId);
                return true;
            })
            .catch(err => {
                this.logService.recordError(`VendorService.GetRetailersForVendor error, vendorId: ${vendorId}.`, err.message || err, null, uniqueLogId);
               return this.handleError(err);
            });
    }

    public GetSponsoringDealerForPreApproval(preApprovalStagingId: number, uniqueLogId:string = 'unknown'): Promise<VendorHeader> {
        this.logService.recordInfo(`VendorService.GetSponsoringDealerForPreApproval start, preApprovalStagingId: ${preApprovalStagingId}`, null, null, uniqueLogId);

        return this.http.get(this.apiUrl + 'GetSponsoringDealer?preApprovalStagingId=' + preApprovalStagingId)
            .toPromise()
            .then(response => {
                if (response) {
                    this.sponsoringVendor = new VendorHeader(response);
                } else {
                    this.sponsoringVendor = new VendorHeader();
                }
                this.logService.recordInfo(`VendorService.GetSponsoringDealerForPreApproval completed, preApprovalStagingId: ${preApprovalStagingId}, returned ${this.sponsoringVendor?.getShortName}`, null, null, uniqueLogId);
                return this.sponsoringVendor;
            })
            .catch(err => {
                this.logService.recordError(`VendorService.GetSponsoringDealerForPreApproval error, preApprovalStagingId: ${preApprovalStagingId}.`, err.message || err, null, uniqueLogId);
               return this.handleError(err);
            });
    }

    private handleError(error: any) {
        console.error('VendorService: An error occurred', error);
        this.logService.recordError(`VendorService.handleError completed`, error.message || error, null, 'unknown');
        return Promise.reject(error.message || error);
    }
}

