import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { TopForm } from '../../../form/top-form';
import { Commitment } from '../../../models/accounts/commitment';
import { AccountHttpService } from '../../../services/account-http.service';
import { UserService } from '../../../services/user.service';
import { Payment } from '../../../models/accounts/payment';
import numberMask from 'text-mask-addons/dist/createNumberMask';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'gol-payments',
    templateUrl: 'payments.component.html',
    styleUrls: ['payments.component.css']
})
export class PaymentsComponent implements OnInit {

    loading: boolean;
    error: boolean;
    errorMessage = '';
    submitError: boolean;
    submitting: boolean;
    loanNumber: string;
    account: Commitment;
    payments: Payment[] = [];
    pendingPayments: Payment[] = [];
    paymentTypes: MenuItem[];
    defaultType: MenuItem;
    bankAccountNumber: string;
    bankRoutingNumber: string;
    achAccountType: string;
    bankAccountHolder: string;
    form: TopForm = new TopForm();
    BankAccountNumber: FormControl
        = new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(17)]));
    BankRoutingNumber: FormControl
        = new FormControl('', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)]));
    ACHAccountType: FormControl = new FormControl('', [Validators.required]);
    BankAccountHolder: FormControl = new FormControl('', [Validators.required]);
    alias: string;
    confirmationNumbers: string[] = [];
    ACHProcessingCutoff: string;

    currencyMask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });

    constructor(private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountHttpService,
        private userService: UserService) { }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.payments = [];
        this.loading = true;
        this.accountService.getCurrentAccount(this.route.snapshot.parent)
            .then((result) => {
                if (result) {
                    this.account = result;
                    this.loanNumber = result.LoanNumber;
                    if (!this.account.HasPendingPayment && this.account.HasBalance) {
                        this.GetPaymentDetails();
                        this.SetMenu();
                    } else {
                        this.loading = false;
                    }
                } else{
                    this.error = true;
                    this.errorMessage = 'Unable to retrieve account.';
                    this.loading = false;
                }
                
            }).catch((reject) => {
                this.error = true;
                this.loading = false;
            });
    }

    SetMenu() {
        this.alias = 'Details';
        this.form.addControl('BankAccountNumber', this.BankAccountNumber);
        this.form.addControl('BankRoutingNumber', this.BankRoutingNumber);
        this.form.addControl('ACHAccountType', this.ACHAccountType);
        this.form.addControl('BankAccountHolder', this.BankAccountHolder);
        this.paymentTypes = [
            {
                label: 'Full Payment', command: (event) => {
                    this.payments[0].AccountPayment = true;
                }
            },
            {
                label: 'Partial Payment', command: (event) => {
                    this.payments[0].AccountPayment = false;
                }
            }
        ];

        this.defaultType = this.paymentTypes[0];
        this.GetACHProcessingCutoffHour();
    }

    GetACHProcessingCutoffHour() {
        this.accountService.getACHProcessingCutoffHour()
            .then((result) => {
                this.ACHProcessingCutoff = result;
            });
    }

    GetPaymentDetails() {
        this.loading = true;
        this.accountService.getPayoffDetails(this.loanNumber, 0)
            .then((result) => {
                if (result.length > 0) {
                    this.payments = result;
                    if (this.payments.length > 1) {
                        this.payments[0].AccountPayment = true;
                        this.payments[0].AccountPaymentAmount = this.TotalPayoffAmount();
                    } else {
                        this.payments[0].AccountPayment = false;
                    }
                }
                this.loading = false;
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
            });
          
    }

    changePaymentAmount(value: string): number {
        const convertedValue = this.toNumber(value);
        return convertedValue;
    }

    toNumber(value: string): number {
        if (value == null || value === undefined) { return 0.00; }
        const convert = +(this.clearmask(value));
        if (Number.isNaN(convert)) {
            return null;
        }
        return +convert.toFixed(2);
    }

    clearmask(str: string): string {
        if (!str || typeof str !== 'string') {
            return str;
        }
        const newstr = str.replace(/\//g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/,/g, '').replace('$', '');
        return newstr;
    }

    TotalPayoffAmount(): number {
        let totalAmount = 0;
        this.payments.forEach(element => {
            totalAmount += element.PayoffAmount;
        });
        return totalAmount;
    }

    TotalPaymentAmount(): number {
        if (this.payments[0].AccountPayment) {
            return this.payments[0].AccountPaymentAmount;
        } else {
            let totalAmount = 0;
            this.payments.forEach(element => {
                totalAmount += element.PaymentAmount;
            });
            return totalAmount;
        }
    }

    VerifyPaymentDetails() {
        this.errorMessage = '';
        this.form.submitted = true;
        if (!this.form.invalid) {
            if (this.TotalPaymentAmount() <= 0) {
                this.errorMessage = 'Total Payment Amount must be greater than zero.  ';
            }
            if (!this.ABACheckSum()) {
                this.errorMessage += 'Invalid Bank Routing Number.  ';
            }
            if (!this.VerifyPaymentAmount()) {
                this.errorMessage += 'Payment amount must be less than or equal to payoff amount.  ';
            }
            if (!this.errorMessage) {
                this.pendingPayments = [];
                this.payments.forEach(payment => {
                    payment.BankAccountNumber = this.bankAccountNumber;
                    payment.BankRoutingNumber = this.bankRoutingNumber;
                    payment.ACHAccountType = this.achAccountType;
                    payment.BankAccountHolder = this.bankAccountHolder;
                    if (this.payments[0].AccountPayment) {
                        this.pendingPayments.push(payment);
                    } else {
                        if (payment.PaymentAmount > 0) {
                            this.pendingPayments.push(payment);
                        }
                    }
                });

                this.alias = 'Review';
                this.error = false;
                this.form.submitted = false;
                window.scrollTo(0, 0);
            }
        }
    }

    VerifyPaymentAmount(): boolean {
        if (this.payments[0].AccountPayment) {
            return this.payments[0].AccountPaymentAmount <= this.TotalPayoffAmount();
        } else {
            let pass = true;
            this.payments.forEach(payment => {
                if (payment.PaymentAmount > payment.PayoffAmount) {
                    pass = false;
                }
            });
            return pass;
        }
    }

    ABACheckSum(): boolean {
        let aba = this.bankRoutingNumber;

        if (!aba) {
            return false;
        }

        // tslint:disable-next-line: prefer-const
        let numericRegex = /^\d{9}$/,
            total = null;

        aba = aba.toString();

        // make sure it's numeric and of length 9
        if (!numericRegex.test(aba)) {
            return false;
        }

        // compute checksum
        for (let i = 0; i < 9; i += 3) {
            total += parseInt(aba.charAt(i), 10) * 3
                + parseInt(aba.charAt(i + 1), 10) * 7
                + parseInt(aba.charAt(i + 2), 10);
        }
        if (total !== 0 && total % 10 === 0) {
            return true;
        }

        return false;
    }

    Edit() {
        this.alias = 'Details';
    }

    Submit() {
        this.submitting = true;
        this.errorMessage = '';
        const paymentError = 'An error may have occurred. Please verify your last payment is in pending status before attempting to submit another payment. If you have any questions please call us at 888-395-8505. ';
        this.accountService.submitPayments(this.pendingPayments)
            .then((results) => {
                results.forEach(result => {
                    if (result.length > 1) {
                        this.confirmationNumbers.push(result);
                    } else {
                        this.errorMessage = paymentError;
                    }
                });

                if (this.confirmationNumbers.length <= 0) {
                    this.errorMessage = paymentError;
                }

                if (this.errorMessage === '') {
                    this.alias = 'Confirmation';
                    window.scrollTo(0, 0);
                } else {
                    this.submitError = true;
                }
            })
            .catch((res) => {
                this.errorMessage = paymentError;
                this.submitError = true;
            })
            .then(() => {
                this.submitting = false;
                this.accountService.updateAccountObservable();
            });
    }

    ConfirmationText(): string {
        const user = this.userService.GetUser();
        let text = 'We will send an email confimation to ' + user.email + '.  ';
        if (this.confirmationNumbers.length > 1) {
            text += 'Your confirmation numbers for this transaction are:';
        } else {
            text += 'Your confirmation number for this transaction is:';
        }
        return text;
    }

    PrintConfirmation() {
        window.print();
    }

    Cancel() {
        this.payments = [];
        this.pendingPayments = [];
        this.submitError = false;
        this.router.navigate(['/dashboard']);
    }
}
