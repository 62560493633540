<div>
    <div>
        <gol-address-component name="mailingAddress" [(address)]="mailingAddress" Title="Mailing Address" [form]="form"
            [group]="group" [updateFn]="streetUpdated()" [showHomeAddressPrefix]=showHomeAddressPrefix
            [allowPOBox]=true></gol-address-component>
    </div>
    <div *ngIf="isPhysicalAddressRequired()">
        <gol-address-component name="physicalAddress" [(address)]='physicalAddress' Title="Physical Address"
            [form]="form" [group]="physicalGroup" [showHomeAddressPrefix]=showHomeAddressPrefix></gol-address-component>
    </div>
</div>