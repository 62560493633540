export const LineOfBusinesses = [
    'Beef Cattle',
    'Corn',
    'Cotton',
    'Dairy',
    'Hay',
    'Rice',
    'Soybean',
    'Swine',
    'Wheat'
];
