import { Component, Input, AfterViewInit } from '@angular/core';
import { Address } from '../../../models/address';
import { AddressComponent } from './address.component';
import { NgForm, NgModel, FormGroup } from '@angular/forms';
import { TopForm } from '../../top-form';

@Component({
    selector: 'gol-combined-address-component',
    templateUrl: 'combined-address.component.html',
})
export class CombinedAddressComponent implements AfterViewInit {
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() name: string;
    physicalGroup: FormGroup = new FormGroup({});
    @Input()
    physicalAddress: Address;

    @Input()
    mailingAddress: Address;

    @Input()
    showHomeAddressPrefix = false;

    ngAfterViewInit() {
        this.streetUpdated()();
    }

    streetUpdated() {
        const combAddress = this;
        return function () {
            const useGroup = combAddress.group || combAddress.form;
            if (combAddress.isPhysicalAddressRequired()) {
                useGroup.addControl(combAddress.name, combAddress.physicalGroup);
            } else {
                useGroup.removeControl(combAddress.name);
            }
        };
    }

    isPhysicalAddressRequired() {
        return !this.mailingAddress.isPhysicalAddress();
    }
}
