import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../app/models/user';
import { CurrentRegistrationService as CurrentRegistrationServiceV1} from '../../../app/services/current-registration.service';
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService as AuthServiceV1} from '../../../app/services/auth.service';
import { AuthService as AuthServiceV2} from '../../services/auth.service';
import { GrowerOnline } from '../../../app/models/constants';
import { RegistrationAgreementComponent as RegistrationAgreementComponentV1 } from '../../../app/registration/agreement/registration-agreement.component'

@Component({
    selector: 'gol-registration-agreement',
    templateUrl: '../../../app/registration/agreement/registration-agreement.component.html',
    styleUrls: ['../../../app/registration/css/styles_form.css'],
})

export class RegistrationAgreementComponent extends RegistrationAgreementComponentV1 {
    constructor(public routerV1: Router, 
        private authServiceV1: AuthServiceV1,
        private authServiceV2: AuthServiceV2,
        public registrationServiceV1: CurrentRegistrationServiceV1,
        public registrationServiceV2: CurrentRegistrationService) {
        super(routerV1, authServiceV1, registrationServiceV1)
    }

    continue() {
        this.form.submitted = true;
        this.serverError = '';
        if (this.form.valid && this.recaptchaToken !== '') {
            this.loading = true;
            this.registrationServiceV2.setCurrentRegistree(this.registree);
            const user = new User();
            user.isAnonymous = false;
            user.email = this.registree.email;
            user.firstName = this.registree.firstName;
            user.lastName = this.registree.lastName;
            user.password = this.registree.password;
            user.confirmPassword = this.registree.confirmPassword;
            user.securityQuestions = this.registree.securityQuestions;
            user.cifno = this.registree.cifNo;
            user.registeredSystem = GrowerOnline;

            this.authServiceV2.Register(
                user, this.recaptchaToken,
                this.agreementAcceptanceDate,
                this.eStatementAcceptanceDate,
                this.achUSBankStatementAcceptanceDate,
                this.registree.accountNumber,
                this.registree.ssn,
                this.registree.zip)
                .then(userToken => {
                    //TODO:MFA - No longer clear registree here
                    this.registree.authToken = userToken;
                    this.registrationServiceV2.setCurrentRegistree(this.registree);
                    this.registrationServiceV2.gotoNextPage();

                })
                .catch((rejected) => {
                    this.serverError = rejected;
                    if (this.recaptcha) {
                        this.recaptcha.reset();
                        this.recaptchaToken = '';
                    }
                })
                .then(() => this.loading = false);
        }
    }
}
