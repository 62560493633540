import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TopForm } from '../../../app/form/top-form'; // TODO:MFA
import { UserService } from '../../../app/services/user.service'; // TODO:MFA
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService } from '../../services/auth.service';
import { ValidatorFn } from '@angular/forms';

@Component({
    selector: 'gol-linkaccount',
    templateUrl: '../../../app/registration/profile/linkaccount.component.html', // TODO:MFA
    styleUrls: ['../../../app/registration/css/styles_form.css'] // TODO:MFA
})
export class LinkAccountComponent implements OnInit {
    @Input() newUser: boolean;
    @Output() cifNo: EventEmitter<Object>;
    @Output() back: EventEmitter<boolean>;
    @Output() cancel: EventEmitter<boolean>;
    @Output() skip: EventEmitter<boolean>;

    form: TopForm = new TopForm();

    AccountNumber: string;
    Last4ssn: string;
    ZipCode: string;

    accountNumber: FormControl
        = new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(7)]));
    last4ssn: FormControl
        = new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)]));
    zip: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(10)]));
    loading: boolean;
    serverError = '';
    mask = zipMask;

    showTandC = false;
    showEStatement = false;
    showAchUSBankStatement = false;

    attestation: FormControl = new FormControl('', this.checkboxValidator('attestation'));
    eStatement: FormControl = new FormControl('', this.checkboxValidator('eStatement'));
    achUSBankStatement: FormControl = new FormControl('', this.checkboxValidator('achUSBankStatement'));

    attestationVisible = false;
    eStatementVisible = false;
    achUSBankStatementVisible = false;

    agreementAcceptanceDate: Date;
    eStatementAcceptanceDate: Date;
    achUSBankStatementAcceptanceDate: Date;

    openLightbox = false;

    constructor(public router: Router,
        private authService: AuthService,
        public registrationService: CurrentRegistrationService,
        private userService: UserService) {
        this.cifNo = new EventEmitter<Object>();
        this.back = new EventEmitter<boolean>();
        this.cancel = new EventEmitter<boolean>();
        this.skip = new EventEmitter<boolean>();

        this.form.addControl('accountNumber', this.accountNumber);
        this.form.addControl('last4ssn', this.last4ssn);
        this.form.addControl('zip', this.zip);
        this.form.addControl('attestation', this.attestation);
        this.form.addControl('eStatement', this.eStatement);
        this.form.addControl('achUSBankStatement', this.achUSBankStatement);
    }

    ngOnInit(): void {
        this.getUserAcceptanceDates();
    }

    SubmitText(): string {
        if (this.newUser) {
            return 'Continue';
        } else {
            return 'Link To Account';
        }
    }

    Back() {
        this.back.emit(true);
    }

    Cancel() {
        this.cancel.emit(true);
    }

    Skip() {
        this.skip.emit(true);
    }

    continue() {
        this.form.submitted = true;
        if (this.form.valid) {
            this.serverError = '';
            this.loading = true;
            this.authService.VerifyAccountDetails(this.AccountNumber, this.Last4ssn, this.ZipCode)
                .then((result) => {
                    if (result > 0) {
                        this.cifNo.emit({ cifno: result, accountNumber: this.AccountNumber, ssn: this.Last4ssn, zip: this.ZipCode });
                        this.updateUserAcceptanceDates();
                    } else if (result === -1) {
                        this.serverError = 'There is already a user associated with this account.';
                    } else if (result === -2) {
                        this.serverError = 'There is an issue with this account.  Please contact customer service for more details.';
                    } else {
                        this.serverError = 'We were unable to match the details provided with an account.';
                    }
                })
                .catch((error) => {
                    this.serverError = error;
                })
                .then(() => this.loading = false);
        }
    }

    updateUserAcceptanceDates() {
        if (!!this.userService.GetUser()) {
            this.authService.UpdateUserAcceptanceDates(
                this.agreementAcceptanceDate,
                this.eStatementAcceptanceDate,
                this.achUSBankStatementAcceptanceDate)
                .then((result) => {
                })
                .catch((error) => {
                    this.serverError = error;
                });
        }
    }

    getUserAcceptanceDates() {
        if (!!this.userService.GetUser()) {
            this.authService.GetUserAcceptanceDates()
                .then((result) => {
                    if (result != null) {
                        this.attestationVisible = result.OnlineAgreementAcceptanceDate == null;
                        this.eStatementVisible = result.EStatementAcceptanceDate == null;
                        this.achUSBankStatementVisible = result.AchUSBankStatementAcceptanceDate == null;
                    }
                })
                .catch((error) => {
                    this.serverError = error;
                });
        }
    }

    agreementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.agreementAcceptanceDate = acceptedDateTime;
    }

    eStatementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.eStatementAcceptanceDate = acceptedDateTime;
    }

    achUSBankStatementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.achUSBankStatementAcceptanceDate = acceptedDateTime;
    }

    checkboxValidator(controlName: string): ValidatorFn {
        const self = this;

        function validate(c: FormControl) {

            if (self.checkboxIsVisible(controlName)) {
                if (!c.value) {
                    return { required: true };
                } else {
                    return null;
                }
            }

            return null;
        }

        return validate;
    }

    checkboxIsVisible(controlName: string): boolean {
        if (controlName === 'attestation') {
            return this.attestationVisible;
        }
        if (controlName === 'eStatement') {
            return this.eStatementVisible;
        }
        if (controlName === 'achUSBankStatement') {
            return this.achUSBankStatementVisible;
        }

        return false;
    }
}

export function zipMask(inputValue) {
    let array: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/];
    if (inputValue.length > 5) {
        array = array.concat(['-', /\d/, /\d/, /\d/, /\d/]);
    }
    return array;
}
