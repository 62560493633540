import { Component, OnInit, AfterViewInit, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IncreasedRiskCountriesComponent } from '../increased-risk-countries/increased-risk-countries.component';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { CurrentApplicationService } from '../../services/current-application.service';
import { LineOfBusinesses } from '../../models/line-of-businesses';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-line-of-business-information',
    templateUrl: 'line-of-business-information.component.html',
    styleUrls: ['../styles_form.css', 'line-of-business-information.component.css']
})
export class LineOfBusinessInformationComponent extends AbstractFormPage implements OnInit, AfterViewInit, OnChanges {
    businesses = LineOfBusinesses;
    form: TopForm;
    @ViewChild(IncreasedRiskCountriesComponent) increasedRiskCountriesComponent: IncreasedRiskCountriesComponent;

    ngOnChanges(LOB) {
        this.application.lineOfBusiness = LOB;
    }

    ngAfterViewInit() {
    }

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService, notificationService: NotificationService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'line-of-business-information';
        this.initPage();
    }

    ngOnInit() {
        this.form = new TopForm();
    }

    submit() {
        this.form.submitted = true;

        if (this.application.lineOfBusiness && this.increasedRiskCountriesComponent.isValid) {
            this.next();
        }
    }

    previous() {
        if (this.isValidForm()) {
            this.back();
        }
    }

    isValidForm() {
        const comp = this;
        comp.form.submitted = true;

        if (comp.application.lineOfBusiness && comp.increasedRiskCountriesComponent.isValid) {
            return true;
        }
        return false;
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string) {
        return new Promise<boolean>((resolve, reject) => {
            const comp = this;
            if (comp.isValidForm()) {
                comp.application.currentPage = newPageAlias;
                resolve(comp.save());
            } else {
                reject(false);
            }
        });
    }
}
