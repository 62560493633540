import { Component } from '@angular/core';

@Component({
    selector: 'gol-electronic-statement',
    templateUrl: 'electronic-statement.component.html',
    styles: [`
        .displayBox {
            background:#e7e7e7;
            margin: 15px;
            padding:15px;
            overflow: auto;
            height:400px;
        }
    `]
})
export class ElectronicStatementComponent {

}
