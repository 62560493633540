<div class="container stage main_container">
    <gol-section-header [nav]="true">Contact Us</gol-section-header>
    <div class="row">
        <div *ngIf="!userLoggedIn()" class="col-md-4"></div>
        <div class="col-md-4">
            <ul class="contact-items raised-card">
                <li class="group">
                    <div class="contact-icon mailIcon"><i class="fa fa-envelope-o"></i>Mail</div>
                    <div class="contact-text">
                        <p>Rabo AgriFinance</p>
                        <p>1402 Technology Parkway</p>
                        <p>Cedar Falls, IA 50613</p>
                    </div>
                </li>
                <li class="group">
                    <div class="contact-icon"><i class="fa fa-phone"></i>Phone</div>
                    <div class="contact-text">(888) 395-8505</div>
                </li>
                <li class="group">
                    <div class="contact-icon"><i class="fa fa-envelope"></i>Email</div>
                    <div class="contact-text"><a href="mailto:QuickLink@RaboAg.com">QuickLink@RaboAg.com</a></div>
                </li>
                <li class="group">
                    <div class="contact-icon"><i class="fa fa-fax"></i>Fax</div>
                    <div class="contact-text">(866) 349-3139</div>
                </li>
            </ul>
        </div>
        <div class="col-md-8">
            <div *ngIf="userLoggedIn()" class="raised-card">
                <h2>Contact Request</h2>
                <gol-loading-feedback *ngIf="loading || submitting"></gol-loading-feedback>
                <form *ngIf="!submitted && !loading && !submitting" [formGroup]="form" (ngSubmit)="Submit()" novalidate>
                    <p>
                        <label>Hours of Operation:</label><br />
                        Mon - Fri 8:00 am - 5:00 pm CST
                    </p>
                    <p>
                        If you are within these hours of operation, you may request a Customer Service Representative to
                        call you to resolve any issues you're experiencing.
                        Please provide us with some information so that we can be prepared to assist you.
                    </p>
                    <div>
                        <label>Name:</label>
                        <input type="text" class="form-control" name="name" [formControl]="name"
                            [(ngModel)]="contactRequest.name">
                        <div class="error" *ngIf="(form.submitted || name.dirty) && name.invalid">
                            <p *ngIf="name.errors['required']">Required</p>
                        </div>
                        <label>Phone Number:</label>
                        <p-autoComplete name="phone" [formControl]="phone" [(ngModel)]="contactRequest.phoneNumber"
                            [suggestions]="filteredPhoneNumbers" (completeMethod)="filterPhoneNumbers($event)"
                            maxlength="14"></p-autoComplete>
                        <div class="error" *ngIf="(form.submitted || phone.dirty) && phone.invalid">
                            <p *ngIf="phone.errors['required']">Required</p>
                            <p *ngIf="phone.errors['minlength'] && form.submitted">Please enter full phone number</p>
                        </div>
                        <label>Message:</label>
                        <textarea pInputTextarea class="form-control message-box" name="message" [formControl]="message"
                            [(ngModel)]="contactRequest.message"></textarea>
                        <div class="error" *ngIf="(form.submitted || message.dirty) && message.invalid">
                            <p *ngIf="message.errors['required']">Required</p>
                        </div>
                    </div>
                    <div *ngIf="submissionErrors" class="error">
                        {{submissionErrors}}
                    </div>
                    <button type="submit" class="btn btn-primary bottom raised" [disabled]="submitting">Submit</button>
                    <button type="button" class="btn btn-secondary bottom raised" (click)="CancelForm()"
                        [disabled]="submitting">Cancel</button>
                    <div class="clear"></div>
                </form>
                <div *ngIf="submitted">
                    Your request has been submitted. A Customer Service Representative will be in touch with you
                    shortly.
                </div>
            </div>
        </div>
    </div>
</div>