import { Component, Input } from '@angular/core';

@Component({
    selector: 'gol-credit-requirements',
    template: `
        <div *ngIf="creditLimit > 200000 && creditLimit <= 400000" class="creditLimitRequirements">
            <p>For requests between $200K and $400K, you will need a copy of your schedule F form.</p>
        </div>
        <div *ngIf="creditLimit > 400000" class="creditLimitRequirements">
            <p>For requests over $400K, you will need your 2 most recent years tax returns and balance sheets.</p>
            <p *ngIf="entity">As an entity, you'll need copies of formation documentation such as Articles of Incorporation, Partnership Agreement, Articles of Organization, etc.</p>
        </div>
        <div *ngIf="showInstructions && creditLimit > 200000" class="instructions">
            <p>Please send us your documents via:</p>
            <ul>
                <li><strong>Email:</strong> <a href="mailto:QuickLink@RaboAg.com">QuickLink@RaboAg.com</a></li>
                <li>or <strong>Fax:</strong> (866) 349-3139</li>
            </ul>
        </div>
    `,
    styles: [`
        .creditLimitRequirements {
            margin-top: 10px;
            padding: 10px;
            text-align: left;
            border: 1px solid #ffb584;
            border-radius: 4px;
            background: #ffe2c4;
        }
        .creditLimitRequirements p:last-child {
            margin-bottom: 0;
        }
        .instructions {
            margin-top: 15px;
            text-align: left;
            padding-left: 10px;
        }
        .instructions ul {
            list-style: none;
        }
    `]
})
export class CreditRequirements {
    @Input()
    creditLimit = 0;
    @Input()
    entity = false;
    @Input()
    showInstructions = false;
}
