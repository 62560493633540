export abstract class ErrorHandling {         

    public static extractMessage(error: any): string {
        const errorErrorMessage: string = error && error.error ? error.error.Message : null;
        let errorMessage: string = error && error.message ? error.message : null;
        if (errorMessage == null && typeof error === 'string'){
            errorMessage = error;
        }
        let parsed: any = {};
        try {
            parsed = JSON.parse(error._body);
        } catch (err) {
            if (error.status === 401) {
                errorMessage = 'Authorization failed';
            }
        }
        const msg = errorErrorMessage || errorMessage || parsed.Message || parsed.message || 'Server error.  Please try again later.';
        return msg;
    }
}