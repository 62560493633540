import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SimpleExpiry, IdleExpiry, Idle } from '@ng-idle/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthorizationGuard } from '../app/services/authorization-guard';
import { CustomErrorHandler } from '../app/services/custom-error-handler';
import { Routing } from './app.routes'; // TODO:MFA
// import { AutoSelectDirective } from '../app/form/validation/autoselect.directive';
// import { CheckboxValidatorDirective } from '../app/form/validation/checkbox-required.directive';
// import { ComparisonValidatorDirective } from '../app/form/validation/comparison-validator.directive';
// import { EmailValidatorDirective } from '../app/form/validation/email-validator.directive';
// import { DateRangeValidatorDirective } from '../app/form/validation/date-range-validator.directive';
// import { NumberValidatorDirective } from '../app/form/validation/number-validator.directive';
// import { PasswordValidatorDirective } from '../app/form/validation/password-validator.directive';
// import { NoDuplicatesValidatorDirective } from '../app/form/validation/no-duplicates.directive';
import { AppComponent } from './app.component'; // TODO:MFA
// import { AddressComponent } from '../app/form/reusables/address/address.component';
// import { AutocompleteComponent } from '../app/form/reusables/autocomplete/autocomplete.component';
// import { ChangeApplicationTypeComponent } from '../app/form/reusables/change-application-type.component';
// import { CombinedAddressComponent } from '../app/form/reusables/address/combined-address.component';
// import { ComplexQuestionComponent } from '../app/form/reusables/wizard/complex-question.component';
// import { ContactComponent } from '../app/form/reusables/contact-information.component';
// import { ContactPageComponent } from '../app/contact/contact-page.component';
// import { CorporationComponent } from '../app/form/reusables/autocomplete/corporation.component';
// import { CreditRequirements } from '../app/form/reusables/credit-requirements.component';
// import { DateBoxComponent } from '../app/form/reusables/date/date-box.component';
import { HeaderComponent } from './form/reusables/view/header.component'; // TODO:MFA
// import { FullNameComponent } from '../app/form/reusables/fullname.component';
import { B2cContentHostComponent } from './shared/b2c-content-host/b2c-content-host.component'; // TODO:MFA
import { LoginComponent } from './form/reusables/account/login.component'; // TODO:MFA
import { B2CLoginComponent } from './form/reusables/account/b2c-login.component';
// import { PasswordExpiredComponent } from '../app/form/reusables/account/password-expired.component';
// import { PhoneComponent } from '../app/form/reusables/phone/phone.component';
// import { ReCaptchaComponent } from '../app/form/reusables/recaptcha.component';
import { ResetPasswordComponent } from './form/reusables/account/reset-password.component'; // TODO:MFA
// import { SecurityQuestionComponent } from '../app/form/reusables/account/security-question.component';
// import { SideNavComponent } from '../app/form/reusables/view/side-nav.component';
// import { SimpleQuestionComponent } from '../app/form/reusables/wizard/simple-question.component';
// import { StateComponent } from '../app/form/reusables/address/states.component';
// import { ZipComponent } from '../app/form/reusables/address/zip.component';
// import { ForceDecimalsDirective } from '../app/form/validation/force-decimals.directive';
// import { AttachmentsComponent } from '../app/form/reusables/attachments/attachments.component';
// import { AutofocusDirective } from '../app/form/reusables/auto-focus/auto-focus.component';
// import { RegistrationComponent } from '../app/registration/registration.component'; // TODO:MFA
import { RegistrationComponent } from './registration/registration.component';

// import { RegistrationProfileComponent } from '../app/registration/profile/registration-profile.component'; //T ODO:MFA
import { RegistrationProfileComponent } from './registration/profile/registration-profile.component'; // TODO:MFA

// import { RegistrationLinkAccountComponent } from '../app/registration/profile/registration-linkaccount.component'; // TODO:MFA
import { RegistrationLinkAccountComponent } from './registration/profile/registration-linkaccount.component'; // TODO:MFA

import { RegistrationNavigationComponent } from './registration/navigation/registration-navigation.component'; // TODO:MFA


// import { RegistrationAgreementComponent } from '../app/registration/agreement/registration-agreement.component'; // TODO:MFA
import { RegistrationAgreementComponent } from './registration/agreement/registration-agreement.component'; // TODO:MFA

import { RegistrationSummaryComponent } from './registration/summary/registration-summary.component'; // TODO:MFA
// import { LinkAccountComponent } from '../app/registration/profile/linkaccount.component'; // TODO:MFA
import { LinkAccountComponent } from './registration/profile/linkaccount.component'; // TODO:MFA
import { IdleComponent } from './idle/idle.component'; // TODO:MFA
// import { TermsAndConditionsComponent } from '../app/terms-and-conditions.component';
// import { ElectronicStatementComponent } from '../app/electronic-statement.component';
// import { AchUSBankStatementComponent } from '../app/ach-us-bank-statement.component';
// import { LineOfBusinessInformationComponent } from '../app/form/line-of-business/line-of-business-information.component';
// import { BorrowerDetailComponent } from '../app/form/borrower/borrower-detail.component';
// import { BorrowerInformationComponent } from '../app/form/borrower/borrower-information.component';
// import { ConfirmationComponent } from '../app/form/confirmation/confirmation.component';
// import { EntityInformationComponent } from '../app/form/entity/entity-information.component';
// import { FinancialInformationBalanceSheetSelectionComponent } from '../app/form/balance-sheet/financial-information-balance-sheet-selection.component';
// import { FinancialInformationBalanceSheetComponent } from '../app/form/balance-sheet/financial-information-balance-sheet.component';
// import { FinancialInformationIncomeComponent } from '../app/form/income/financial-information-income.component';
// import { FinancialInformationComponent } from '../app/form/balance-sheet/financial-information.component';
// import { FinancialSummaryComponent } from '../app/form/balance-sheet/financial-summary.component';
// import { OwnerDetailComponent } from '../app/form/ownership/owner-detail.component';
// import { OwnershipComponent } from '../app/form/ownership/ownership.component';
// import { RadioCompleteComponent } from '../app/form/line-of-business/radio-complete.component';
import { ResetPasswordLandingComponent } from './form/reset-password/reset-password-landing.component';
// import { SummaryAndSignComponent } from '../app/form/summary/summary-and-sign.component';
// import { SurveyComponent } from '../app/form/confirmation/survey.component';
// import { VendorProgramsComponent } from '../app/form/program/vendor-programs.component';
// import { VendorComponent } from '../app/form/program/vendor.component';
// import { NewLandingComponent } from '../app/landing/new-landing.component';
import { HomeComponent } from './home/home.component'; // TODO:MFA
// import { HelpTipComponent } from '../app/form/reusables/help-tip.component';
// import { NumberInputComponent } from '../app/form/reusables/number-input.component';
// import { NavigationDashboardComponent } from '../app/dashboard/account/navigation/navigation.component';
import { AccountPreferencesComponent } from './dashboard/preferences/account-preferences.component';
// import { PreferencesContactComponent } from '../app/dashboard/preferences/contact/preferences-contact.component';
import { PreferencesNameComponent } from './dashboard/preferences/name/preferences-name.component';
import { PreferencesContactComponent } from './dashboard/preferences/contact/preferences-contact.component';
import { PreferencesEmailComponent } from './dashboard/preferences/email/preferences-email.component';
import { PreferencesEmailFrameComponent } from './dashboard/preferences/email/preferences-email-frame.component';
import { PreferencesEmailConfirmationComponent } from './dashboard/preferences/email/preferences-email-confirmation.component';
import { ChangeEmailConfirmationComponent } from './dashboard/preferences/email/change-email-confirmation.component';
import { PreferencesPasswordComponent } from './dashboard/preferences/password/preferences-password.component';
import { PreferencesPasswordFrameComponent } from './dashboard/preferences/password/preferences-password-frame.component';
import { PreferencesPasswordConfirmationComponent } from './dashboard/preferences/password/preferences-password-confirmation.component';
import { ChangePasswordConfirmationComponent } from './dashboard/preferences/password/change-password-confirmation.component';
// import { PreferencesPasswordComponent } from '../app/dashboard/preferences/password/preferences-password.component';
import { PreferencesAlertsComponent } from './dashboard/preferences/alerts/preferences-alerts.component';
// import { PreferencesSecurityComponent } from '../app/dashboard/preferences/security/preferences-security.component';
// import { ApplicationStatusSummaryComponent } from '../app/dashboard/home/application-status-summary/application-status-summary.component';
// import { DashboardHomeComponent } from '../app/dashboard/home/dashboard-home.component';
import { DashboardHomeComponent } from './dashboard/home/dashboard-home.component';
// import { AccountSummaryComponent } from '../app/dashboard/home/account-summary/account-summary.component';
import { AccountSummaryComponent } from './dashboard/home/account-summary/account-summary.component';
// import { PaymentsComponent } from '../app/dashboard/account/payments/payments.component';
// import { PayoffComponent } from '../app/dashboard/account/payments/payoff.component';
// import { PaymentNavigationComponent } from '../app/dashboard/account/payments/payment-navigation.component';
// import { AccountStatementsComponent } from '../app/dashboard/account/statements/account-statements.component';
import { UserAccountGuard } from '../app/services/user-account-guard';
import { UserContactGuard } from './services/user-account-guard'; // TODO:MFA
// import { AccountDetailsComponent } from '../app/dashboard/account/account-details/account-details.component';
// import { AccountTemplateComponent } from '../app/dashboard/account/account-template.component';
// import { PaymentHistoryComponent } from '../app/dashboard/account/payments/payment-history.component';
// import { SubCommitmentDetailsComponent } from '../app/dashboard/account/account-details/subcommitment-details.component';
// import { AmendmentComponent } from '../app/dashboard/account/amendment/amendment.component';
// import { AuthorizedPartyComponent } from '../app/dashboard/account/authorized-party/authorized-party.component';
// import { AuthorizedPartyAddFormComponent } from '../app/dashboard/account/authorized-party/add-form/add-form.component';
// import { AuthorizedPartyInProgressDetailComponent } from '../app/dashboard/account/authorized-party/in-progress-detail/in-progress-detail.component';
// import { AuthorizedPartyResponseComponent } from '../app/dashboard/account/authorized-party/response/response.component';
// import { TransactionReportComponent } from '../app/dashboard/account/transaction-report/transaction-report.component';
// import { LoadingFeedbackComponent } from '../app/shared/loading-feedback/loading-feedback.component';
// import { SectionHeaderComponent } from '../app/shared/section-header/section-header.component';
// import { PreApprovalComponent } from '../app/form/preapproval/preapproval.component';
// import { EditSecurityQuestionsComponent } from '../app/form/reusables/account/edit-security-questions.component';
// import { ResetSecurityQuestionsComponent } from '../app/form/reusables/account/reset-security-questions.component';
// import { DoingBusinessAsComponent } from '../app/form/entity/doing-business-as/doing-business-as.component';
// import { IncreasedRiskCountriesComponent } from '../app/form/increased-risk-countries/increased-risk-countries.component';
import { LookupService } from '../app/services/lookup.service';
import { UserService } from '../app/services/user.service';
import { StorageService } from '../app/services/storage.service';
import { AuthService as  AuthService} from './services/auth.service'; // TODO:MFA
import { AuthService as AuthServiceV1} from '../app/services/auth.service'; // TODO:MFA
import { AuthenticatedHttp } from '../app/services/authenticated-http.service';
import { CurrentApplicationService } from '../app/services/current-application.service';
import { ApplicationHttpService } from '../app/services/application-http.service';
// import { CurrentRegistrationService } from '../app/services/current-registration.service';
import { CurrentRegistrationService } from './services/current-registration.service'; // TODO:MFA
import { AccountHttpService as AccountHttpService } from './services/account-http.service'; // TODO:MFA
import { AccountHttpService as AccountHttpServiceV1 } from '../app/services/account-http.service'; // TODO:MFA
import { DocumentService } from '../app/services/document.service';
import { MessageCenterService } from '../app/services/message-center.service';
import { VendorService } from '../app/services/vendor.service';
import { AuthorizedPartyService } from '../app/services/authorized-party.service';
import { FinancialSummaryService } from '../app/services/financial-summary.service';
import { LoggingService } from '../app/services/logging.service';
import { IfAngularCoreModule } from 'if-angular-core';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser'; // TODO:MFA
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { CONFIG } from '../environments/environment';
import { IfAngularSecurityModule, IfB2CConfigService} from 'if-angular-security';
import { AppModule } from '../app/app.module';
import { SiteComponent } from './site.component';

// TODO:MFA
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(IfB2CConfigService.getConfig(CONFIG));
  }

  /**
   * Set your default interaction type for MSALGuard here. If you have any
   * additional scopes you want the user to consent upon login, add them here as well.
   */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect
    };
}

@NgModule({
  imports: [
    BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule, Routing,
    MatDatepickerModule, MatInputModule, MatNativeDateModule, CheckboxModule, AccordionModule, DialogModule, TableModule,
    RadioButtonModule, DropdownModule, ButtonModule, TabMenuModule, ToastModule, InputTextareaModule, AutoCompleteModule, TextMaskModule,
    CalendarModule, MatSelectModule, MatRadioModule, MessageModule, MessagesModule, FlexLayoutModule, IfAngularCoreModule,
    IfAngularSecurityModule, AppModule],       // TODO:MFA module dependencies
  declarations: [
    AppComponent, // TODO:MFA REAL
    AccountPreferencesComponent,
    HeaderComponent, // TODO:MFA REAL
    ResetPasswordLandingComponent,
    ResetPasswordComponent, // Will have change soon
    IdleComponent, // TODO:MFA REAL FULL
    HomeComponent,
    B2cContentHostComponent,
    LoginComponent, // TODO:MFA REAL
    B2CLoginComponent,
    RegistrationNavigationComponent, // TODO:MFA REAL
    RegistrationComponent,
    RegistrationAgreementComponent, // TODO:MFA REAL
    RegistrationLinkAccountComponent, // TODO:MFA REAL
    RegistrationProfileComponent,
    RegistrationSummaryComponent,
    PreferencesNameComponent,
    PreferencesContactComponent,
    PreferencesEmailComponent,
    PreferencesEmailFrameComponent,
    PreferencesEmailConfirmationComponent,
    ChangeEmailConfirmationComponent,
    PreferencesPasswordComponent,
    PreferencesPasswordFrameComponent,
    PreferencesPasswordConfirmationComponent,
    ChangePasswordConfirmationComponent,
    PreferencesAlertsComponent,
    LinkAccountComponent,
    AccountSummaryComponent,
    DashboardHomeComponent,
    SiteComponent,
  ],   // components and directives
  bootstrap: [
    AppComponent
  ],     // root component
  providers: [
    Idle, SimpleExpiry, { provide: IdleExpiry, useExisting: SimpleExpiry }, AuthorizationGuard, UserService, AuthService,
    AuthenticatedHttp, CurrentApplicationService, ApplicationHttpService, CurrentRegistrationService, AccountHttpService,
    StorageService, UserAccountGuard, DocumentService, MessageCenterService, VendorService, AuthorizedPartyService, FinancialSummaryService,
    LoggingService, AuthServiceV1, AccountHttpServiceV1, UserContactGuard,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    LookupService,
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]                    // services
})
export class AppModuleV2 { }
