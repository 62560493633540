<div *ngIf="subcommitment">
    <h2>{{subcommitment.Dealer.Name}} - {{subcommitment.SubCommitmentNumber}}</h2>
    <div class="row">
        <div class="col-md-4">
            <label>Available Credit:</label>
            {{getAvailableCredit() | currency:'USD'}}
        </div>
        <div class="col-md-4">
            <label>Total Credit:</label>
            {{getTotalCredit() | currency:'USD'}}
        </div>
        <div class="col-md-4">
            <label>Draw Period:</label>
            {{subcommitment.MaturityDate | date:'shortDate'}}
        </div>
    </div>
    <p-table [value]="loanDetails" expandableRows="true" dataKey="rowIndex">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="col-icon"></th>
                <th>Program</th>
                <th>Current Balance</th>
                <th>Next Payment Due Date</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-expanded="expanded">
            <tr>
                <td [pRowToggler]="row">
                    <i [ngClass]="expanded ? 'fa fa-chevron-circle-down fa-lg' : 'fa fa-chevron-circle-right fa-lg'"></i>
                </td>
                <td> {{row.ProgramName }} </td>
                <td> {{row.Balance  | currency:'USD' }} </td>
                <td> {{row.MaturityDate | date:'shortDate'}} </td>
            </tr>
        </ng-template>

        <ng-template let-loan pTemplate="rowexpansion">
            <tr>
                <td colspan="4">
                    <div class="grid" style="font-size:16px;padding:20px">
                        <div class="col-6" style="text-align:left;">                            
                                <div class="col-12 sc-data">
                                    <span class="sc-label">Principal Balance:&nbsp;</span>
                                    <span class="sc-data">{{loan.PrincipalBalance  | currency:'USD'}}</span>
                                </div>
                                <div class="col-12 sc-data">
                                    <span class="sc-label">Maturity Date:&nbsp;</span>
                                    <span class="sc-data">{{loan.MaturityDate | date:'shortDate'}}</span>
                                </div>
                                <div class="col-12 sc-data">
                                    <span class="sc-label">Interest Rate:&nbsp;</span>
                                    <span class="sc-data">
                                        {{loan.InterestRate}}%<span *ngIf="loan.NextRateDate">*</span>
                                    </span>
                                </div>                            
                        </div>
                        <div class="col-6" *ngIf="loan.Transactions">
                            <div class="sc-label" style="text-align:center"><label>Recent Transactions</label></div>
                            <div class="grid">
                                <div class="col-4">Date</div>
                                <div class="col-4">Amount</div>
                                <div class="col-4" style="text-align:right">Type</div>
                                <hr />
                            </div>
                            <div class="grid" *ngFor="let trans of loan.Transactions; let i=index">
                                <div class="col-4">{{trans.TransactionDate | date: 'shortDate'}}</div>
                                <div class="col-4">{{trans.Amount | currency:'USD'}}</div>
                                <div class="col-4" style="text-align:right">{{trans.Type}}</div>
                            </div>
                        </div>
                        <div *ngIf="loan.NextRateDate" class="p-grid-row">
                            <div class="p-grid-col-12 rateStepNotice">
                                * This rate will be changing on {{loan.NextRateDate | date:'shortDate'}}. See full loan
                                program
                                terms for more information.
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>