import { TopForm } from '../top-form';
import { FormControl, Validators } from '@angular/forms';

import { UtilitiesService } from '../../services/utilities.service';
import { Crop } from '../../models/crop';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { totalIncomeMaxNLS, FinancialValidation } from '../financial-validation';
import { CropItemFactory } from './crop-item-factory';
import { IItemFactory } from '../reusables/autocomplete/autocomplete.component';

export class Crops {
    form: TopForm;
    editingCrop: Crop;
    cropName: string;
    cropYieldUnits: string;
    current: Crop;
    financialValidation: FinancialValidation = new FinancialValidation();
    totalIncomeMax: number = totalIncomeMaxNLS;

    constructor(private utilities: UtilitiesService) {
        this.current = new Crop();
        this.form = new TopForm();
        this.form.addControl('acres', new FormControl('', Validators.required));
        this.form.addControl('yield', new FormControl('', Validators.required));
        this.form.addControl('price', new FormControl('',
            [Validators.required, validateNumberFactory(false, 2, 0, this.totalIncomeMax, this.utilities)]));
    }

    update(crop?: Crop) {
        this.cropName = crop ? crop.name : '';
        this.current.acres = crop ? crop.acres : 0;
        this.current.averageYield = crop ? crop.averageYield : 0;
        this.cropYieldUnits = crop ? crop.yieldUnits : '';
        this.current.price = crop ? crop.price : 0;
    }

    clear() {
        this.update();
    }

    setCropName() {
        const cropFactory = new CropItemFactory();

        const crops = this;
        return function (cropName) {
            crops.cropName = cropName;
            const cropItems = cropFactory.getCropItems();

            if (cropItems[cropName]) {
                crops.cropYieldUnits = cropItems[cropName];
            }
        };
    }

    setYieldUnits() {
        const crops = this;
        return function (yieldUnits) {
            crops.cropYieldUnits = yieldUnits;
        };
    }

    get value(): Crop {
        return new Crop({
            Name: this.cropName,
            Acres: this.current.acres,
            AverageYield: this.current.averageYield,
            YieldUnits: this.cropYieldUnits,
            Price: this.current.price
        });
    }
}
