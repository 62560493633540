import {
    Component, OnInit, Input, Output,
    EventEmitter, OnDestroy
} from '@angular/core';

import { Question } from '../../models/wizard/question';
import { Finances } from '../../models/finances';
import { SimpleQuestion } from '../../models/wizard/simple-question';
import { ComplexQuestion } from '../../models/wizard/complex-question';
import { totalIncomeMaxNLS } from '../financial-validation';
import { FinancialSummaryService } from '../../services/financial-summary.service';

@Component({
    selector: 'gol-financial-summary',
    templateUrl: 'financial-summary.component.html',
    styleUrls: ['financial-summary.component.css'],
})
export class FinancialSummaryComponent implements OnInit, OnDestroy {
    selected: any = {};
    finances: Finances;
    @Input()
    application;
    @Input()
    characters: string[];
    @Input()
    questions: Question[];
    @Output()
    navigateWizard = new EventEmitter<number>();

    public totalIncomeMax: number = totalIncomeMaxNLS;

    constructor(private financialSummaryService: FinancialSummaryService) {
    }

    ngOnInit() {
        if (this.characters && this.characters.length > 0) {
            if (this.characters.length === 1) {
                this.selected.index = 0;
                this.selected.character = this.characters[0];
            } else {
                this.selected.index = -1;
            }

            this.finances = this.transform(this.questions);

            this.financialSummaryService.setContainsErrorsMethod(() => {
                return this.financesOutsideOfMax(this.totalAssets())
                    || this.financesOutsideOfMax(this.totalLiabilities())
                    || this.financesOutsideOfMax(this.application.income.totalFarmIncome);
            });
        }
    }

    ngOnDestroy() {
        this.financialSummaryService.setContainsErrorsMethod(null);
    }

    selectCharacter(character: string) {
        this.selected.index = this.characters.indexOf(character);
        this.selected.character = character;
    }

    financesOutsideOfMax(total: number): boolean {
        return total > totalIncomeMaxNLS;
    }

    sum(values: number[]): number {
        return values.reduce((prev, curr) => prev + curr, 0);
    }

    selectQuestion(index: number) {
        this.navigateWizard.emit(index);
    }

    totalAssets() {
        return this.sum(this.finances.assets.total) | 0;
    }

    totalLiabilities() {
        return this.sum(this.finances.liabilities.total) | 0;
    }

    netWorth() {
        return this.totalAssets() - this.totalLiabilities();
    }

    transform(questions): Finances {
        this.finances = new Finances();

        if (questions) {
            for (let index = 0; index <= questions.length - 1; index++) {
                const question: Question = questions[index];

                if (question instanceof SimpleQuestion) {
                    this.finances[question.fieldType.toLowerCase()].addQuestion(+index,
                        question.length,
                        question.description,
                        question.values);
                } else if (question instanceof ComplexQuestion) {
                    this.finances.assets.addQuestion(+index, question.length, question.description, question.assets);
                    this.finances.liabilities.addQuestion(+index, 'shortTerm', question.description, question['shortTermLiability']);
                    const values: number[] = question.totalLiability.map((total, i) => total - question['shortTermLiability'][i]);
                    this.finances.liabilities.addQuestion(+index, question.length, question.description, values);
                }
            }
        }

        return this.finances;
    }
}
