import { Component, Input, AfterViewInit, ViewChildren, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { NgForm, NgModel, FormGroup } from '@angular/forms';
import { hundredWithDecimalMask } from '../validation/custom-masks';
import * as moment from 'moment';
import { UtilitiesService } from '../../services/utilities.service';
import { LookupService } from '../../services/lookup.service';
import { USA } from '../../models/constants';

@Component({
    selector: 'gol-borrower-detail',
    templateUrl: 'borrower-detail.component.html',
    styles: [`
    .postfix {
        float: right;
        margin-top:-30px;
        padding-right:5px;
    }`]

})
export class BorrowerDetailComponent implements OnInit, AfterViewInit {

    constructor(utilities: UtilitiesService, private lookupService: LookupService) {
        this._utilities = utilities;
    }
    _utilities: UtilitiesService;

    citizenshipCountries: string[] = null;
    addressCountries: string[] = null;

    @ViewChildren(NgModel) inputChildren;

    @Input() form: NgForm;
    @Input() group: FormGroup;

    ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    hundredMask = hundredWithDecimalMask;


    @Input() showOwnership: boolean;
    @Input() borrower: Person;

    maxDob: Date = moment().subtract(18, 'years').toDate();
    minDob: Date = new Date(1880, 0, 1);
    today: Date = moment().toDate();

    ngOnInit() {
        if (this.borrower.phoneNumber) {
            this.borrower.phoneNumber = this._utilities.formatPhoneNumber(this.borrower.phoneNumber);
        }

        this.lookupService.getCitizenshipCountriesExceptUSA()
            .then(resolve => {
                this.citizenshipCountries = [''].concat(resolve);
            });

        this.lookupService.getPermanentResidenceCountries()
            .then(resolve => {
                this.addressCountries = [''].concat(resolve);
            });

        this.setBorrowerCitizenshipToUS();
    }

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.group.addControl(item.name, item.control);
        });
    }

    isCitizen() {
        return this.borrower.isUSCitizen === true;
    }

    isBankruptcy() {
        return this.borrower.hasBankruptcy === true;
    }

    changeCitizen(value) {
        this.borrower.isUSCitizen = (value === 'true');
        if (this.borrower.isUSCitizen) {
            this.group.removeControl('citizenshipCountry');
            this.group.removeControl('permanentres');
            this.setBorrowerCitizenshipToUS();
            this.changeResident('true');
        } else {
            this.reAddConditionalControls();
        }
    }

    setBorrowerCitizenshipToUS() {
        this.borrower.countryOfCitizenship = USA;
    }

    changeBankruptcy(value) {
        this.borrower.hasBankruptcy = (value === 'true');
        if (this.borrower.hasBankruptcy) {
            this.reAddConditionalControls();
        } else {
            this.group.removeControl('filingDate');
            this.group.removeControl('dischargeDate');
        }
    }

    changeResident(value) {
        this.borrower.isPermanentResident = (value === 'true');
    }

    reAddConditionalControls() {
        setTimeout(() => {
            this.inputChildren.forEach((item) => {
                if (!this.group.contains(item.name)) {
                    this.group.addControl(item.name, item.control);
                }
            });
        });
    }
}