import { Application } from './application';
import { ApplicationType } from './application-type';
import * as moment from 'moment';
const MAX_APP_AGE_TO_CONTINUE = 90;
interface Page { alias: string; name: string; hideFromNav: boolean; route: string; }

export class NavigationOptions {
  public static Pages: Page[] = [
    { alias: 'entity', name: 'Entity', hideFromNav: false, route: '/entity-information' },
    { alias: 'borrower', name: 'Borrower', hideFromNav: false, route: '/borrower-information' },
    { alias: 'ownership', name: 'Ownership', hideFromNav: false, route: '/ownership' },
    { alias: 'program', name: 'Program', hideFromNav: false, route: '/program' },
    { alias: 'line-of-business-information', name: 'Line of Business', hideFromNav: false, route: '/line-of-business-information' },
    { alias: 'income', name: 'Financial', hideFromNav: false, route: '/financial-information-income' },
    { alias: 'balancesheetSelection', name: 'Balance Sheet Selection', hideFromNav: true, route: '/financial-information-balance-sheet-selection' },
    { alias: 'balancesheet', name: 'Balance Sheet', hideFromNav: true, route: '/financial-information-balance-sheet' },
    { alias: 'financial', name: 'Financial Info', hideFromNav: true, route: '/financial-information' },
    { alias: 'sign', name: 'Summary', hideFromNav: false, route: '/summary-and-sign' },
    { alias: 'confirmation', name: 'Confirmation', hideFromNav: true, route: '/confirmation' }
  ];

  public static GetStartPage(application: Application): string {
    const appPages = this.AppPages(application, true);
    let page = appPages.find(el => el.alias === application.currentPage);
    if (moment(application.createdDate) < moment().subtract(MAX_APP_AGE_TO_CONTINUE, 'days') || !page || page.alias === 'confirmation') {
      page = appPages[0];
    }
    return page.route;
  }

  public static GetPageIndexByAlias(name: string, application: Application, includeHidden?: boolean) {
    return this.AppPages(application, !!includeHidden).findIndex((el) => {
      return el.alias === name;
    });
  }

  public static VisiblePages(): Page[] {
    return this.Pages.filter((page) => !page.hideFromNav);
  }

  public static AppPages(application: Application, includeHidden: boolean): Page[] {
    return (includeHidden ? this.Pages : this.VisiblePages()).filter((page) => {
      return ((page.alias !== 'entity' && page.alias !== 'ownership') || application.appType == ApplicationType.Entity);
    });
  }
}
