import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import numberMask from 'text-mask-addons/dist/createNumberMask';
import { totalIncomeMaxNLS, FinancialValidation } from '../financial-validation';
import { validateNumberFactory } from '../validation/number-validator.directive';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-financial-information',
    templateUrl: 'financial-information.component.html',
    styleUrls: ['../styles_form.css', 'financial-table.css']
})
export class FinancialInformationComponent extends AbstractFormPage implements OnInit {

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService, notificationService: NotificationService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'financial';
        this.initPage();
    }

    public get totalAssetsInvalid(): boolean {
        return this.application.balanceSheet.totalAssets > this.totalIncomeMax;
    }

    public get totalLiabilitiesInvalid(): boolean {
        return this.application.balanceSheet.totalLiabilities > this.totalIncomeMax;
    }

    public get totalFarmIncomeInvalid(): boolean {
        return this.application.income.totalFarmIncome > this.totalIncomeMax;
    }
    form: TopForm = new TopForm();
    currentAssets: FormControl;
    termAssets: FormControl;
    currentLiabilities: FormControl;
    termLiabilities: FormControl;
    intermediateAssets: FormControl;
    intermediateLiabilities: FormControl;
    creationDate: FormControl;
    minBalanceSheetDate: moment.Moment;
    minDateError = false;
    maxDateError = false;
    minDate: Date = new Date(this.getMinimumBalanceSheetDate()['_d']);
    financialValidation: FinancialValidation = new FinancialValidation();
    totalIncomeMax: number = totalIncomeMaxNLS;

    mask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });

    ngOnInit() {
        this.currentAssets = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.termAssets = new FormControl('', [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.currentLiabilities = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.termLiabilities = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.intermediateAssets = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.intermediateLiabilities = new FormControl(
            '',
            [Validators.required, validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)]);
        this.creationDate = new FormControl('', []);
        this.form.addControl('currentAssets', this.currentAssets);
        this.form.addControl('termAssets', this.termAssets);
        this.form.addControl('currentLiabilities', this.currentLiabilities);
        this.form.addControl('termLiabilities', this.termLiabilities);
        this.form.addControl('intermediateLiabilities', this.intermediateLiabilities);
        this.form.addControl('intermediateAssets', this.intermediateAssets);
        this.form.addControl('creationDate', this.creationDate);
        this.minBalanceSheetDate = this.getMinimumBalanceSheetDate();
    }

    getMinimumBalanceSheetDate(): moment.Moment {
        let minimumBalanceSheetDate = moment().subtract(6, 'month');
        const startOfYear = this.getStartOfFiscalYear();
        if (startOfYear < minimumBalanceSheetDate) {
            minimumBalanceSheetDate = startOfYear;
        }
        return minimumBalanceSheetDate;
    }

    getStartOfFiscalYear(): moment.Moment {
        const startOfYear = moment().month(11).date(1);
        if (startOfYear > moment()) {
            startOfYear.year(startOfYear.year() - 1);
        }
        return startOfYear;
    }

    checkDateAge() {
        this.minDateError = this.application.balanceSheet.creationDate < new Date(this.minDate.toDateString());
        this.maxDateError = this.application.balanceSheet.creationDate > new Date();
    }

    isValidForm(){
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        const noDateError = (!this.application.balanceSheet.creationDate && this.application.balanceSheet.totalEquity > 0);
        const totalsOutsideOfRange = this.totalAssetsInvalid || this.totalLiabilitiesInvalid || this.totalFarmIncomeInvalid;

        if (this.minDateError || this.maxDateError || noDateError) {
            window.scrollTo(0, 0);
            return false;
        } else {
            if (this.form.valid && !totalsOutsideOfRange) {
                return true;
            } else {
                this.scrollToError();
                return false;
            }
        }
    }

    submit() {
        if (this.isValidForm()) {
            this.next();
        }
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string) {
        return new Promise<boolean>((resolve, reject) => {
            const comp = this;
            if (comp.isValidForm()) {
                comp.application.currentPage = newPageAlias;
                resolve(comp.save());
            } else {
                reject(false);
            }
        });
    }

    updateNetWorth() {
        // setTimeout(() => {
        //     this.form.controls['Date of Balance Sheet'].updateValueAndValidity();
        // });
    }
}
