import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'gol-registration-summary',
    templateUrl: 'registration-summary.component.html',
    styleUrls: ['../../../app/registration/css/styles_form.css'],
})

export class RegistrationSummaryComponent {

    constructor(public router: Router) { }
}
