import * as _ from 'lodash';

const POBOX_ABBREVIATIONS =
    [
        'pobox',
        'po box',
        'p.o.'
    ];

export class Address {
    line1 = '';
    line2 = '';
    city = '';
    state = '';
    zipcode = '';
    allowPOBox = true;

    constructor(allowPOBox, appData?: any) {
        this.allowPOBox = allowPOBox;
        if (appData) {
            this.city = _.isNil(appData.City) ? '' : appData.City;
            this.line1 = _.isNil(appData.Line1) ? '' : appData.Line1;
            this.line2 = _.isNil(appData.Line2) ? '' : appData.Line2;
            this.state = _.isNil(appData.State) ? '' : appData.State;
            this.zipcode = _.isNil(appData.ZipCode) ? '' : appData.ZipCode;
        }
    }

    get streetValid(): boolean {
        return this.allowPOBox || this.isPhysicalAddress();
    }

    isPhysicalAddress(): boolean {
        let pobox = false;
        let i = 0;

        while (i < POBOX_ABBREVIATIONS.length && !pobox) {
            if (this.line1.toLowerCase().indexOf(POBOX_ABBREVIATIONS[i]) !== -1) {
                pobox = true;
            }
            i++;
        }

        return !pobox;
    }

    toString(): string {
        return `${this.line1}, ${this.city}, ${this.state} ${this.zipcode}`;
    }

    get isComplete(): boolean {
        return this.line1 && this.line1.length > 0 && this.state.length > 0 && this.city.length > 0 && this.zipcode.length > 0;
    }
}
