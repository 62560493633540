import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import {
    AuthorizedPartyBase,
    authorizedPartyIdStorageKey
} from '../authorized-party-base';
import { AuthorizedPartyService } from '../../../../services/authorized-party.service';
import { AccountHttpService } from '../../../../services/account-http.service';
import { StorageService } from '../../../../services/storage.service';

class Status {
    title: string;
    message: string;
}

@Component({
    selector: 'gol-authorized-party-response',
    styleUrls: [
        'response.component.css',
        '../authorized-party.style.css',
        '../../../../form/summary/waiting.css',
        '../../../../form/styles_form.css'],
    templateUrl: 'response.component.html'
})
export class AuthorizedPartyResponseComponent extends AuthorizedPartyBase implements OnInit {
    status: Status;
    showContinue = false;
    showPrintDialog = false;
    public responseRetrieved: string = null;

    constructor(route: ActivatedRoute, accountHttpService: AccountHttpService, private storageService: StorageService,
        private authorizedPartyService: AuthorizedPartyService, private router: Router) {
        super(route, accountHttpService);
    }

    ngOnInit() {
        const eventQP = this.router.parseUrl(this.router.url).queryParams['event'];
        this.status = new Status();

        if (eventQP === 'signing_complete') {
            this.status.title = 'Submitted';
            // tslint:disable-next-line: max-line-length
            this.status.message = 'You\'ve successfully submitted a request to add an authorized party to your account which requires endorsements by the authorized party. They will receive an email from DocuSign with instructions to complete the signature process.  Once that is complete, we will be able to process your request.';
        } else if (eventQP === 'id_check_failed') {
            this.showContinue = true;
            this.status.title = 'ID Check';
            this.status.message = 'Sorry, your request was cancelled due to an ID check failure.';
        } else if (eventQP === 'viewing_complete') {
            this.router.navigate(['dashboard', 'account', this.accountNumber, 'authorized-party']);
        } else if (eventQP === 'server_error') {
            this.showContinue = true;
            this.status.title = 'DocuSign error';
            this.status.message = 'There was an unexpected problem contacting DocuSign.';
        } else {
            this.showContinue = true;
            this.status.title = 'Cancelled';
            this.status.message = 'Your request was cancelled. You can:';
        }
    }

    createNew() {
        this.router.navigate(['dashboard', 'account', this.accountNumber, 'authorized-party', 'add']);
    }

    printApp(): void {
        const id = +this.storageService.getFromSession(authorizedPartyIdStorageKey);
        this.CurrentCommitment.then((c) => {
            this.authorizedPartyService.getPrintableRequest(id, c.AcctRefNo)
                .then((blob) => {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, 'Rabo AgriFinance Add Authorized Party.pdf');
                    } else {
                        const fileURL = URL.createObjectURL(blob);
                        window.open(fileURL, '_blank');
                    }
                    this.showPrintDialog = false;
                });
        });
    }

    openPrintDialog(): void {
        this.showPrintDialog = true;
    }

    closePrintDialog(): void {
        this.showPrintDialog = false;
    }
}
