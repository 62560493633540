import { Directive, ElementRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UtilitiesService } from '../../services/utilities.service';

@Directive({
    selector: '[golForceDecimals]',
    host: { '(blur)': 'mapValue()' }
})
export class ForceDecimalsDirective {
    constructor(private ele: ElementRef, private utilities: UtilitiesService) {

    }

    mapValue() {
        const value = this.ele.nativeElement.value;
        if (value) {
            const numberValue = this.utilities.toNumber(value);
            const decimalValue = new CurrencyPipe('en').transform(numberValue, 'USD', 'symbol', '1.2-2');
            this.ele.nativeElement.value = decimalValue;
        }
    }
}
