<div ngFormGroupName="borrower">
    <gol-fullname-component name="fullname" [fullName]="borrower.fullName" Title="Legal Name" [form]="form"
        [group]="group">
    </gol-fullname-component>

    <div class="form-group">
        <label for="ssn">Social Security Number</label>
        <input id="ssn" name="ssn" class="form-control" [(ngModel)]="borrower.ssn" placeholder="Social Security Number"
            #ssn="ngModel" required type="text" [textMask]="{mask: ssnMask, guide:false}" minlength="11"
            maxlength="11" />
        <div class="error" *ngIf="(form.submitted || ssn.dirty) && ssn.invalid">
            <p *ngIf="ssn.errors['required']">Required</p>
            <p *ngIf="ssn.errors['minlength'] || ssn.errors['maxlength']">Social Security Number is invalid. Must be in
                the format: 123-45-6789.</p>
        </div>
    </div>

    <div class="form-group">
        <label for="email">Email</label>
        <input id="email" name="email" class="form-control" [(ngModel)]="borrower.email" placeholder="Email" required type="email"
            #email="ngModel" validate-email />
        <div class="error" *ngIf="(form.submitted ||email.dirty) && email.invalid">
            <p *ngIf="email.errors['required']">Required</p>
            <p *ngIf="email.errors['validateEmail']">Email is invalid</p>
        </div>
    </div>

    <div class="form-group">
        <gol-date-box [(ngModel)]="borrower.dateOfBirth" label="Date of Birth" golDateRangeValidator [min]="minDob"
            [max]="maxDob" required name="dob" #dob="ngModel"></gol-date-box>
        <div *ngIf="(form.submitted || dob.dirty) && dob.invalid" class="error">
            <p *ngIf="dob.errors['required']">A date is required</p>
            <p *ngIf="dob.errors['invalidDate']">That is not a valid date</p>
            <p *ngIf="dob.errors['precedesMin']">Please select a date after {{dob.errors['precedesMin'].toDateString()}}
            </p>
            <p *ngIf="dob.errors['exceedsMax']">Please select a date before {{dob.errors['exceedsMax'].toDateString()}}
            </p>
        </div>
    </div>

    <gol-phone-component name="phone" [(ngModel)]="borrower.phoneNumber" Title="Phone Number" [form]="form"
        [group]="group" required>
    </gol-phone-component>

    <gol-phone-component name="altphone" [(ngModel)]="borrower.alternatePhoneNumber" Title="Alternate Phone Number"
        [isrequired]="false" [form]="form" [group]="group"></gol-phone-component>

    <gol-combined-address-component name="combAddress" [(physicalAddress)]="borrower.physicalAddress"
        [(mailingAddress)]="borrower.mailingAddress" [form]="form" [group]="group" [showHomeAddressPrefix]="true">
    </gol-combined-address-component>

    <div class="form-group" *ngIf="showOwnership">
        <label for="ownerPercent">Ownership Percentage</label>
        <input id="ownerPercent" name="ownerPercent" class="form-control" [(ngModel)]="borrower.ownershipPercentage"
            placeholder="Ownership Percentage" required #ownerPercent="ngModel" validate-number type="text" min="0"
            max="100" allowDecimal="2" [textMask]="{mask: hundredMask, guide:false}" />
        <div class="postfix">%</div>
        <div class="error" *ngIf="(form.submitted ||ownerPercent.dirty) && ownerPercent.invalid">
            <p *ngIf="ownerPercent.errors['required']">Required</p>
            <p *ngIf="ownerPercent.errors['validateNumber']">
                Ownership percentage must be between 0 and 100, with up to 2 decimal places
            </p>
        </div>
    </div>

    <div class="form-group" *ngIf="showOwnership">
        <label for="signerTitle">Company Role/Title</label>
        <input #signerTitle="ngModel" id="signerTitle" name="signerTitle" class="form-control" [(ngModel)]="borrower.signerTitle"
            required placeholder="Title">
        <div *ngIf="(form.submitted || signerTitle.dirty) && signerTitle.invalid" class="error">
            <p *ngIf="signerTitle.errors">Required</p>
        </div>
    </div>

    <div class="form-group">
        <label for="bankruptcy">Bankruptcy Filing</label>
        <select [ngModel]="borrower.hasBankruptcy" (change)="changeBankruptcy($event.target.value)" id="bankruptcy" name="bankruptcy"
            required class="form-control">
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>

    <div class="form-group">
        <label for="uscitizen">U.S. Citizen</label>
        <select [ngModel]="borrower.isUSCitizen" (change)="changeCitizen($event.target.value)" id="uscitizen" name="uscitizen" required
            class="form-control">
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>    

    <div class="form-group" *ngIf="!borrower.isUSCitizen">
        <label for="permanentres">Permanent Resident</label>
        <select [ngModel]="borrower.isPermanentResident" (change)="changeResident($event.target.value)"
            id="permanentres" name="permanentres" class="form-control" required>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    </div>

    <div class="form-group" *ngIf="showOwnership">
        <label for="countryOfPermanentResidence">Country of Permanent Residence</label>
        <select [(ngModel)]="borrower.countryOfPermanentResidence" 
                id="countryOfPermanentResidence" name="countryOfPermanentResidence" 
                class="form-control"
                #countryOfPermanentResidence="ngModel" 
                required>
            <option *ngFor="let x of addressCountries" value="{{x}}">{{x}}</option>
        </select>
        <div *ngIf="(form.submitted || countryOfPermanentResidence.dirty) && !countryOfPermanentResidence.valid" class="error">
            Required
        </div>
    </div>
    
    <div class="form-group" *ngIf="!borrower.isUSCitizen">
        <label for="citizenshipCountry">Country of Citizenship</label>
        <select [(ngModel)]="borrower.countryOfCitizenship" id="citizenshipCountry" name="citizenshipCountry" class="form-control"
            #citizenshipCountry="ngModel" required>
            <option *ngFor="let x of citizenshipCountries" value="{{x}}">{{x}}</option>
        </select>
        <div *ngIf="(form.submitted || citizenshipCountry.dirty) && !citizenshipCountry.valid" class="error">
            Required
        </div>
    </div>

    <div *ngIf="borrower.hasBankruptcy" class="form-group">
        <gol-date-box [(ngModel)]="borrower.bankruptcyFilingDate" label="Bankruptcy Filing Date" golDateRangeValidator
            [max]="today" required name="filingDate" #filingDate="ngModel"></gol-date-box>
        <div *ngIf="form.submitted && filingDate.invalid" class="error">
            <p *ngIf="filingDate.errors['required']">A date is required</p>
            <p *ngIf="filingDate.errors['invalidDate']">That is not a valid date</p>
            <p *ngIf="filingDate.errors['precedesMin']">Please select a date after
                {{filingDate.errors['precedesMin'].toDateString()}}</p>
            <p *ngIf="filingDate.errors['exceedsMax']">Please select a date before
                {{filingDate.errors['exceedsMax'].toDateString()}}</p>
        </div>
    </div>

    <div *ngIf="borrower.hasBankruptcy" class="form-group">
        <gol-date-box [(ngModel)]="borrower.bankruptcyDischargeDate" label="Bankruptcy Discharge Date"
            golDateRangeValidator [min]="borrower.bankruptcyFilingDate" [max]="today" required name="dischargeDate"
            #dischargeDate="ngModel">
        </gol-date-box>
        <div *ngIf="form.submitted && dischargeDate.invalid" class="error">
            <p *ngIf="dischargeDate.errors['required']">A date is required</p>
            <p *ngIf="dischargeDate.errors['invalidDate']">That is not a valid date</p>
            <p *ngIf="dischargeDate.errors['precedesMin']">Please select a date after
                {{dischargeDate.errors['precedesMin'].toDateString()}}</p>
            <p *ngIf="dischargeDate.errors['exceedsMax']">Please select a date before
                {{dischargeDate.errors['exceedsMax'].toDateString()}}</p>
        </div>
    </div>
</div>