import { Component, OnInit } from '@angular/core';
import { AuthenticatedHttp } from '../../services/authenticated-http.service';
import { Message } from '../../models/message';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageCenterService } from '../../services/message-center.service';

@Component({
    selector: 'gol-message-center',
    templateUrl: 'message-center.component.html',
    styleUrls: ['./message-center.style.css']
})
export class MessageCenterComponent implements OnInit {
    messages;

    sanatizer: DomSanitizer;

    constructor(private http: AuthenticatedHttp, private sanitizer: DomSanitizer, private messageCenter: MessageCenterService) {

    }

    ngOnInit() {
        this.getMessages();
    }

    getMessages() {
        this.messageCenter.getMessages().then((resolve) => {
            if (resolve) {
                this.messages = this.groupBy(resolve, 'MessageHeader');
            }
        })
            .catch((error) => this.handleError(error));

    }

    groupBy(arr: any[], key: string) {
        return arr.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    showMessages(): boolean {
        return !!this.messages && Object.keys(this.messages).length > 0;
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
    }
}
