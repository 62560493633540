import { Component, Input, forwardRef, AfterViewInit, OnInit, AfterContentInit, ViewChildren, QueryList } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, ControlValueAccessor, FormControl, FormGroup, Validators } from '@angular/forms';
import { TopForm } from '../../top-form';

export const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

@Component({
    selector: 'gol-phone-component',
    templateUrl: 'phone.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PhoneComponent),
        multi: true
    }],
})
export class PhoneComponent implements ControlValueAccessor, AfterViewInit {
    @Input() form: TopForm;
    @Input() group: FormGroup;
    mask = phoneMask;

    @Input()
    Title = 'Phone Number';
    @Input()
    requiredErrorMessage = 'Required';
    @Input()
    isrequired = true;
    length = 14;

    phoneNumber: String;
    phoneCtrl = new FormControl();

    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            useGroup.addControl(this.Title, item.control);
        });
    }

    writeValue(value: any) {
        if (value !== this.phoneNumber) {
            this.phoneNumber = value;
        }

        if (this.Title === 'Phone Number') {
            this.phoneCtrl.setValidators([
                Validators.required,
                Validators.minLength(this.length),
                Validators.maxLength(this.length)]);
            if (this.group && this.group.get('phone')) {
                this.phoneCtrl.setValue(this.group.get('phone').value);
            } else {
                this.phoneCtrl.setValue(value);
            }
        }

        if (this.group && this.group.get('altphone')) {
            if (this.Title === 'Alternate Phone Number' && this.group.get('altphone') && this.group.get('altphone').value) {
                this.phoneCtrl.setValue(this.group.get('altphone').value);
            }
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    update(value: any) {
        if (this.onChangeCallback) {
            this.onChangeCallback(value);
        }
    }
}
