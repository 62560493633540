import { Component, OnInit } from '@angular/core';
import { VERSIONCONFIG } from '../../../version-config';
import { Router } from '@angular/router';

@Component({
    selector: 'gol-change-password-confirmation',
    template: '<div class="b2c-int-container"></div>',
    styleUrls: ['./change-password-confirmation.component.css']

})
export class ChangePasswordConfirmationComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit() {
        if (window.top.location !== window.location) {
            window.top.location.replace(`/${VERSIONCONFIG.redirectPrefix}dashboard/preferences/password-confirmation`);
        } else {
            this.router.navigate(['/dashboard/preferences/password-confirmation']);
        }
    }
}
