<div class="row">
    <div class="col-sm-3"></div>
    <div class="raised registrationStage white col-sm-6">
        <h2>Account Security Questions</h2>
        <form [formGroup]="form" (ngSubmit)="continue()" novalidate>
            <div [formGroup]="questionsGroup">
                <div *ngFor="let securityItem of registree.securityQuestions; let i=index">
                    <div class="form-group">
                        <label>Security Question {{i+1}}</label>
                        <select name="securityItemQuestion" class="form-control"
                            (change)="changeSecurityQuestion(securityItem, $event.target.value)"
                            [formControlName]="'questionControl'+i" [(ngModel)]="securityItem.question">
                            <option value="" selected>Select Security Question</option>
                            <option *ngFor="let question of possibleSecurityQuestions" value="{{question}}">{{question}}
                            </option>
                        </select>
                        <div class="error"
                            *ngIf="(form.submitted || questionsGroup?.controls['questionControl'+i].dirty) && questionsGroup?.controls['questionControl'+i].invalid">
                            <p>Required</p>
                        </div>
                        <input name="answer" [formControlName]="'answerControl'+i" class="form-control"
                            [(ngModel)]="securityItem.answer" />
                        <div class="error"
                            *ngIf="(form.submitted || questionsGroup?.controls['answerControl'+i].dirty) && questionsGroup?.controls['answerControl'+i].invalid">
                            <p>Required</p>
                        </div>
                    </div>
                </div>
                <div class="error"
                    *ngIf="(form.submitted || questionsGroup.dirty) && questionsGroup.invalid && questionsGroup.errors">
                    <p>Each security question must be unique.</p>
                </div>
            </div>
            <div class="form-group error" *ngIf="serverError">
                {{serverError}}
            </div>
            <div class="buttonContainer">
                <button class="btn btn-primary" type="submit">Continue</button>
                <button type="button" class="btn btn-secondary" (click)="registrationService.cancel()">Cancel</button>
                <button type="button" class="btn btn-secondary back-btn"
                    (click)="registrationService.back()">Back</button>
                <div class="clear"></div>
            </div>
        </form>
    </div>
</div>