import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { Question } from '../models/wizard/question';
import { SimpleQuestion } from '../models/wizard/simple-question';
import { ComplexQuestion } from '../models/wizard/complex-question';
import { CONFIG } from '../../environments/environment';
import { validateNumberFactory } from '../form/validation/number-validator.directive';
import { totalIncomeMaxNLS } from '../form/financial-validation';
import { UtilitiesService } from './utilities.service';

@Injectable()
export class WizardQuestionService implements IWizardHttpService {
    private resourceUrl = CONFIG.apiBaseUri + 'WizardQuestions';
    constructor(private http: HttpClient, private utilities: UtilitiesService) { }
    getQuestions() {
        return new Promise<Question[]>((resolve, reject) => {
            const financialValidators = [validateNumberFactory(false, 2, 0, totalIncomeMaxNLS, this.utilities)];

            const testData = [];
            let simple = new SimpleQuestion();
            simple.fieldType = 'Assets';
            simple.length = 'shortTerm';
            simple.description = 'Cash, checking, and savings';
            simple.questionText = 'How much do you have in cash, checking and savings?';
            simple.example = '(eg. Checking account, savings account, money market account, certificate of deposit account)';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Assets';
            simple.length = 'shortTerm';
            simple.description = 'Accounts receivable';
            simple.questionText = 'Does anyone owe you money (Accounts Receivable)? If so, how much?';

            // tslint:disable-next-line: max-line-length
            simple.example = '(eg. Crop Insurance Claim, Deferred Grain Income, Custom Work Income, other amounts owed to you and will be collected within the next 12 months)';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Assets';
            simple.length = 'shortTerm';
            simple.description = 'Upcoming crop investment';
            simple.questionText = 'What is your current investment in the upcoming crop?';
            simple.example = '(eg. Purchased Seed, Chemicals, Fertilizer, Rent, Fuel)';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Assets';
            simple.length = 'shortTerm';
            simple.description = 'Crop inventory';
            simple.questionText = 'What is the value of your crop inventory?';
            simple.example = '(eg. Grain in storage, cotton bales, silage, hay)';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Assets';
            simple.length = 'shortTerm';
            simple.description = 'Market livestock';
            simple.questionText = 'What is the value of your market livestock?';
            simple.example = '(eg. Cattle, Swine, and other livestock owned to be sold within the next 12 months)';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Liabilities';
            simple.length = 'shortTerm';
            simple.description = 'Market livestock loan';
            simple.questionText = 'How much do you owe against your market livestock that is due within 1 year?';
            simple.customValidators = financialValidators;
            simple.example = '';
            testData.push(simple);

            let complex = new ComplexQuestion();
            complex.length = 'intermediate';
            complex.description = 'Breeding and dairy livestock';
            complex.liabilityDescription = 'Breeding and dairy livestock payments';
            complex.assetQuestionText = 'What is your breeding or dairy livestock worth?';
            complex.assetExample = '(eg. Dairy Cattle, Cow/Calf pairs, Bulls, Sows, Boars, and other Breeding Livestock)';
            complex.totalLiabilityQuestionText = 'How much do you owe on your breeding or dairy livestock? ';
            complex.totalLiabilityExample = '';
            complex.shortTermLiabilityQuestionText = 'What are your total breeding or dairy livestock payments over the next 12 months?';
            complex.shortTermLiabilityExample = '';
            complex.shortTermLiabilityCustomValidators = financialValidators;
            complex.totalLiabilityCustomValidators = financialValidators;
            complex.assetCustomValidators = financialValidators;
            testData.push(complex);

            complex = new ComplexQuestion();
            complex.length = 'intermediate';
            complex.description = 'Machinery and equipment';
            complex.liabilityDescription = 'Machinery and equipment payments';
            complex.assetQuestionText = 'What is your machinery and equipment worth?';
            complex.assetExample = '(eg. Farm Machinery, Trucks, Trailers, Vehicles)';
            complex.totalLiabilityQuestionText = 'How much do you owe on your machinery and equipment?';
            complex.totalLiabilityExample = '';
            complex.shortTermLiabilityQuestionText = 'What are your total machinery and equipment payments over the next 12 months?';
            complex.shortTermLiabilityExample = '';
            complex.shortTermLiabilityCustomValidators = financialValidators;
            complex.totalLiabilityCustomValidators = financialValidators;
            complex.assetCustomValidators = financialValidators;
            testData.push(complex);

            complex = new ComplexQuestion();
            complex.length = 'term';
            complex.description = 'Buildings & improvements';
            complex.liabilityDescription = 'Building & improvement payments';
            complex.assetQuestionText = 'What are your buildings and improvements worth?';
            complex.assetExample = '(eg. Grain Bins, Machine Sheds, Livestock Barns, Irrigation Pivots)';
            complex.totalLiabilityQuestionText = 'How much do you owe on your buildings and improvements?';
            complex.totalLiabilityExample = '';
            complex.shortTermLiabilityQuestionText = 'What are your total buildings and improvements payments over the next 12 months?';
            complex.shortTermLiabilityExample = '';
            complex.shortTermLiabilityCustomValidators = financialValidators;
            complex.totalLiabilityCustomValidators = financialValidators;
            complex.assetCustomValidators = financialValidators;
            testData.push(complex);

            complex = new ComplexQuestion();
            complex.length = 'term';
            complex.description = 'Real estate';
            complex.liabilityDescription = 'Real estate payments';
            complex.assetQuestionText = 'What is your real estate worth?';
            complex.assetExample = '(eg. Farm Land, Personal Residence)';
            complex.totalLiabilityQuestionText = 'How much do you owe on your real estate?';
            complex.totalLiabilityExample = '';
            complex.shortTermLiabilityQuestionText = 'What are your total real estate payments over the next 12 months?';
            complex.shortTermLiabilityExample = '';
            complex.shortTermLiabilityCustomValidators = financialValidators;
            complex.totalLiabilityCustomValidators = financialValidators;
            complex.assetCustomValidators = financialValidators;
            testData.push(complex);

            simple = new SimpleQuestion();
            simple.fieldType = 'Assets';
            simple.length = 'term';
            simple.description = 'Other value';
            simple.questionText = 'Do you own anything else that should be included? If so, what is the total value?';
            simple.example = '(eg. Ownership Equity in other entities not included on this application, Retirement Accounts)';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Liabilities';
            simple.length = 'shortTerm';
            simple.description = 'Operating loan balance';
            simple.questionText = 'What is the current balance of your operating loan?';
            simple.example = '';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Liabilities';
            simple.length = 'shortTerm';
            simple.description = 'Other crop loans';
            simple.questionText = 'What is the current balance of any other crop input loan?';
            simple.example = '';
            simple.customValidators = financialValidators;
            testData.push(simple);

            simple = new SimpleQuestion();
            simple.fieldType = 'Liabilities';
            simple.length = 'term';
            simple.description = 'Other debt';
            simple.questionText = 'Do you have any other debt? If so, how much?';
            simple.example = '';
            simple.customValidators = financialValidators;
            testData.push(simple);

            resolve(testData);

        });
        // return this.http.get(this.resourceUrl)
        //     .toPromise()
        //     .then(response => response.json() as Question[])
        //     .catch(this.handleError);
    }
    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}

export interface IWizardHttpService {
    getQuestions(): Promise<Question[]>;
}

