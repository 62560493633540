import { Component, Input } from '@angular/core';

@Component({
    selector: 'gol-loading-feedback',
    templateUrl: './loading-feedback.component.html',
    styleUrls: ['./loading-feedback.component.css']
})
export class LoadingFeedbackComponent {
    @Input() inline: boolean;
}
