import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Commitment } from '../../../models/accounts/commitment';
import { SubCommitment } from '../../../models/accounts/sub-commitment';
import { Amendment } from '../../../models/accounts/amendment';
import { DealerHeader } from '../../../models/accounts/dealer-header';
import { AccountHttpService } from '../../../services/account-http.service';
import { VendorService } from '../../../services/vendor.service';
import { SelectItem } from 'primeng/api';
import { CurrencyPipe } from '@angular/common';
import numberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
    selector: 'gol-amendment',
    templateUrl: 'amendment.component.html',
    styleUrls: ['amendment.component.css']
})
export class AmendmentComponent implements OnInit {

    loading = false;
    error = false;
    preexisting = false;
    errorMessage = '';
    account: Commitment = null;
    amendment: Amendment = null;
    vendors: SelectItem[];

    form: FormGroup;
    formSubCommitment: SubCommitment;
    formSubmitted: boolean;
    selectedSubCommitment: SubCommitment;
    updatingSubCommitment: boolean;
    subCommitmentToDelete: SubCommitment;
    deletingSubCommitment: boolean;
    submittingAmendment: boolean;
    waitingOnRequest: boolean;
    successfulSubmission: boolean;
    selectedVendor: number;
    showForm: boolean;
    changeSummary: string[];

    currencyMask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });

    constructor(private route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        private accountService: AccountHttpService,
        private vendorService: VendorService) { }

    ngOnInit(): void {
        this.getAccount();
        window.scrollTo(0, 0);

        this.form = this.fb.group({
            Dealer: ['', <any>Validators.required],
            CreditLimit: ['', <any>Validators.required]
        });
        this.formSubCommitment = new SubCommitment();
        this.formSubCommitment.Dealer = null;

        this.vendorService.GetVendors()
            .then(() => {
                this.setVendorList();
            });
    }

    setVendorList() {
        if (this.amendment) {
            this.vendorService.filterSelectedVendors(this.amendment.SubCommitments.map(s => s.Dealer.RowId));
        }
        this.vendors = this.vendorService.filteredVendors.map((vendor) => {
            return { label: vendor.getShortName(), value: vendor.rowId };
        });
        this.vendors.unshift({ label: 'Select a Program', value: null });
    }

    getAccount() {
        this.loading = true;
        this.accountService.getCurrentAccount(this.route.snapshot.parent)
            .then(act => this.account = act)
            .then(() => this.accountService.doesAccountHaveAmendment(this.account.LoanNumber))
            .then(result => {
                this.preexisting = result;
                if (!this.preexisting) {
                    this.amendment = new Amendment(this.account);
                }
            })
            .catch(err => {
                this.errorMessage = 'There was an issue pulling this account\'s details: ' + err;
                this.error = true;
            })
            .then(() => this.loading = false);
    }

    onRowSelect(event: any) {
        this.formSubCommitment = new SubCommitment(this.selectedSubCommitment);
        this.selectedVendor = this.formSubCommitment.Dealer.RowId;
        this.updatingSubCommitment = true;
        this.showForm = true;
    }

    addSub() {
        this.error = false;
        this.formSubmitted = true;
        if (this.form.valid) {

            if (this.updatingSubCommitment) {
                const match = this.amendment.SubCommitments.findIndex(s => s.Dealer.RowId === this.selectedVendor);
                this.amendment.SubCommitments[match].CreditLimit = this.formSubCommitment.CreditLimit;
            } else {
                const selected = this.vendorService.vendors.find(vendor => vendor.rowId === this.selectedVendor);
                this.formSubCommitment.Dealer = new DealerHeader({
                    RowId: selected.rowId,
                    Name: selected.name,
                    DealerNumber: selected.dealerNumber
                });
                if (this.amendment.SubCommitments.find(
                    sc => sc.Dealer.DealerNumber === this.formSubCommitment.Dealer.DealerNumber) === undefined) {
                    this.formSubCommitment.SubCommitmentNumber = 'TBD';
                    this.formSubCommitment.Index = this.amendment.SubCommitments
                        .map((sc) => sc.Index)
                        .reduce((psc, sc) => sc > psc ? sc : psc) + 1;
                    this.amendment.SubCommitments.push(this.formSubCommitment);
                    this.setVendorList();
                } else {
                    this.errorMessage =
                        'The dealer (${this.formSubCommitment.Dealer.Name}) already exists on your account.  Please edit it directly.';
                }
            }
            this.toggleForm();
        }
    }

    toggleForm() {
        this.showForm = !this.showForm;
        if (!this.showForm) {
            this.cancelSub();
        }
    }

    cancelSub() {
        this.formSubCommitment = new SubCommitment();
        this.formSubCommitment.Dealer = null;
        this.selectedVendor = null;
        this.updatingSubCommitment = false;
        this.selectedSubCommitment = null;
        this.formSubmitted = false;
    }

    deleteSubCommitment(sub: SubCommitment) {
        this.errorMessage = '';
        this.subCommitmentToDelete = sub;
        this.deletingSubCommitment = true;
    }

    confirmDeleteSubCommitment() {
        const match = this.amendment.SubCommitments.findIndex(
            s => s.Dealer.DealerNumber === this.subCommitmentToDelete.Dealer.DealerNumber);
        this.amendment.SubCommitments.splice(match, 1);
        this.setVendorList();
        this.deletingSubCommitment = false;
    }

    cancelDeleteSubCommitment() {
        this.deletingSubCommitment = false;
    }

    submitAmendment() {
        this.errorMessage = '';
        this.submittingAmendment = true;
        this.changeSummary = [];
        const pipe = new CurrencyPipe('en');

        this.account.SubCommitments.forEach(sc => {
            const modifiedsc = this.amendment.SubCommitments.find(fsc => sc.Dealer.DealerNumber === fsc.Dealer.DealerNumber);
            if (modifiedsc) {
                if (sc.CreditLimit > modifiedsc.CreditLimit) {
                    this.changeSummary.push(
                        ''.concat('Decrease credit limit for "', sc.Dealer.Name, '" from ',
                            pipe.transform(sc.CreditLimit, 'USD', 'symbol', '1.2-2'), ' to ',
                            pipe.transform(modifiedsc.CreditLimit, 'USD', 'symbol', '1.2-2')));
                } else if (sc.CreditLimit < modifiedsc.CreditLimit) {
                    this.changeSummary.push(
                        ''.concat('Increase credit limit for "', sc.Dealer.Name, '" from ',
                            pipe.transform(sc.CreditLimit, 'USD', 'symbol', '1.2-2'), ' to ',
                            pipe.transform(modifiedsc.CreditLimit, 'USD', 'symbol', '1.2-2')));
                }
            } else {
                this.changeSummary.push('Remove program "' + sc.Dealer.Name + '"');
            }
        });

        const newSubs = this.amendment.SubCommitments.filter(
            fsc => this.account.SubCommitments.find(sc => sc.Dealer.DealerNumber === fsc.Dealer.DealerNumber) === undefined);
        newSubs.forEach(
            sc => this.changeSummary.push('Add program "' + sc.Dealer.Name + '" with ' +
                pipe.transform(sc.CreditLimit, 'USD', 'symbol', '1.2-2')));

        if (this.account.getTotalLoanAmount() !== this.amendment.getTotalLoanAmount()) {
            this.changeSummary.push('Total loan amount changing from '.concat(
                pipe.transform(this.account.getTotalLoanAmount(), 'USD', 'symbol', '1.2-2'),
                ' to ',
                pipe.transform(this.amendment.getTotalLoanAmount(), 'USD', 'symbol', '1.2-2')));
        }
    }

    confirmSubmitAmendment() {
        this.error = false;
        this.waitingOnRequest = true;
        this.accountService.submitAmendment(this.amendment)
            .then((res) => {
                this.waitingOnRequest = false;
                if (res == null || res === '') {
                    this.successfulSubmission = true;
                } else {
                    this.errorMessage = res;
                    this.submittingAmendment = false;
                }
            });
    }

    cancelSubmitAmendment() {
        this.submittingAmendment = false;
    }

    toNumber(value: string): number {
        if (value == null || value === undefined) { return 0.00; }
        const convert = +(this.clearmask(value));
        if (Number.isNaN(convert)) {
            return null;
        }
        return convert;
    }

    clearmask(str: string): string {
        if (!str || typeof str !== 'string') {
            return str;
        }
        const newstr = str.replace(/\//g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/,/g, '').replace('$', '');
        return newstr;
    }

    getFormHeader(): string {
        if (this.formSubCommitment && this.formSubCommitment.SubCommitmentNumber) {
            return 'Update ' + this.formSubCommitment.Dealer.Name + ' (' + this.formSubCommitment.SubCommitmentNumber + ')';
        } else {
            return 'New Program';
        }
    }
}
