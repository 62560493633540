import { Component } from '@angular/core';
import { FormGroup, FormControl, NgForm, NgModel } from '@angular/forms';
import { Router } from '@angular/router';

import { Application } from '../../models/application';
import { ApplicationType } from '../../models/application-type';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { Person } from '../../models/person';
import { TopForm } from '../top-form';
import { validateCheckboxFactory } from '../validation/checkbox-required.directive';
import { UtilitiesService } from '../../services/utilities.service';
import { UserService } from '../../services/user.service';
import { HistoryService } from '../../services/history.service';
import { NavigationOptions } from '../../models/navigation-options';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-summary-and-sign',
    templateUrl: 'summary-and-sign.component.html',
    styleUrls: ['../styles_form.css', 'waiting.css', 'summary-and-sign.component.css', '../balance-sheet/financial-table.css']
})
export class SummaryAndSignComponent extends AbstractFormPage {
    constructor(currentAppSvc: CurrentApplicationService,
        router: Router,
        utilities: UtilitiesService,
        history: HistoryService,
        notificationService: NotificationService,
        private userService: UserService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'sign';
        this.initPage();
    }
    recaptchaToken = '';
    showTandC = false;
    form: TopForm = new TopForm();
    ownership: FormControl;
    finances: FormControl;
    terms: FormControl;
    waiting = false;
    docusignError = false;
    selectedOwner: Person;
    selectedBorrower: Person;
    printing = false;
    isAnonymous = false;
    hasSubmitErrors = false;

    get ownersToDisplay(): Person[] {
        let owners: Person[] = [];
        if(this.application && this.application.entity && this.application.entity.ultimateBeneficialOwners){
            owners = owners.concat(this.application.entity.ultimateBeneficialOwners);
        }
        if(this.application && this.application.entity && this.application.entity.controllingParties){
            owners = owners.concat(this.application.entity.controllingParties);
        }
        return owners;
    }

    afterInitPage() {
        this.isAnonymous = this.userService.GetUser().isAnonymous;
        this.finances = new FormControl(false, validateCheckboxFactory());
        this.form.addControl('finances', this.finances);
        if (this.application.entity) {
            this.ownership = new FormControl(false, validateCheckboxFactory());
            this.form.addControl('ownership', this.ownership);
        }
        if (this.isAnonymous) {
            this.terms = new FormControl(false, validateCheckboxFactory());
            this.form.addControl('terms', this.terms);
        }

        this.selectedOwner = this.ownersToDisplay[0];
        this.selectedBorrower = this.application.borrowers[0];
    }

    submit() {
        this.form.submitted = true;
        if (this.form.valid && (!this.isAnonymous || this.recaptchaToken !== '')) {
            if (!this.isAnonymous) {
                const currentDateTime = new Date();
                this.application.onlineAgreementAcceptanceDate = currentDateTime;
            }
            this.waiting = true;
            const newPage = NavigationOptions.AppPages(this.application, true)[this.pageNumber + 1];
            this.application.currentPage = newPage.alias;
            this.application.maxPageReached = newPage.alias;
            this.application.submit(this.recaptchaToken)
                .then((url) => {
                    window.location.href = url;
                })
                .catch(reason => {
                    if(reason.includes('Recaptcha')){
                        this.notificationService.push({
                            severity: 'error',
                            summary: 'Could not verify recaptcha token.',
                            detail: reason,
                        });
                    } else if(reason.includes('Docusign')){
                        this.docusignError = true;
                        this.notificationService.push({
                            severity: 'error',
                            summary: 'Docusign Error',
                            detail: reason,
                        });
                    } else{
                       this.notificationService.push({
                            severity: 'error',
                            summary: 'There was an error submitting the application.',
                            detail: reason,
                        });
                    }
                    this.hasSubmitErrors = true;
                    this.waiting = false;
                });
        } else {
            this.scrollToError();
        }
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string) {
        // There isn't anything to validate on this page, can navigate away without form validation.
        return new Promise<boolean>((resolve) => {
            const comp = this;
            comp.application.currentPage = newPageAlias;
            return resolve(comp.save());
        });
    }

    agreementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.application.onlineAgreementAcceptanceDate = acceptedDateTime;
    }

    displayDate(date: Date): string { //  mm/dd/yyyy
        if (!date) { return ''; }
        const mm = (date.getMonth() + 1).toString();
        const dd = date.getDate().toString();
        return [!mm[1] ? '0' : '', mm, '/', !dd[1] ? '0' : '', dd, '/', date.getFullYear()].join('');
    }

    showEntity(): boolean {
        return this.application.entity && this.application.appType == ApplicationType.Entity;
    }

    getOwnerAddress(person: Person) {
        if (person.physicalAddress.line1 === '' || person.physicalAddress.line1 == null) {
            return person.mailingAddress;
        } else {
            return person.physicalAddress;
        }
    }

    recaptchaResolved(token: string) {
        this.recaptchaToken = token;
    }

    entityIsOther(app) {
        return app.ownersList && app.entity.signer && !(app.ownersList.findIndex((o) => o === app.entity.signer) >= 0);
    }

    printApp() {
        this.printing = true;
        this.application.print().then(blob => {
            this.printing = false;
            this.closePrintDialog();
        }, err => {
            this.notificationService.push({
            severity: 'error',
                summary: 'Unable to generate PDF',
                detail: 'Unable to generate PDF, see log for details.'
            });
            this.printing = false;
            this.closePrintDialog();
        });
    }

    closePrintDialog() {
        this.docusignError = false;
    }
}
