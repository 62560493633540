import { Component, Input, AfterViewInit, ViewChildren, QueryList, forwardRef } from '@angular/core';
import { IItemFactory } from './autocomplete.component';
import { NgModel, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TopForm } from '../../top-form';
import { HelpTipComponent } from '../help-tip.component';
import { CorporationItemFactory } from './CorporationItemFactory';

export const CORP_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CorporationComponent),
    multi: true
};

@Component({
    selector: 'gol-corporation-component',
    template: `<div class="form-group" >
                    <label for="items">{{DisplayTitle}}</label>&nbsp;<gol-help-tip>Select an option from the list or type in your entity type if it is not listed.</gol-help-tip>
                    <gol-autocomplete [DisplayTitle]="DisplayTitle" [itemFactory]=itemFactory [maxSize]="4" [form]="form" [group]="group" [value]="corporationType" [update]="getUpdate()" ></gol-autocomplete>
                </div>
            `,
    styles: ['.form-group { position: relative }'],
    providers: [CORP_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CorporationComponent implements AfterViewInit, ControlValueAccessor {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;
    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() DisplayTitle = 'Entity Type';

    @Input()
    Title = 'Corporation';
    corporationType: string;

    itemFactory: IItemFactory = new CorporationItemFactory;

    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};
    ngAfterViewInit() {
        const useGroup: FormGroup = this.group || this.form;
        this.inputChildren.forEach((item) => {
            useGroup.addControl(this.Title, item.control);
        });
    }

    writeValue(value: any) {
        if (value !== this.corporationType) {
            this.corporationType = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
    getUpdate() {
        const corpComp = this;
        return function (value) {
            corpComp.corporationType = value;
            corpComp.onChangeCallback(value);
        };
    }
}


