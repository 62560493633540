<div class="financial-wizard-summary">
    <div *ngIf="finances" class="panel">
        <div class="characterTabs group">
            <a *ngFor="let character of characters" (click)="selectCharacter(character)"
                [class.active]="selected.character === character" title="{{character}}">{{character}}</a>
            <a *ngIf="characters.length > 1" (click)="selectCharacter(null)"
                [class.active]="selected.index === -1">Total</a>
        </div>
        <div *ngFor="let financial of [finances.assets, finances.liabilities]"
            class="{{financial.type.toLowerCase()}} category">
            <h2>{{financial.type}}</h2>
            <ul class="categories group">
                <li *ngFor="let category of financial.categories" class="group">
                    <div *ngIf="category.questions && category.questions.length > 0">
                        <h3>{{category.title}}</h3>
                        <ul class="items nav-on-click">
                            <li *ngFor="let question of category.questions" (click)="selectQuestion(question.index)"
                                class="group">
                                <label><i class="fa fa-pencil" title="Click to edit"></i>
                                    {{question.description}}</label>
                                <span
                                    *ngIf="selected.index !== -1">{{question.values[selected.index] | currency:'USD':'symbol':'1.2-2'}}</span>
                                <span
                                    *ngIf="selected.index === -1">{{sum(question.values) | currency:'USD':'symbol':'1.2-2'}}</span>
                            </li>
                        </ul>
                        <span *ngIf="selected.index !== -1"
                            class="subCategoryTotal">{{(category.total[selected.index] || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                        <span *ngIf="selected.index === -1"
                            class="subCategoryTotal">{{(sum(category.total) || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                </li>
            </ul>
            <div class="categoryTotal group" *ngIf="selected.index !== -1">
                <label>Total {{financial.type}}</label>
                <span
                    [class.error]="financesOutsideOfMax((financial.total[selected.index] || 0))">{{(financial.total[selected.index] || 0) | currency:'USD':'symbol':'1.2-2'}}</span><br />
                <span *ngIf="financesOutsideOfMax((financial.total[selected.index] || 0))" class="error">Amount must be
                    less than {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
            </div>
            <div class="categoryTotal group" *ngIf="selected.index === -1">
                <label>Total {{financial.type}}</label>
                <span
                    [class.error]="financesOutsideOfMax((sum(financial.total) || 0))">{{(sum(financial.total) || 0) | currency:'USD':'symbol':'1.2-2'}}</span><br />
                <span *ngIf="financesOutsideOfMax((sum(financial.total) || 0))" class="error">Amount must be less than
                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
            </div>
        </div>
    </div>
    <div class="panel">
        <div class="category">
            <h2>Net Worth</h2>
            <ul class="items net-worth">
                <li>
                    <label>Total Assets:</label>
                    <span>{{totalAssets() | currency:'USD':'symbol':'1.2-2'}}</span>
                </li>
                <li>
                    <label>Total Liabilities:</label>
                    <span>{{totalLiabilities() | currency:'USD':'symbol':'1.2-2'}}</span>
                </li>
            </ul>
            <div class="categoryTotal group">
                <label>Total</label>
                <span [class.green]="netWorth() >= 0"
                    [class.red]="netWorth() < 0">{{netWorth() | currency:'USD':'symbol':'1.2-2'}}</span>
            </div>
        </div>

        <div class="category">
            <h2>Total Income</h2>
            <ul class="items">
                <li>
                    <label>Gross Crop:</label>
                    <span>{{application.income.grossCropIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                </li>
                <li>
                    <label>Gross Livestock:</label>
                    <span>{{application.income.grossLivestockIncome  | currency:'USD':'symbol':'1.2-2'}}</span>
                </li>
                <li>
                    <label>Other Farm:</label>
                    <span>{{application.income.otherFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                </li>
            </ul>
            <div class="categoryTotal group">
                <label>Total</label>
                <span class="green">{{application.income.totalFarmIncome | currency:'USD':'symbol':'1.2-2'}}</span>
                <span *ngIf="financesOutsideOfMax(application.income.totalFarmIncome)" class="error">Amount must be less
                    than {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
            </div>
        </div>
    </div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>