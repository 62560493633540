<div class="preference-group group raised-card">
    <h2>Update Contact Information</h2>
    <gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
    <div *ngIf="!loading && !contactInfoChangeComplete">
        <form [formGroup]="contactInfoForm" class="preference-group group" (ngSubmit)="contactInfoSubmit()" novalidate>
            <div class="well">
                <legend>
                    <span class="spanTitle">
                        Mailing Address
                    </span>
                </legend>
                <p>{{contactDetails.MailingAddress.line1}}</p>
                <p *ngIf="contactDetails.MailingAddress.line2?.length > 0">{{contactDetails.MailingAddress.line2}}</p>
                <p>{{contactDetails.MailingAddress.city}}, {{contactDetails.MailingAddress.state}}
                    {{contactDetails.MailingAddress.zipcode}}</p>
                <p-checkbox *ngIf="sameAsPhysical" [disabled]="true" [(ngModel)]="sameAsPhysical"
                    [ngModelOptions]="{standalone: true}" label="Same as physical address" binary="true"
                    (click)="setPhysicalAddressValidators()"></p-checkbox>
            </div>

            <div *ngIf="!sameAsPhysical" class="well">
                <legend>
                    <span class="spanTitle">
                        Physical Address
                    </span>
                </legend>
                <p>{{contactDetails.PhysicalAddress.line1}}</p>
                <p *ngIf="contactDetails.PhysicalAddress.line2?.length > 0">{{contactDetails.PhysicalAddress.line2}}</p>
                <p>{{contactDetails.PhysicalAddress.city}}, {{contactDetails.PhysicalAddress.state}}
                    {{contactDetails.PhysicalAddress.zipcode}}</p>
            </div>
            <div class="well">
                <legend>
                    <span class="spanTitle">
                        Phone Numbers
                    </span>
                </legend>
                <div class="row phonesHeader hidden-xs">
                    <div class="col-sm-6">
                        Phone Number
                    </div>
                    <div class="col-sm-3">
                        Primary
                    </div>
                    <div class="col-sm-3">
                        Mobile
                    </div>
                </div>
                <div class="row phones" *ngFor="let phoneNumber of contactDetails?.PhoneNumbers; let i=index">
                    <div class="col-sm-6 phone">
                        {{formatPhoneNumber(phoneNumber.PhoneNumber)}}
                    </div>
                    <div class="col-sm-3">
                        <mat-radio-group class="mat-radio-group" [(ngModel)]="contactDetails.PrimaryPhoneNumber"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="mat-radio-button" value="{{phoneNumber.PhoneNumber}}">
                            </mat-radio-button>
                        </mat-radio-group>
                        <span class="visible-xs">(primary)</span>
                    </div>
                    <div class="col-sm-3">
                        <p-checkbox [(ngModel)]="phoneNumber.IsMobile" [ngModelOptions]="{standalone: true}"
                            binary="true"></p-checkbox>
                        <span class="visible-xs">(mobile)</span>
                    </div>
                </div>
                <div class="row phones newPhoneSection">

                    <div class="col-sm-6 phone" *ngIf="addingNewPhone">
                        <div class="form-group">
                            <label>New Phone Number</label>
                            <input name="Phone" class="form-control" formControlName="newPhoneCtrl"
                                [(ngModel)]="newPhone.PhoneNumber" placeholder="Phone Number"
                                [textMask]="{mask: phoneMask, guide:false}" maxlength="14" minlength="14" required />
                            <div class="alert alert-danger" *ngIf="(contactInfoForm.submitted || contactInfoForm.controls['newPhoneCtrl'].dirty) &&
                                contactInfoForm.controls['newPhoneCtrl'].invalid">
                                <div *ngIf="contactInfoForm.controls['newPhoneCtrl'].hasError('required')">Required
                                </div>
                                <div *ngIf="contactInfoForm.controls['newPhoneCtrl'].hasError('minlength') ||
                                    contactInfoForm.controls['newPhoneCtrl'].hasError('maxlength')">
                                    Phone Number is invalid</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3" *ngIf="addingNewPhone">
                        <div class="phonesHeader hidden-xs">Primary</div>
                        <mat-radio-group class="mat-radio-group" [(ngModel)]="contactDetails.PrimaryPhoneNumber"
                            [ngModelOptions]="{standalone: true}">
                            <mat-radio-button class="mat-radio-button" value="{{newPhone.PhoneNumber}}">
                            </mat-radio-button>
                        </mat-radio-group>
                        <span class="visible-xs">(primary)</span>
                    </div>
                    <div class="col-sm-3" *ngIf="addingNewPhone">
                        <div class="phonesHeader hidden-xs">Mobile</div>
                        <p-checkbox [(ngModel)]="newPhone.IsMobile" [ngModelOptions]="{standalone: true}" binary="true">
                        </p-checkbox>
                        <span class="visible-xs">(mobile)</span>
                    </div>
                    <button type="button" class="btn btn-secondary" (click)="addPhone(false)"
                        *ngIf="addingNewPhone">Cancel</button>
                    <button *ngIf="!addingNewPhone" type="button" class="btn btn-secondary"
                        (click)="addPhone(true)">Add</button>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="updateInfoError">
                {{updateInfoError}}
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="contactInfoForm.invalid">Update</button>
            <button type="button" class="btn btn-secondary" (click)="getUserData()">Cancel</button>
        </form>
    </div>
    <div *ngIf="contactInfoChangeComplete">
        <p>Your account was successfully updated!</p>
        <button type="button" class="btn btn-primary" (click)="closeComplete()">OK</button>
    </div>
</div>