<gol-loading-feedback *ngIf="loading"></gol-loading-feedback>
<div *ngIf="!loading" class="raised-card">
    <h2>In progress</h2>
    <div class="raised-card" *ngFor="let aa of workflows">
        <div class="row">
            <div class="col-md-9">
                <h2>{{aa.FirstName + " " + aa.LastName}}</h2>
                <p>Status: {{aa.Status}}</p>
            </div>
            <div class="col-md-3">
                <div><button type="button" class="btn btn-success raised" *ngIf="showSignButton(aa)"
                        (click)="signButtonClick(aa)"><i class="glyphicon fa fa-check"></i> Sign</button></div>
                <div><button type="button" class="btn btn-danger raised" *ngIf="showCancelButton(aa)"
                        (click)="cancelButtonClick(aa)"><i class="glyphicon fa fa-stop"></i> Cancel</button></div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
</div>
<p-dialog [(visible)]="showConfirmation" modal="true" (onAfterHide)="closeDialog()">
    <ng-template pTemplate="header">
        Please Confirm
    </ng-template>
    Are you sure you want to cancel this request?
    <div>
        <button class="btn btn-secondary bottom" (click)="closeDialog()">No</button>
        <button class="btn btn-primary bottom" (click)="cancelConfirmButtonClick()">Yes</button><br />
    </div>
</p-dialog>