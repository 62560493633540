<div class="container stage main_container">
    <gol-side-nav selectedAlias="{{pageAlias}}" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div *ngIf="preApproved" class="col-sm-12">
            <h1 class="row preapprovalBanner">
                Pre-Screened Offer Acceptance
            </h1>
        </div>
        <div class="col-sm-7">
            <h1>Program Information</h1>
        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application">
                <p-accordion (onClose)="tabClose($event)" (onOpen)="tabOpen($event)">
                    <p-accordionTab *ngFor="let b of application.vendors; let i = index" [selected]="isOpen[i]">
                        <ng-template pTemplate="header">
                            <div class="headerContainer" [ngClass]="setClass(form.submitted, i)">
                                <span *ngIf="!showDisplayName(b)" class="new">(new)</span>
                                <span *ngIf="showDisplayName(b)">{{b.vendorName}}</span>
                                <span class="actionicon" (click)="delete(i, $event)">
                                    <i class="glyphicon fa fa-trash-o"></i> Delete
                                </span>
                            </div>
                        </ng-template>
                        <gol-vendor-programs [vendor]="b" [application]="application" [form]="form"
                            [group]="formGroups[i]">
                        </gol-vendor-programs>
                    </p-accordionTab>
                </p-accordion>
                <gol-credit-requirements [creditLimit]="application.totalCreditLimit()" [entity]="application.entity">
                </gol-credit-requirements>
                <div class="totalCredit">
                    Total Credit Requested: {{application.totalCreditLimit() | currency:'USD'}}
                </div>
                <div class="buttonContainer" fxLayout="column" fxLayoutAlign="center center">
                    <button type="button" class="btn btn-primary" (click)="add()">Add Program</button>
                    <div>
                    <button *ngIf="showBack" class="btn btn-secondary back-btn" type="button"
                        (click)="previous()">Back</button>
                    <button type="submit" class="btn btn-secondary">Continue</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-sm-1"></div>
        <gol-contact-component></gol-contact-component>
    </div>

    <div *ngIf="showModal" class="modal-wrapper">
        <div class="modal-content">
            <div>
                You have changed the values associated with your pre-screen offer. Please click “Restore Defaults” if
                you wish to continue with the pre-screen offer. If you would simply like to start a new application,
                which would not be processed under the pre-screen offer, please click “Proceed” and you can begin the
                new application process.
            </div>
            <div class="buttonContainer">
                <button type="button" class="btn btn-primary" (click)="ProceedWithoutPreApproval()">Proceed</button>
                <button type="button" class="btn btn-primary" (click)="restorePreApprovalDefaults()">Restore
                    Defaults</button>
            </div>
        </div>
    </div>

    <div style="clear:both"></div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>