import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Commitment } from '../../models/accounts/commitment';
import { AccountHttpService } from '../../services/account-http.service';

@Component({
    selector: 'gol-account',
    templateUrl: 'account-template.component.html',
    styleUrls: ['account-template.component.css']
})
export class AccountTemplateComponent implements OnInit {

    loading = false;
    error: boolean;
    errorMessage = '';
    account: Commitment = null;

    constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountHttpService) {
        accountService.accountUpdated$.subscribe(update => {
            this.accountService.getCurrentAccount(this.route.snapshot, true)
                .then(act => this.account = act);
        });
    }

    ngOnInit(): void {
        this.getAccount();
    }

    getAccount() {
        this.loading = true;
        this.accountService.getCurrentAccount(this.route.snapshot)
            .then(act => this.account = act)
            .catch(err => {
                this.errorMessage = 'Error pulling down account details: ' + err;
                this.error = true;
            })
            .then(() => this.loading = false);
    }
}
