import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../services/user.service';

@Component({
    selector: 'gol-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css', 'slider.css']
})
export class HomeComponent implements OnInit {

    showLogin = true;

    constructor(private router: Router, private userService: UserService) { }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.redirectIfLoggedIn();
    }

    redirectIfLoggedIn() {
        if (this.userLoggedIn()) {
            this.router.navigate(['/dashboard']);
        }
    }

    userLoggedIn() {
        return this.userService.GetUser() && !this.userService.GetUser().isAnonymous;
    }

    switchToForgot() {
        this.showLogin = false;
    }

    switchToLogin() {
        this.showLogin = true;
    }

}
