<gol-loading-feedback *ngIf="!account"></gol-loading-feedback>
<nav *ngIf="loan_number">
    <div *ngIf="account" class="selected-account">{{account.AccountHolder.DisplayName}} <span
            class="smaller">({{account.LoanNumber}})</span></div>
    <h2>I'd Like To</h2>
    <a routerLink="/dashboard" class="dashboard">Return to Dashboard</a>
    <a routerLink="/dashboard/preferences" class="dashboard">Update my Profile</a>
    <a class="dashboard" [class.selected]="currentTab === 'authorized-party'"
        [routerLink]="['/dashboard/account/', loan_number, 'authorized-party']" routerLinkActive="active">Add an
        Authorized Party</a>
    <h3 *ngIf="canViewAccountDetails()">View</h3>
    <ul *ngIf="canViewAccountDetails()">
        <li [class.selected]="currentTab === 'details'">
            <a [routerLink]="['/dashboard/account/', loan_number]" routerLinkActive="active">Account Details</a>
        </li>
        <li [class.selected]="currentTab === 'statements'">
            <a [routerLink]="['/dashboard/account/', loan_number, 'statements']" routerLinkActive="active">Statements
                and Documents</a>
        </li>
        <li [class.selected]="currentTab === 'amendment'">
            <a [routerLink]="['/dashboard/account/', loan_number,'amendment']" routerLinkActive="active">Account
                Amendment Options</a>
        </li>
        <li [class.selected]="currentTab === 'transaction-report'">
            <a [routerLink]="['/dashboard/account/', loan_number,'transaction-report']"
                routerLinkActive="active">Transaction Report</a>
        </li>
    </ul>
    <h3>Payments</h3>
    <ul>
        <li [class.selected]="currentTab === 'payment'">
            <a [routerLink]="['/dashboard/account/', loan_number, 'payment']" routerLinkActive="active">Make a
                Payment</a>
        </li>
        <li [class.selected]="currentTab === 'pending-payments'" *ngIf="account?.HasPendingPayment">
            <a [routerLink]="['/dashboard/account/', loan_number, 'pending-payments']"
                routerLinkActive="active">View/Edit Pending Payments</a>
        </li>
        <li [class.selected]="currentTab === 'payoff'">
            <a [routerLink]="['/dashboard/account/', loan_number, 'payoff']" routerLinkActive="active">Request a
                Payoff</a>
        </li>
        <li [class.selected]="currentTab === 'payment-history'">
            <a [routerLink]="['/dashboard/account/', loan_number, 'payment-history']" routerLinkActive="active">Payment
                History</a>
        </li>
    </ul>

</nav>