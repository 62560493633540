import { Injectable } from '@angular/core';
import { AuthenticatedHttp } from './authenticated-http.service';
import 'rxjs/add/operator/toPromise';

import { Message } from '../models/message';
import { CONFIG } from '../../environments/environment';

@Injectable()
export class MessageCenterService {
    private _messages: Message[];
    serviceCalled = false;

    constructor(private http: AuthenticatedHttp) { }

    private getGrowerMessages(): Promise<void | Message[]> {
        const url = CONFIG.apiBaseUri + 'messages/Grower';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                this._messages = Array.from(response as any[], (item) => new Message(item));
                return this._messages;
            })
            .catch(err => this.handleError(err));
    }

    getMessages(): Promise<void | Message[]> {
        return (this.serviceCalled)
            ? Promise.resolve(this._messages)
            : this.getGrowerMessages()
                .then(messages => {
                    this.serviceCalled = true;
                    this._messages = messages ? messages : null;
                    return this._messages;
                })
                .catch(err => this.handleError(err));
    }

    private handleError(error: any) {
        const json = error;
        if (json && json.Message) {
            const msg = json.Message;
            return Promise.reject(msg);
        } else {
            console.error('An error occurred', error.message || error);
            return Promise.reject(error.message || error);
        }
    }
}
