import { Component, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { Registree } from '../../models/register';
import { TopForm } from '../../form/top-form';
import { User } from '../../models/user';
import { ReCaptchaComponent } from '../../form/reusables/recaptcha.component';
import { validateCheckboxFactory } from '../../form/validation/checkbox-required.directive';
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService, IAuthService } from '../../services/auth.service';
import { GrowerOnline } from '../../models/constants';

@Component({
    selector: 'gol-registration-agreement',
    templateUrl: 'registration-agreement.component.html',
    styleUrls: ['../css/styles_form.css'],
})

export class RegistrationAgreementComponent {

    @ViewChild(ReCaptchaComponent, { static: true }) recaptcha: ReCaptchaComponent;
    showTandC = false;
    showEStatement = false;
    showAchUSBankStatement = false;
    recaptchaToken = '';
    form: TopForm = new TopForm();
    attestation: FormControl = new FormControl('', validateCheckboxFactory());
    eStatement: FormControl = new FormControl('', validateCheckboxFactory());
    achUSBankStatement: FormControl = new FormControl('', validateCheckboxFactory());
    serverError = '';
    agreementAcceptanceDate: Date;
    eStatementAcceptanceDate: Date;
    achUSBankStatementAcceptanceDate: Date;
    questionsGroup: FormGroup;
    registree: Registree;
    loading: boolean;

    constructor(public router: Router, private authService: AuthService, public registrationService: CurrentRegistrationService) {
        this.initPage();
        this.questionsGroup = new FormGroup({});
        this.form.addControl('questionGroup', this.questionsGroup);
        this.form.addControl('attestation', this.attestation);
        this.form.addControl('eStatement', this.eStatement);
        this.form.addControl('achUSBankStatement', this.achUSBankStatement);
    }

    initPage() {
        this.registree = this.registrationService.getCurrentRegistree();
        if ((this.registree.isExistingCustomer && this.registree.cifNo === 0) || !this.registree.email.length) {
            this.registrationService.back();
        }
        window.scrollTo(0, 0);
    }

    agreementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.agreementAcceptanceDate = acceptedDateTime;
    }

    eStatementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.eStatementAcceptanceDate = acceptedDateTime;
    }

    achUSBankStatementChanged(event) {
        const checked = event.target.checked;
        const acceptedDateTime = checked ? new Date() : null;
        this.achUSBankStatementAcceptanceDate = acceptedDateTime;
    }

    captchaResolved(captchaResponse: string) {
        this.recaptchaToken = captchaResponse;
    }

    continue() {
        this.form.submitted = true;
        this.serverError = '';
        if (this.form.valid && this.recaptchaToken !== '') {
            this.loading = true;
            this.registrationService.setCurrentRegistree(this.registree);
            const user = new User();
            user.isAnonymous = false;
            user.email = this.registree.email;
            user.firstName = this.registree.firstName;
            user.lastName = this.registree.lastName;
            user.password = this.registree.password;
            user.confirmPassword = this.registree.confirmPassword;
            user.securityQuestions = this.registree.securityQuestions;
            user.cifno = this.registree.cifNo;
            user.registeredSystem = GrowerOnline;
            
            this.authService.Register(
                user, this.recaptchaToken,
                this.agreementAcceptanceDate,
                this.eStatementAcceptanceDate,
                this.achUSBankStatementAcceptanceDate,
                this.registree.accountNumber,
                this.registree.ssn,
                this.registree.zip)
                .then(() => {
                    this.registrationService.makeNewRegistree();
                    this.router.navigate(['/registration/registration-summary']);
                })
                .catch((rejected) => {
                    this.serverError = rejected;
                    if (this.recaptcha) {
                        this.recaptcha.reset();
                        this.recaptchaToken = '';
                    }
                })
                .then(() => this.loading = false);
        }
    }
}
