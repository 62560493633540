import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationNavigationComponent as RegistrationNavigationComponentV1 } from '../../../app/registration/navigation/registration-navigation.component';
import { NavigationOptionsRegistration } from '../../models/navigation-options-registration';

@Component({
    selector: 'gol-registration-navigation',
    templateUrl: '../../../app/registration/navigation/registration-navigation.component.html',
    styleUrls: ['../../../app/registration/css/progress-navigation.css']
})
export class RegistrationNavigationComponent extends RegistrationNavigationComponentV1 {

    constructor(private routerV1: Router, private activeRouteV1: ActivatedRoute) {
        super(routerV1, activeRouteV1);

        this.options = NavigationOptionsRegistration.RegistrationPages(); //TODO:MFA
    }

    navigate(route: string, alias: string) { // TODO:MFA Override base

        if (!this.isDisabledViaConfig(alias)){
            return super.navigate(route, alias);
        } else {
            return false;
        }
    }

    private isDisabledViaConfig(alias: string) { 
        var pageNavsMatched = NavigationOptionsRegistration.PageNavigationMatrix.filter(p => p.fromAlias === this.selectedAlias && p.toAlias === alias);
        const disabledViaConfig = pageNavsMatched.length == 1 ? !pageNavsMatched[0].isAllowed : false;
        return disabledViaConfig;
    }
}
