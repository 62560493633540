import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Registree } from '../../models/register';
import { TopForm } from '../../form/top-form';
import { validateEmailFactory } from '../../form/validation/email-validator.directive';
import { validatePasswordFactory, passwordCompareFactory } from '../../form/validation/password-validator.directive';
import { CurrentRegistrationService } from '../../services/current-registration.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'gol-registration-profile',
    templateUrl: 'registration-profile.component.html',
    styleUrls: ['../css/styles_form.css'],
})

export class RegistrationProfileComponent {

    form: TopForm = new TopForm();
    firstName: FormControl = new FormControl('', Validators.required);
    lastName: FormControl = new FormControl('', Validators.required);
    email: FormControl = new FormControl('', [Validators.required, validateEmailFactory()]);
    password: FormControl = new FormControl('', [Validators.required, validatePasswordFactory()]);
    confirmPassword: FormControl = new FormControl('', [Validators.required]);
    existingCustomer: FormControl = new FormControl('');
    passwordGroup: FormGroup;
    serverError = '';
    emailError = '';
    registree: Registree;
    loading: boolean;

    constructor(public router: Router, private authService: AuthService, public registrationService: CurrentRegistrationService) {
        this.initPage();
        this.passwordGroup = new FormGroup({
            'password': this.password,
            'confirmPassword': this.confirmPassword
        }, passwordCompareFactory());
        this.form.addControl('firstName', this.firstName);
        this.form.addControl('lastName', this.lastName);
        this.form.addControl('email', this.email);
        this.form.addControl('passwordGroup', this.passwordGroup);
        this.form.addControl('existingCustomer', this.existingCustomer);
    }

    initPage() {
        this.registree = this.registrationService.getCurrentRegistree();
        window.scrollTo(0, 0);
    }

    passwordValidationMessage(errorObj) {
        let message = 'Password is invalid: ';
        // tslint:disable-next-line: forin
        for (const error in errorObj) {
            message += errorObj[error] + '; ';
        }
        return message;
    }

    continue() {
        this.form.submitted = true;
        if (this.form.valid) {
            this.loading = true;
            this.authService.CheckAvailability(this.email.value, this.email.value)
                .then((result) => {
                    this.loading = false;
                    if (result === 'Available') {
                        this.registrationService.setCurrentRegistree(this.registree);
                        if (this.registree.isExistingCustomer === true) {
                            this.router.navigate(['/registration/registration-linkaccount']);
                        } else {
                            this.router.navigate(['/registration/registration-security']);
                        }
                    } else {
                        this.emailError = '\'' + this.email.value + '\' is already registered';
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.serverError = error;
                })
                .then(() => this.loading = false);
        }
    }

    cancel() {
        this.registrationService.cancel();
    }
}
