import { Component, OnInit, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { SimpleQuestion } from '../../models/wizard/simple-question';
import { Question } from '../../models/wizard/question';
import { WizardQuestionService } from '../../services/wizard-question-http.service';
import { HistoryService } from '../../services/history.service';
import { FinancialSummaryService } from '../../services/financial-summary.service';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-financial-information-balance-sheet',
    templateUrl: 'financial-information-balance-sheet.component.html',
    styleUrls: ['../styles_form.css'],
    providers: [WizardQuestionService]
})
export class FinancialInformationBalanceSheetComponent extends AbstractFormPage implements AfterViewInit, OnInit {
    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    form: TopForm = new TopForm();
    currentIndex = 0;
    review = false;
    editing = false;
    characters = [];
    historyService: HistoryService;

    questions: Question[];

    financesContainsErrorsCallback: () => boolean = null;

    ngAfterViewInit() {
        this.inputChildren.forEach((item) => {
            this.form.addControl(item.name, item.control);
        });
    }

    ngOnInit() {
        this.financialSummaryService.ContainsErrorsMethod.subscribe({
            next: (f) => { this.financesContainsErrorsCallback = f; }
        });
    }

    constructor(
        currentAppSvc: CurrentApplicationService,
        router: Router,
        utilities: UtilitiesService,
        history: HistoryService,
        notificationService: NotificationService,
        private wizardService: WizardQuestionService,
        private financialSummaryService: FinancialSummaryService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.historyService = history;
        this.pageAlias = 'balancesheet';
        this.initPage();
    }

    afterInitPage() {
        this.characters = this.getCharacters();
        if (!this.application.balanceSheet.questions) {
            this.wizardService.getQuestions().then(questions => {
                this.questions = questions;
            })
                .catch((err) => { });
        } else {
            this.questions = this.application.balanceSheet.questions;
            const index = this.application.balanceSheet.getCurrentWizardIndex();
            if (index === -1) {
                this.review = true;
                this.currentIndex = this.questions.length;
            }
        }
    }

    getCharacters(): string[] {
        const characters = this.application.borrowers.map((person) => person.fullName.toString());
        if (this.application.entity && this.application.entity.legalName) {
            characters.push(this.application.entity.legalName);
        }
        return characters;
    }

    get currentIsSimple(): boolean {
        return this.questionIsSimple(this.currentQuestion);
    }

    questionIsSimple(question: Question): boolean {
        return question instanceof SimpleQuestion;
    }

    get currentQuestion(): Question {
        return this.questions[this.currentIndex];
    }

    get financesContainErrors(): boolean {
        return this.financesContainsErrorsCallback && this.financesContainsErrorsCallback();
    }

    nextQuestion() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;
        if (this.form.valid) {
            this.application.balanceSheet.questions = this.questions;
            this.application.save();

            if (this.editing) {
                this.currentIndex = this.questions.length - 1;
                this.editing = false;
                this.review = true;
            } else if (this.questions.length > (this.currentIndex + 1)) {
                this.form.submitted = false;
                this.currentIndex++;
            } else {
                this.review = true;
                this.currentIndex++;
            }
        } else {
            this.scrollToError();
        }
    }

    navigateWizard(index: number) {
        this.editing = true;
        this.review = false;
        this.currentIndex = +index;
    }

    submit() {
        this.application.balanceSheet.convertWizardAnswersToAppData();
        if (!this.financesContainErrors) {
            this.next();
        }
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string) {
        return new Promise<boolean>((resolve, reject) => {
            const comp = this;
            comp.application.balanceSheet.convertWizardAnswersToAppData();
            if (!comp.financesContainErrors) {
                comp.application.currentPage = newPageAlias;
                resolve(comp.save());
            } else {
                reject(false);
            }
        });
    }

    wizardBack() {
        // flow hard...
        if (this.review || this.currentIndex === 0) {
            if (this.historyService.isEmpty()) {
                this.router.navigate(['/financial-information-balance-sheet-selection']);
            } else {
                this.back();
            }
        } else {
            // remove current form controls from formapi
            for (const c in this.form.controls) {
                if (c.split('_')[1] === this.currentIndex.toString()) {
                    this.form.removeControl(c);
                }
            }
            this.form.submitted = false;
            this.currentIndex--;
        }

    }



}
