import { Component } from '@angular/core';

@Component({
    selector: 'gol-registration',
    template: `
        <div class="stage container dashboard-wrapper">
            <gol-registration-navigation></gol-registration-navigation>
            <h1>User Registration</h1>
            <hr />
            <router-outlet></router-outlet>
        </div>
    `,
    styleUrls: ['../../app/registration/css/styles_form.css'],
})
export class RegistrationComponent {
    constructor() { }
}
