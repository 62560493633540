import { Component, Input, OnInit, AfterViewInit, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel, NgForm, FormGroup } from '@angular/forms';
import { UtilitiesService } from '../../services/utilities.service';
import { CurrencyPipe } from '@angular/common';
import numberMask from 'text-mask-addons/dist/createNumberMask';

export const NUMBER_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberInputComponent),
    multi: true
};

@Component({
    selector: 'gol-number-input',
    templateUrl: 'number-input.component.html',
    providers: [NUMBER_INPUT_CONTROL_VALUE_ACCESSOR]

})
export class NumberInputComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('inputRef', { static: true }) input: { nativeElement: { value: string; focus: () => void; select: () => void; }; };
    @Input() placeholder = '';
    @Input() disabled: boolean;
    @Input() name: string;

    value: number;
    mask = numberMask({
        prefix: '$',
        includeThousandsSeperator: true,
        allowDecimal: true,
    });
    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    constructor(private utilities: UtilitiesService) { }

    writeValue(value: any) {
        if (value !== this.value) {
            this.value = value;
            this.initInput();
        }
    }

    ngAfterViewInit() {
        this.initInput();
    }

    initInput() {
        if (this.input) {
            setTimeout(() => {
                this.input.nativeElement.value = new CurrencyPipe('en').transform(this.toNumber(this.value), 'USD', 'symbol', '1.2-2');
            });
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    update() {
        this.onTouchedCallback();
        this.onChangeCallback(this.value);
    }

    toNumber(value) {
        return this.utilities.toNumber(value);
    }

    focus() {
        if (this.input) {
            setTimeout(() => {
                this.input.nativeElement.focus();
                this.input.nativeElement.select();
            }, 50);
        }
    }
}
