import { Component } from '@angular/core';

@Component({
    selector: 'gol-dashboard-home',
    templateUrl: 'dashboard-home.component.html',
    styles: [`
        .dashboard {
            padding-bottom: 15px;
        }
    `]
})
export class DashboardHomeComponent {

    loadingAccounts = true;
    loadingApplications = true;
    hasAccount: boolean;
    hasOpenApplication: boolean;

    returnHasAccount(event: boolean) {
        this.hasAccount = event;
    }

    returnloadingAccounts(event: boolean) {
        this.loadingAccounts = event;
    }

    returnHasOpenApplication(event: boolean) {
        this.hasOpenApplication = event;
    }

    returnLoadingApplications(event: boolean) {
        this.loadingApplications = event;
    }
}
