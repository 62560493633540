import { Component, OnInit, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { Application } from '../../models/application';
import { CurrentApplicationService } from '../../services/current-application.service';
import { Router } from '@angular/router';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { NgModel, FormControl, FormGroup } from '@angular/forms';
import { UtilitiesService } from '../../services/utilities.service';
import { SimpleQuestion } from '../../models/wizard/simple-question';
import { Question } from '../../models/wizard/question';
import { WizardQuestionService } from '../../services/wizard-question-http.service';
import { HistoryService } from '../../services/history.service';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-financial-information-balance-sheet-selection',
    template: `<div class="container stage main_container">
                <gol-side-nav selectedAlias="income"></gol-side-nav>
                <div class="row">
                    <div class="col-sm-7">
                        <h1>Financial Information - Balance Sheet</h1>
                    </div>
                    <div class="col-sm-5 change-app">
                        <gol-change-application-type-component></gol-change-application-type-component>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <ul class="nav wizard-nav flex-column">
                        <li>
                            <a tabindex="0" class="btn btn-wrap btn-primary" (click)="goToWizard()">Walk me through creating a balance sheet</a>
                        </li>
                        <li>
                            <a tabindex="0" class="btn btn-wrap btn-primary" (click)="submit()">I have a balance sheet and will enter totals</a>
                        </li>
                    </ul>
                </div>
                <div class="buttonContainer group">
                    <button class="btn btn-secondary back-btn" type="button" (click)="back()">Back</button>
                </div>
                <div style="padding-bottom:20px"></div>
            </div>
            <div>
                <if-notification defaultLife="12000"></if-notification>
            </div>`,
    styleUrls: ['../styles_form.css']
})
export class FinancialInformationBalanceSheetSelectionComponent extends AbstractFormPage {


    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService, notificationService: NotificationService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'balancesheetSelection';
        this.initPage();
    }

    goToWizard() {
        this.goToPage('/financial-information-balance-sheet');
    }

    submit() {
        this.goToPage('/financial-information');
    }


}
