<div class="row">
    <div class="col-sm-3"></div>
    <div class="raised registrationStage white col-sm-6">
        <h2>Register as a new user</h2>
        <form [formGroup]="form" (ngSubmit)="continue()" novalidate>
            <div class="form-group">
                <label for="firstName">First Name</label>
                <input name="firstName" [formControl]="firstName" class="form-control" [(ngModel)]="registree.firstName"
                    placeholder="First Name" />
                <div class="error" *ngIf="(form.submitted || firstName.dirty) && firstName.invalid">
                    <p *ngIf="firstName.errors['required']">Required</p>
                </div>
            </div>
            <div class="form-group">
                <label for="lastName">Last Name</label>
                <input name="lastName" [formControl]="lastName" class="form-control" [(ngModel)]="registree.lastName"
                    placeholder="Last Name" />
                <div class="error" *ngIf="(form.submitted || lastName.dirty) && lastName.invalid">
                    <p *ngIf="lastName.errors['required']">Required</p>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input name="email" [formControl]="email" class="form-control" [(ngModel)]="registree.email"
                    placeholder="Email" />
                <div class="error" *ngIf="(form.submitted || email.dirty) && email.invalid">
                    <p *ngIf="email.errors['required']">Required</p>
                    <p *ngIf="email.errors['validateEmail']">Email is invalid</p>
                </div>
                <div class="error" *ngIf="emailError">
                    <p>{{emailError}}</p>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input name="password" type="password" [formControl]="password" class="form-control"
                    [(ngModel)]="registree.password" placeholder="Password" autocomplete="off" />
                <div class="error" *ngIf="(form.submitted || password.dirty) && password.invalid">
                    <p *ngIf="password.errors['required']">Required</p>
                    <p *ngIf="password.errors['passwordValidation']">
                        {{passwordValidationMessage(password.errors['passwordValidation'])}}
                    </p>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input name="confirmPassword" type="password" [formControl]="confirmPassword" class="form-control"
                    [(ngModel)]="registree.confirmPassword" autocomplete="off" placeholder="Confirm Password" />
                <div class="error"
                    *ngIf="(form.submitted || confirmPassword.dirty) && (confirmPassword.invalid || passwordGroup.invalid)">
                    <p *ngIf="confirmPassword.errors?.required">Required</p>
                    <p *ngIf="passwordGroup.errors?.noMatch">Passwords do not match</p>
                </div>
            </div>
            <div class="form-group">
                <label>
                    <p-checkbox name="existingCustomer" [formControl]="existingCustomer"
                        [(ngModel)]="registree.isExistingCustomer" binary="true"></p-checkbox>Are you an existing
                    customer?
                </label>
            </div>
            <div>
                * If you are already registered as a dealer on Dealer.Raboag.com, please use the same login credentials
                to register your grower account.
            </div>
            <div class="form-group error" *ngIf="serverError">
                {{serverError}}
            </div>
            <div class="buttonContainer">
                <button type="submit" class="btn btn-primary" [disabled]="loading">Continue</button>
                <button type="button" class="btn btn-secondary" (click)="registrationService.cancel()"
                    [disabled]="loading">Cancel</button>
                <div class="clear"></div>
            </div>

        </form>
    </div>
    <div class="col-sm-3"></div>
</div>