import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from '../../models/application';
import { ApplicationType } from '../../models/application-type';
import { CurrentApplicationService } from '../../services/current-application.service';
import { Entity } from '../../models/entity';
import { NavigationOptions } from '../../models/navigation-options';


@Component({
    selector: 'gol-change-application-type-component',
    host: {
        '(document:click)': 'handleClick($event)',
    },
    template: `<div #changeAppType  class="changeAppContainer form-group">
                    <a tabindex="0" (click)='showOptions = !showOptions'>Change Application Type</a>
                    <select *ngIf="showOptions" class="form-control changeAppDropDown" [ngModel]="selected" (change)="changedAppType($event.target.value)">
                        <option *ngFor="let name of appTypes" [value]="name">{{GetEnumDisplay(name)}}</option>
                    </select>
                </div>
    `,
    styles: [`.changeAppContainer {
        position:relative;
        color: var(--blue-500);
    }
    .changeAppDropDown {
        position:absoulte;
        left: 0;
        right: 0;
        z-index:99;
    }`]
})
export class ChangeApplicationTypeComponent implements OnInit {
    appTypes: string[];
    showOptions = false;
    selected: string;
    @ViewChild('changeAppType', { static: true }) changeAppDropDown;
    constructor(private router: Router, private currentAppService: CurrentApplicationService) { }

    ngOnInit() {
        const types = Object.keys(ApplicationType);
        this.appTypes = types.slice(types.length / 2);
        this.currentAppService.getCurrentApplication('ChangeApplicationTypeComponent - ngOnInit').then(app => {
            this.selected = ApplicationType[app.appType];
        });
    }

    changedAppType(value: string) {
        this.currentAppService.getCurrentApplication('ChangeApplicationTypeComponent - changedAppType').then(app => {
            const previousType = app.appType;
            const redirect = '';
            app.appType = ApplicationType[value];
            if (app.appType === ApplicationType.Entity && previousType !== ApplicationType.Entity) {
                app.entity = new Entity();
            } else {
                app.entity = null;
            }
            const startpage = NavigationOptions.AppPages(app, true)[0];
            app.currentPage = startpage.alias;
            this.currentAppService.setCurrentApplication(app);
            this.router.navigate([startpage.route]);
        });
    }

    GetEnumDisplay(appType: string) {
        if (ApplicationType[appType] === ApplicationType.Single) {
            return 'Individual';
        } else if (ApplicationType[appType] === ApplicationType.CoBorrower) {
            return 'Individual with Co-Borrowers';
        } else {
            return appType;
        }
    }

    handleClick(event) {
        let clickedComponent = event.target;
        let inside = false;
        if (this.changeAppDropDown) {
            do {
                if (clickedComponent === this.changeAppDropDown.nativeElement) {
                    inside = true;
                }
                clickedComponent = clickedComponent.parentNode;
            } while (clickedComponent);
        }
        if (!inside) {
            this.showOptions = false;
        }
    }
}
