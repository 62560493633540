import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationOptions } from '../../../models/navigation-options';
import { Application } from '../../../models/application';
import { CurrentApplicationService } from '../../../services/current-application.service';
import { HistoryService } from '../../../services/history.service';

@Component({
    selector: 'gol-side-nav',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['progress-navigation.css']
})
export class SideNavComponent {
    constructor(private router: Router, private currentAppService: CurrentApplicationService, private activeRoute: ActivatedRoute, public history: HistoryService) {
        currentAppService.getCurrentApplication('SideNavComponent - Cstr').then((app) => {
            this.currentApplication = app;
            if (app) {
                this.options = NavigationOptions.AppPages(this.currentApplication, false);
            }
        });
    }

    @Input() validateSave(newPageAlias: string): Promise<boolean> { return new Promise<boolean>(()=>false); }
    @Input() selectedAlias: string;
    currentApplication: Application;
    options: any[];
    showingMenu = false;
    closeProgressNav = true;

    isDisabled(alias: string) {
        if (this.currentApplication.maxPageReached && this.currentApplication.maxPageReached !== '') {
            const aliasIndex = NavigationOptions.AppPages(this.currentApplication, true).findIndex((page) => page.alias === alias);
            const maxIndex = NavigationOptions.AppPages(this.currentApplication, true).findIndex((page) => page.alias === this.currentApplication.maxPageReached);
            return aliasIndex > maxIndex;
        }
        return this.getIndexByAlias(alias) > this.getIndexByAlias(this.selectedAlias);
    }

    isSelected(alias: string) {
        return this.getIndexByAlias(alias) === this.getIndexByAlias(this.selectedAlias);
    }

    getIndexByAlias(alias: string): number {
        return this.options.findIndex((page) => page.alias === alias);
    }

    showMenu(show: boolean) {
        this.showingMenu = show;
    }

    navigate(route: string, alias: string) {
        if (this.isDisabled(alias) || this.isSelected(alias)){
            return false;
        }
        this.validateSave(alias).then(res => {
            if(res){
                this.history.forward(route.split('..')[1]);
                this.router.navigate([route], { relativeTo: this.activeRoute });
            }
            return res;
        }, reject => {
            // reject is already pushed to notification queue in ValidateSaveFunction
            return false;
        }
        ).catch(err => {
            // err is already pushed to notification queue in ValidateSaveFunction
            return false;
        });
    }
}
