export class ApplicationHeader {
    id: any;
    status = 'Draft';

    constructor(appData?: any) {
        if (appData) {
            this.id = appData.ID;
            this.status = appData.Status;
        }
    }
}
