<if-notification defaultLife="12000"></if-notification>
<div class="container" fxLayout="column">
    <div class="row">
        <div class="col-sm-4" id="login" fxFlex.lt-md="100%">
            <div *ngIf="showLogin">
                <gol-login [hideCancel]=true (closeEvent)="redirectIfLoggedIn()"></gol-login>
                <div id="loginOptions">
                    <p class="passwordLink"><a class="clickable" tabindex="0" (click)="switchToForgot()">Forgot your
                            password?</a></p>
                    <p class="registerLink">If you are a new user, please <a class="clickable" routerLink="/registration">click here</a> to register.</p>
                    <p class="right"><a class="clickable" href="https://www.raboag.com/security-privacy/how-we-protect-you-148" target="_blank">Click
                            here</a> for customer security information.</p>
                    <p class="right">Only authorized use of this site is permitted. Unauthorized access may result in legal or disciplinary action.</p>
                    <p class="right">This site uses cookies to provide for technical functionality as well as to make our website more enjoyable and relevant
                        to you. By continuing your visit on the website, you consent to the use of these cookies.
                    </p>
                </div>
            </div>
            <div *ngIf="!showLogin">
                <gol-reset-password (closeEvent)="switchToLogin()"></gol-reset-password>
            </div>
        </div>
        <div class="col-sm-8" fxHide.lt-md="true" >
            <div id="carousel">
                <div id="transparent">
                    <div id="slider">
                        <ul>
                            <li>
                                <div class="slider-container">
                                    <h1>Welcome to the new Grower Online Site!</h1>
                                    <button class="btn btn-primary" [routerLink]="['/registration']">Register
                                        Here!</button>
                                    <p class="newParagraph">Get started with your online banking by registering today.
                                    </p>
                                    <div style="clear:both"></div>
                                </div>
                            </li>
                            <li>
                                <div class="slider-container">
                                    <h1>Need credit? Online applications are now available!</h1>
                                    <button class="btn btn-primary" [routerLink]="['/apply']">Apply Here!</button>
                                    <p>Quick - Convenient - Faster Turnaround</p>
                                    <div style="clear:both"></div>
                                </div>
                            </li>
                            <li>
                                <div class="slider-container">
                                    <h1>Welcome to the new Grower Online Site!</h1>
                                    <button class="btn btn-primary" [routerLink]="['/registration']">Register
                                        Here!</button>
                                    <p class="newParagraph">Get started with your online banking by registering today.
                                    </p>
                                    <div style="clear:both"></div>
                                </div>
                            </li>
                            <li>
                                <div class="slider-container">
                                    <h1>Need credit? Online applications are now available!</h1>
                                    <button class="btn btn-primary" [routerLink]="['/apply']">Apply Here!</button>
                                    <p>Quick - Convenient - Faster Turnaround</p>
                                    <div style="clear:both"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" fxLayout="row" fxHide.gt-xs="true" fxLayoutAlign="space-around">
    <button type="button" class="btn btn-primary" routerLink="/apply">Apply Here</button>
    <button type="button" class="btn btn-primary" routerLink="/contact">Contact Us</button>
</div>
<div class="container" id="features">
    <h2 id="title">You’re just a click away from accessing everything you need.
    </h2>
    <div class="row">
        <div class="col-sm-4">
            <div class="feature">
                <div class="icon iconPadding5"><i class="fa fa-usd fa-3x"></i></div>
                <div class="description">
                    <label>Pay Bills</label>
                    <p>Free yourself from mailing in checks with convenient online bill pay.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="feature">
                <div class="icon"><i class="fa fa-files-o fa-3x"></i></div>
                <div class="description">
                    <label>Online Statements</label>
                    <p>Access copies of your statements and transaction history.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="feature">
                <div class="icon"><i class="fa fa-desktop fa-3x"></i></div>
                <div class="description">
                    <label> Account Management</label>
                    <p>Request credit limit increases and make changes to the programs you're enrolled in.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div class="feature">
                <div class="icon"><i class="fa fa-file-text fa-3x"></i></div>
                <div class="description">
                    <label>Application Management</label>
                    <p>Check the status of your application as it goes through the approval process.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="feature">
                <div class="icon"><i class="fa fa-envelope fa-3x"></i></div>
                <div class="description">
                    <label>Alerts</label>
                    <p>Provides notifications on account activities.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="feature">
                <div class="icon iconPadding10"><i class="fa fa-lock fa-3x"></i></div>
                <div class="description">
                    <label>Security</label>
                    <p>Your account security and privacy are our top priority. Rabo AgriFinance is committed to making
                        your online transactions secure.</p>
                </div>
            </div>
        </div>
    </div>
</div>