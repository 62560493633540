import { IItemFactory } from '../form/reusables/autocomplete/autocomplete.component';
import { TopVendors, Vendor } from '../models/vendor';
import { VendorHeader } from '../models/vendor-header';
import { AuthenticatedHttp } from './authenticated-http.service';
import { VendorService } from './vendor.service';

export class TopVendorsFactory implements IItemFactory {
    public items: string[] = [];

    constructor(private vendorService: VendorService) {
        this.GetVendors();
    }

    getItems(query: string): string[] {
        if (query == null || query === '') {
            return this.items;
        } else {
            return this.items.filter(function (el) {
                return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }.bind(this));
        }
    }

    private GetVendors() {
        this.items = this.vendorService.filteredVendors.map((value) => {
            return value.getShortName();
        });
    }
}

export class VendorDealersFactory implements IItemFactory {
    public items: string[] = [];

    constructor(private vendorService: VendorService) {
        this.GetVendorRetailers();
    }

    getItems(query: string): string[] {
        if (query == null || query === '') {
            return this.items;
        } else {
            return this.items.filter(function (el) {
                return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }.bind(this));
        }
    }

    private GetVendorRetailers() {
        this.items = this.vendorService.vendorRetailers.map((value) => {
            return value.getItemName();
        });
    }
}
