import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../../app/services/user.service';
import { AccountHttpService } from './account-http.service';

@Injectable()
export class UserContactGuard implements CanActivate {

    constructor(protected router: Router, protected userService: UserService, protected accountService: AccountHttpService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const user = this.userService.GetUser();

        if (user != null && !user.isAnonymous && user.cifno) {
            return Promise.resolve(true);
        } else {
            this.router.navigate(['/dashboard']);
            return Promise.resolve(false);
        }
    }
}
