<gol-environment-banner></gol-environment-banner>
<gol-header-component></gol-header-component>
<gol-message-center></gol-message-center>
<router-outlet></router-outlet>
<gol-footer-component></gol-footer-component>
<gol-idle-component></gol-idle-component>
<p-dialog header="Unsupported browser" [(visible)]="showBowserAlert" [modal]="true">
  <div class="modal-body">
    <h1>Unsupported browser</h1>
    <p>This website is designed to work best when using an up to date internet browser. Edge 88.0, Chrome 87.0, FireFox
      80.0 or later versions ensure that all features and functionality are available.</p>
    <p> Please update to the latest version to optimize your experience.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showBowserAlert = false">Ok</button>
  </div>
</p-dialog>