import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { ApplicationType } from '../../models/application-type';
import { Person } from '../../models/person';
import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { ChangeLogService } from '../../services/changelog.service';
import { ChangeLogModel } from '../../../app/models/changelog';
import { UserService } from '../../services/user.service';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-borrower-information',
    templateUrl: 'borrower-information.component.html',
    styleUrls: ['../styles_form.css']
})

export class BorrowerInformationComponent extends AbstractFormPage {
    isOpen: boolean[];
    accordionMap: number[];
    formGroups: FormGroup[];
    form: TopForm;
    showModal: boolean;
    preApproved = false;
    changeLogSvc: ChangeLogService;
    userService: UserService;
    containerClass: string;
    pendingChangelogs: ChangeLogModel[] = [];

    constructor(currentAppSvc: CurrentApplicationService, router: Router,
        utilities: UtilitiesService, history: HistoryService,
        changeLogService: ChangeLogService, userSvc: UserService, notificationService: NotificationService) {

        super(currentAppSvc, router, utilities, history, notificationService);

        this.changeLogSvc = changeLogService;
        this.userService = userSvc;
        this.pageAlias = 'borrower';
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
        this.containerClass = '';
        this.initPage();
    }

    afterInitPage() {
        this.form = new TopForm();
        if (this.application.borrowers.length === 0) {
            this.add();
        } else {
            let opened = false;
            for (let i = 0; i < this.application.borrowers.length; ++i) {
                if (!opened &&
                    (!this.application.borrowers[i].fullName.firstName ||
                        !this.application.borrowers[i].fullName.lastName)) {
                    opened = true;
                    this.isOpen.push(true);
                } else {
                    this.isOpen.push(false);
                }
                this.accordionMap.push(i);
                const group = new FormGroup({});
                this.formGroups.push(group);
                this.form.addControl(this.accordionMap.length.toString(), group);
            }
        }

        if (this.application.preApprovalStagingId > 0) {
            this.SetPreApprovalStatus(true);
            this.isOpen[0] = true;
        }
    }

    setClass(isSubmitted: boolean, index: number): string {
        if (!this.containerClass) {
            if (isSubmitted && !(this.formGroups[index] && this.formGroups[index].valid)) {
                this.containerClass = 'error';
            }
            if (!(this.formGroups[index] && this.formGroups[index].valid) && !this.isOpen[index]) {
                this.containerClass = 'ng-invalid';
            }
        }
        return this.containerClass;
    }

    add() {
        this.form.submitted = false;
        const borrower = new Person();
        borrower.isUSCitizen = true;
        this.application.borrowers.push(borrower);
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        this.formGroups.push(group);
        this.form.addControl(this.accordionMap.length.toString(), group);
        window.scrollTo(0, 0);
    }

    delete(i: number, e: MouseEvent) {
        this.application.borrowers.splice(i, 1);
        this.isOpen.splice(i, 1);
        this.formGroups.splice(i, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === i) {
                found = true;
                this.form.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        e.preventDefault();

        if (this.application.borrowers.length === 0) {
            this.add();
        }
    }

    tabOpen(e) {
        this.isOpen[this.accordionMap[e.index]] = true;
    }

    tabClose(e) {
        this.isOpen[this.accordionMap[e.index]] = false;
    }

    private closeAllTabs() {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    hasNonWhitespace(s) {
        return /[^\s]/g.test(s);
    }

    showDisplayName(borrower: Person): boolean {
        return this.hasNonWhitespace(borrower.fullName);
    }

    previous() {
        if (this.isValidForm()) {
            this.back();
        }
    }

    submit() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;

        // tslint:disable-next-line: forin
        for (const index in this.form.controls) {
            const discharge = this.form.controls[index].get('dischargeDate');
            if (discharge) {
                discharge.updateValueAndValidity();
            }
        }

        if (this.application.preApprovalStagingId > 0 && !this.comparePreApprovalData()) {
            this.showModal = true;
            return;
        }

        if (this.form.valid && !this.invalidTotalOwnership()) {
            this.next(1);
        } else {
            this.scrollToError();
        }
    }

    isValidForm() {
        const comp = this;

        comp.form.updateValueAndValidity();
        comp.form.submitted = true;

        // tslint:disable-next-line: forin
        for (const index in comp.form.controls) {
            const discharge = comp.form.controls[index].get('dischargeDate');
            if (discharge) {
                discharge.updateValueAndValidity();
            }
        }
        if (comp.application.preApprovalStagingId > 0 && !comp.comparePreApprovalData()) {
            comp.showModal = true;
            return false;
        }

        if (comp.form.valid && !comp.invalidTotalOwnership()) {
            return true;
        } else {
            comp.scrollToError();
            return false;
        }
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const comp = this;
            if (comp.isValidForm()) {
                comp.application.currentPage = newPageAlias;
                try {
                    resolve(comp.save());
                } catch (err) {
                    reject(false);
                }
            } else {
                reject(false);
            }
        });
    }

    afterSave(id): void {
        if(this.pendingChangelogs.length > 0){
            const myUser = this.userService.GetUser();
            this.pendingChangelogs.forEach(log => {
                log.RefValue = `${id}`;
                log.ChangedBy = myUser && myUser.email ? myUser.email : 'Unknown';
                this.changeLogSvc.saveChangeLog(log);
            });
            this.pendingChangelogs = [];
        }
    }

    invalidTotalOwnership() {
        if (!this.isEntityApplication()) {
            return false;
        }
        const ownerPercent = this.application.borrowerOwnership();
        return (ownerPercent > 100);
    }

    isEntityApplication() {
        return this.application.appType === ApplicationType.Entity;
    }

    viewModal() {
        this.showModal = true;
    }

    comparePreApprovalData(): boolean {
        const borrowerFullName = this.application.borrowers[0].fullName;
        const borrowerMailingAddress = this.application.borrowers[0].mailingAddress;
        const defaultName = this.application.preApprovalBorrower?.fullName;
        let defaultAddress = this.application.preApprovalBorrower?.mailingAddress;

        if (borrowerFullName.lastName !== defaultName?.lastName) {
            return false;
        } else {
            //check allowed changes and log for auditing
            if (borrowerFullName.firstName !== defaultName.firstName) {
                this.generateChangeLog('FirstName', defaultName.firstName, borrowerFullName.firstName);
                defaultName.firstName = borrowerFullName.firstName;
            }
            if (borrowerFullName.middleName !== defaultName.middleName) {
                this.generateChangeLog('MiddleName', defaultName.middleName, borrowerFullName.middleName);
                defaultName.middleName = borrowerFullName.middleName;
            }
            if (borrowerFullName.suffix !== defaultName.suffix) {
                this.generateChangeLog('Suffix', defaultName.suffix, borrowerFullName.suffix);
                defaultName.suffix = borrowerFullName.suffix;
            }
            if (defaultAddress.toString() !== borrowerMailingAddress.toString()) {
                this.generateChangeLog("Address", defaultAddress.toString(), borrowerMailingAddress.toString());
                defaultAddress = borrowerMailingAddress;
            }

            return true;
        }
    }

    generateChangeLog(fieldName: string, oldValue: string, newValue: string){
        
        let log = new ChangeLogModel();
        log.Form='PreApproval';
        let myUser = this.userService.GetUser();
        log.ChangedBy = myUser && myUser.email ? myUser.email : 'Unknown';
        log.RefType = 'ApplicationId';
        log.RefValue = this.application.id;
        log.FieldName = fieldName;
        log.OldValue = oldValue;
        log.NewValue = newValue;
        if (!log.RefValue || log.RefValue === '0'){
            this.pendingChangelogs.push(log);
        }else{
            this.changeLogSvc.saveChangeLog(log);
        }
    }

    restorePreApprovalDefaults() {
        if (this.application.borrowers.length === 0) {
            this.add();
        }
        const borrower = this.application.borrowers[0];
        const defaults = this.application.preApprovalBorrower;

        borrower.fullName.firstName = defaults.fullName.firstName;
        borrower.fullName.lastName = defaults.fullName.lastName;
        borrower.fullName.middleName = defaults.fullName.middleName;
        borrower.fullName.suffix = defaults.fullName.suffix;

        borrower.mailingAddress.city = defaults.mailingAddress.city;
        borrower.mailingAddress.line1 = defaults.mailingAddress.line1;
        borrower.mailingAddress.line2 = defaults.mailingAddress.line2;
        borrower.mailingAddress.state = defaults.mailingAddress.state;
        borrower.mailingAddress.zipcode = defaults.mailingAddress.zipcode;
        borrower.physicalAddress = this.application.preApprovalBorrower.mailingAddress;
        borrower.physicalAddress.isPhysicalAddress();

        this.SetPreApprovalStatus(true);
        this.showModal = false;
    }

    ProceedWithoutPreApproval() {
        this.application.preApprovalStagingId = null;
        this.SetPreApprovalStatus(false);
        this.submit();
    }

    SetPreApprovalStatus(status: boolean) {
        this.preApproved = status;
        this.application.borrowers[0].isPreApproved = this.preApproved;
    }
}
