import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'gol-date-box',
    templateUrl: 'date-box.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DateBoxComponent }
    ]
})
export class DateBoxComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() disabled = false;
    innerValue = '';
    outerValue: Date;
    twoDigitYearCap = 40;
    _onChange: (value: any) => void;

    writeValue(value: any) {
        const date = moment(value);

        if (value && date.isValid()) {
            this.innerValue = date.format('MM/DD/YYYY');
            this.outerValue = date.toDate();
        }
    }

    inputUpdate($event: any): void {
        const candidate = this.cleanInput($event.target.value);

        $event.target.value = candidate;
        this.innerValue = candidate;

        if (candidate === '') {
            this.outerValue = null;
        } else {
            this.outerValue = this.parseDate(candidate);
        }

        this._onChange(this.outerValue);
    }

    blurUpdate(): void {
        if (this.outerValue instanceof Date && !/invalid/i.test(this.outerValue.toString())) {
            this.innerValue = moment(this.outerValue).format('MM/DD/YYYY');
        }
    }

    cleanInput(input: string): string {
        const illegalChars = /[^\d/\-\.]/gi;
        return input.replace(illegalChars, '');
    }

    parseDate(candidate: string): Date {
        const dateLike = /^(\d{1,2})[/\-\.](\d{1,2})[/\-\.](\d{4}|\d{2})$/;
        const match = candidate.match(dateLike);

        if (match) {
            let [, month, day, year] = match.map((element: string) => parseInt(element));
            year = this.bumpTwoDigitYears(year);

            return moment([year, month - 1, day]).toDate();
        } else {
            return moment('').toDate();
        }
    }

    bumpTwoDigitYears(year: number): number {
        if (year < 100) {
            return (year > this.twoDigitYearCap) ? year + 1900 : year + 2000;
        } else {
            return year;
        }
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    registerOnTouched() { }
}
