import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { Document } from '../../../models/document/document';
import { DocumentMetaData } from '../../../models/document/document-meta-data';
import { DocumentService } from '../../../services/document.service';

@Component({
    selector: 'gol-attachments',
    templateUrl: 'attachments.component.html',
    styleUrls: ['attachments.component.css']
})

export class AttachmentsComponent implements OnInit {

    @Input() Refno: string;
    @Input() PrimaryCifNumber: string;
    @Input() Type: string;

    incompleteFields = 'Please enter a value for all required fields!';
    uploadIncomplete = false;
    updateIncomplete = false;
    uploading = false;
    uploadDocuments: Document[] = [];
    editingDocument: Document;
    files: FileList;
    uploadMessages: string[] = [];
    errorMessages: string[] = [];

    constructor(private documentService: DocumentService) { }

    ngOnInit() {
        this.uploadMessages = [];
        this.errorMessages = [];
        this.uploadDocuments = [];
    }

    onChange(event) {
        this.files = event.srcElement.files;

        for (let i = 0; i < this.files.length; i++) {
            const newDocument = this.createDocumentFromFile(this.files[i]);
            this.uploadDocuments.push(newDocument);
        }
        this.files = null;
    }

    onChangeEdit(event) {
        this.files = event.srcElement.files;
        if (this.files.length > 0) {
            this.editingDocument.File = this.files[0];
            this.editingDocument.Name = this.files[0].name.replace(/^.*[\\\/]/, '');
        }
        this.files = null;
    }

    private createDocumentFromFile(file: any): Document {
        const newDocument = new Document();
        newDocument.Name = file.name.replace(/^.*[\\\/]/, '');
        newDocument.MetaData = new DocumentMetaData();
        if (this.Refno) {
            newDocument.MetaData.Refno = this.Refno;
        }
        if (this.PrimaryCifNumber) {
            newDocument.MetaData.PrimaryCifNumber = this.PrimaryCifNumber;
        }
        newDocument.MetaData.DocumentType = this.Type;
        newDocument.MetaData.DocumentDescription = 'Needs Verification';
        newDocument.File = file;
        return newDocument;
    }

    getTitle(): string {
        let title = '';

        if (this.Refno) {
            title = 'Application ' + this.Refno;
        }

        return title;
    }

    cancel(id: number) {
        this.uploadDocuments.splice(id, 1);
    }

    cancelAll() {
        this.uploadDocuments = [];
    }

    saveAll() {
        this.uploadMessages = [];
        this.errorMessages = [];
        this.uploading = true;
        this.documentService.uploadDocuments(this.uploadDocuments)
            .then((res) => {
                res.forEach(document => {
                    if (document.DocumentId) {
                        this.uploadMessages.push('Document Saved: ' + document.Name + ' has been uploaded');
                    } else {
                        this.errorMessages.push('Issue Saving Document: ' + document.Name + ' has not been uploaded');
                    }
                });
                this.cancelAll();
                this.uploading = false;
            })
            .catch((res) => {
                this.errorMessages.push('Issue Saving Document: There was an issue saving the document/s');
                this.uploading = false;
            });
    }

    private getTodaysDate(): string {
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        return this.pad(mm, 2) + '/' + this.pad(dd, 2) + '/' + yyyy;
    }

    private pad(num: number, digits: number): string {
        return (1e15 + num + '').slice(-digits);
    }
}
