import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../app/services/user.service';
import { User } from '../../../app/models/user';

@Component({
    selector: 'gol-account-preferences',
    templateUrl: './account-preferences.component.html',
    styleUrls: ['../../../app/dashboard/preferences/account-preferences.component.css']
})
export class AccountPreferencesComponent implements OnInit {

    private user: User;

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.user = this.userService.GetUser();
    }

    get showContactInformation(): boolean {
        if (this.user.cifno) {
            return true;
        }

        return false;
    }
}
