import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TopForm } from '../form/top-form';
import { phoneMask } from '../form/reusables/phone/phone.component';
import { ContactRequest } from '../models/contact-request';
import { UserService } from '../services/user.service';
import { AccountHttpService } from '../services/account-http.service';
import { UtilitiesService } from '../services/utilities.service';

@Component({
    selector: 'gol-contact-page',
    templateUrl: 'contact-page.component.html',
    styleUrls: ['contact-page.component.css']
})

export class ContactPageComponent implements OnInit {

    contactRequest: ContactRequest;
    submissionErrors: string;
    submitted: boolean;
    loading: boolean;
    submitting: boolean;
    filteredPhoneNumbers: any[];
    phoneNumbers: any[];

    form: TopForm = new TopForm();
    name: FormControl = new FormControl('', Validators.required);
    phone: FormControl = new FormControl('', Validators.compose([Validators.maxLength(14), Validators.minLength(14), Validators.required]));
    message: FormControl = new FormControl('', Validators.required);

    phoneMask = phoneMask;

    constructor(private userService: UserService, private accountService: AccountHttpService, private utilitiesService: UtilitiesService) {
    }

    ngOnInit() {
        window.scrollTo(0, 0);

        this.contactRequest = new ContactRequest();
        this.form.addControl('name', this.name);
        this.form.addControl('phone', this.phone);
        this.form.addControl('message', this.message);

        if (this.userLoggedIn()) {
            this.checkForSubmittedContactRequest();
            this.getUserPhoneNumbers();
        }
    }

    getUserPhoneNumbers() {
        this.phoneNumbers = [];
        this.accountService.getPhoneNumbers()
            .then((phoneNumbers) => {
                phoneNumbers.forEach(phoneNumber => {
                    const raw = phoneNumber.replace(/\D/g, '');
                    this.phoneNumbers.push({ raw: raw, formatted: this.utilitiesService.formatPhoneNumber(raw) });
                });
            });
    }

    filterPhoneNumbers(event) {
        const query = event.query.replace(/\D/g, '').substring(0, 10);
        this.contactRequest.phoneNumber = this.utilitiesService.formatPhoneNumber(query);
        this.filteredPhoneNumbers = this.filterNumbers(query, this.phoneNumbers);
    }

    filterNumbers(query, phoneNumbers: any[]): any[] {
        const filtered: any[] = [];
        for (let i = 0; i < phoneNumbers.length; i++) {
            const phoneNumber = phoneNumbers[i];
            if (query.length === 0 || phoneNumber.raw.includes(query)) {
                filtered.push(phoneNumber.formatted);
            }
        }
        return filtered;
    }

    userLoggedIn() {
        const user = this.userService.GetUser();
        return user && !user.isAnonymous;
    }

    checkForSubmittedContactRequest() {
        this.loading = true;
        this.accountService.checkForSubmittedContactRequest()
            .then((result) => {
                this.submitted = result;
            })
            .catch(() => {
                this.submissionErrors = 'Service temporarily unavailable.';
            })
            .then(() => this.loading = false);
    }

    CancelForm() {
        this.contactRequest.name = undefined;
        this.contactRequest.phoneNumber = '';
        this.contactRequest.message = undefined;
        this.form.submitted = false;
        this.form.markAsPristine();
    }

    Submit() {
        this.form.submitted = true;
        if (this.form.valid) {
            this.submissionErrors = undefined;
            this.submitting = true;
            this.accountService.submitContactRequest(this.contactRequest)
                .then((errors) => {
                    if (errors) {
                        this.submissionErrors = errors;
                    } else {
                        this.submitted = true;
                    }
                })
                .catch(() => {
                    this.submissionErrors = 'We were unable to process your request at this time.';
                })
                .then(() => this.submitting = false);
        }
    }
}
