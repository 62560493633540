import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, FormControl } from '@angular/forms';
import { UtilitiesService } from '../../services/utilities.service';

const validateRange = (min, max, str): Object => {
  const numberVal = new Number(str);
  if (min && numberVal < min) {
    return {
      validateNumber: {
        valid: false,
        min: true
      }
    };
  }
  if (max && numberVal > max) {
    return {
      validateNumber: {
        valid: false,
        max: true
      }
    };
  }
  return null;
};

const validateIfNumber = (regex, str) => {
  const REGEXP = new RegExp(regex);

  return REGEXP.test(str) ? null : {
    validateNumber: {
      valid: false,
      NAN: true
    }
  };
};

export function validateNumberFactory(allowNegative: boolean, allowDecimal: number, min: number, max: number, utilities: UtilitiesService) {
  return (c: FormControl) => {
    // note the second condition must match with === because 0 == '' returns true
    if (c.value == null || c.value === '') { return null; }
    const value = utilities.clearmask(c.value);

    let retVal = null;
    let regexStr = '^[+' + (allowNegative ? '-' : '') + ']?';
    regexStr += allowDecimal
      ? '[0-9]+(\.[0-9]{0,' + allowDecimal + '})?$'
      : '[0-9]+$';

    retVal = validateIfNumber(regexStr, value);
    if (!retVal) {
      // validate range
      retVal = validateRange(min, max, value);
    }
    return retVal;
  };
}

@Directive({
  selector: '[golValidateNumber][ngModel],[validate-number][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberValidatorDirective), multi: true }
  ]
})
export class NumberValidatorDirective {

  constructor(private utilities: UtilitiesService) { }
  @Input()
  allowNegative = false;
  @Input()
  allowDecimal: number;
  @Input()
  min: number;
  @Input()
  max: number;

  validate(c: FormControl) {
    const validator = validateNumberFactory(this.allowNegative, this.allowDecimal, this.min, this.max, this.utilities);
    return validator(c);
  }
}
