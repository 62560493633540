<div class="container stage">
    <div class="row">
        <div class="col-lg-5 brand">
            <h1>Credit Application</h1>
            <div class="contact-bar">
                <div><i class="fa fa-leaf RaboWhite-icon" aria-hidden="true"></i> For Agricultural Purposes Only</div>
                <div><i class="fa fa-envelope RaboWhite-icon" aria-hidden="true"></i> Email: <a
                        href="mailto:QuickLink@RaboAg.com">QuickLink@RaboAg.com</a></div>
                <div><i class="fa fa-phone RaboWhite-icon" aria-hidden="true"></i> Phone: (888) 395-8505</div>
            </div>
        </div>
        <div class="col-lg-7 apply">
            <h2>I'm applying as an...</h2>
            <ul class="credit-choices" fxLayout="row wrap">
                <li (click)="proceedWithSingle()" fxFlex.gt-sm="30"><a>Individual</a></li>
                <li (click)="proceedWithCoborrower()" fxFlex.gt-sm="30"><div class="center-vert-and-horiz"><a>Individual with Co-Borrowers</a></div></li>
                <li (click)="proceedWithEntity()" fxFlex.gt-sm="30"><a>Entity</a></li>
            </ul>
            <div class="row help">
                <div class="col-sm-5 help-side">
                    <h3>What will I need?</h3>
                    <p>We suggest you gather the following items to complete your application:</p>
                </div>
                <div class="col-sm-7 help-items">
                    <ul>
                        <li>
                            <i class="fa fa-user" aria-hidden="true"></i><label>Personal Information</label>
                            <p>for yourself and any co-borrowers (address, date of birth, social security number, tax
                                ID, etc.)</p>
                        </li>
                        <li>
                            <i class="fa fa-file-text-o" aria-hidden="true"></i><label>Current Balance Sheet</label>
                            <p>the online application provides tools to assist you in completing a balance sheet</p>
                        </li>
                        <li>
                            <i class="fa fa-calculator" aria-hidden="true"></i><label>Revenue Information</label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>