<div class="container stage main_container">
    <gol-side-nav selectedAlias="{{pageAlias}}" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Entity Information</h1>

        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-6">
            <p class="text-small">All fields are required unless otherwise noted.</p>
        </div>
    </div>
    <div class="spacer">
        <div fxFlex.gt-md="60">
            <form ngFormModel="form" (ngSubmit)="submit()" novalidate *ngIf="application && application.entity">
                <div class="form-group">
                    <label for="legalName">Entity Legal Name</label>
                    <input name="legalName" class="form-control" [(ngModel)]="application.entity.legalName" required
                        placeholder="Entity Legal Name" #legalName="ngModel" />
                    <div class="error" *ngIf="(form.submitted || legalName.dirty) && legalName.invalid">
                        <p *ngIf="legalName.errors['required']">Required</p>
                    </div>
                </div>

                <div class="form-group">
                    <label for="taxIdNumber">Tax ID Number</label>
                    <input name="taxIdNumber" class="form-control" [(ngModel)]="application.entity.taxId" required
                        placeholder="Tax ID Number" #taxIdNumber="ngModel" pattern="[0-9]{2}-[0-9]{7}"
                        [textMask]="{mask:taxIDMask, guide:false}" minlength="10" maxlength="10" />
                    <div class="error" *ngIf="(form.submitted || taxIdNumber.dirty) && taxIdNumber.invalid">
                        <p *ngIf="taxIdNumber.errors['required']">Required</p>
                        <p *ngIf="taxIdNumber.errors['pattern']">TaxID Number is invalid. Must be in the format:
                            12-3456789.</p>
                    </div>
                </div>

                <gol-doing-business-as [application]="application" [form]="form"></gol-doing-business-as>

                <gol-corporation-component name="entityType" [(ngModel)]="application.entity.entityType" [form]="form">
                </gol-corporation-component>

                <gol-phone-component name="entityPhone" [(ngModel)]="application.entity.phoneNumber"
                    Title="Phone Number" [form]="form"></gol-phone-component>

                <gol-state-select name="stateOfOrganization" [(ngModel)]="application.entity.stateOfOrganization"
                    Title="State of Organization" [form]="form"></gol-state-select>

                <div class="form-group">
                    <gol-date-box [(ngModel)]="application.entity.dateOfOrganization" label="Date of Organization"
                        golDateRangeValidator [min]="minDateOfOrg" [max]="today" required name="orgDate"
                        #orgDate="ngModel"></gol-date-box>
                    <div *ngIf="(form.submitted || orgDate.dirty) && orgDate.invalid" class="error">
                        <p *ngIf="orgDate.errors['required']">A date is required</p>
                        <p *ngIf="orgDate.errors['invalidDate']">That is not a valid date</p>
                        <p *ngIf="orgDate.errors['precedesMin']">Please select a date after
                            {{orgDate.errors['precedesMin'].toDateString()}}</p>
                        <p *ngIf="orgDate.errors['exceedsMax']">Please select a date before
                            {{orgDate.errors['exceedsMax'].toDateString()}}</p>
                    </div>
                </div>
                <gol-combined-address-component name="combAddress"
                    [(physicalAddress)]="application.entity.physicalAddress"
                    [(mailingAddress)]="application.entity.mailingAddress" [form]="form">
                </gol-combined-address-component>
                <div class="buttonContainer">
                    <button class="btn btn-primary" type="submit">Continue</button>
                </div>
            </form>
        </div>
        <div class="col-sm-1"></div>
        <gol-contact-component></gol-contact-component>
    </div>
    <div style="clear:both"></div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>