import * as _ from 'lodash';
import { Address } from './address';
import { FullName } from './fullname';
import { Passport } from './passport';

export class Person {
    isBorrower = true;
    fullName: FullName = new FullName();
    ownershipPercentage = 0;
    ssn = '';
    dateOfBirth: Date;
    physicalAddress: Address = new Address(false);
    isUSCitizen = null;
    isPermanentResident = null;
    countryOfCitizenship = '';
    countryOfPermanentResidence = '';
    email = '';
    phoneNumber = '';
    alternatePhoneNumber = '';
    mailingAddress: Address = new Address(true);
    hasBankruptcy = false;
    bankruptcyFilingDate: Date;
    bankruptcyDischargeDate: Date;
    isPreApproved = false;
    isControllingParty = false;
    signerTitle: string;
    passport: Passport = new Passport();

    constructor(appData?: any) {
        if (appData) {
            this.alternatePhoneNumber = _.isNil(appData.AlternatePhoneNumber) ? '' : appData.AlternatePhoneNumber;
            this.bankruptcyDischargeDate = appData.BankruptcyDischargeDate ? new Date(appData.BankruptcyDischargeDate) : null;
            this.bankruptcyFilingDate = appData.BankruptcyFilingDate ? new Date(appData.BankruptcyFilingDate) : null;
            this.countryOfCitizenship = _.isNil(appData.CountryOfCitizenship) ? '' : appData.CountryOfCitizenship;
            this.countryOfPermanentResidence = _.isNil(appData.CountryOfPermanentResidence) ? '' : appData.CountryOfPermanentResidence;
            this.dateOfBirth = appData.DateOfBirth ? new Date(appData.DateOfBirth) : null;
            this.email = _.isNil(appData.Email) ? '' : appData.Email;
            this.fullName = new FullName(appData.FullName);
            this.hasBankruptcy = appData.HasBankruptcy;
            this.isPermanentResident = appData.IsPermanentResident;
            this.isUSCitizen = appData.IsUSCitizen;
            this.mailingAddress = new Address(true, appData.MailingAddress);
            this.ownershipPercentage = appData.OwnershipPercentage;
            this.phoneNumber = _.isNil(appData.PhoneNumber) ? '' : appData.PhoneNumber;
            this.physicalAddress = new Address(false, appData.PhysicalAddress);
            this.ssn = _.isNil(appData.SSN) ? '' : appData.SSN;
            this.isControllingParty = appData.IsControllingParty;
            this.signerTitle = appData.SignerTitle;
            this.passport = new Passport(appData.Passport);
            this.isPreApproved = appData.isPreApproved;
        }
    }
}
