import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { CurrentApplicationService } from '../../services/current-application.service';
import { AbstractFormPage } from '../abstractpage';
import { Person } from '../../models/person';
import { TopForm } from '../top-form';
import { UtilitiesService } from '../../services/utilities.service';
import { HistoryService } from '../../services/history.service';
import { NotificationService } from 'if-angular-core';

@Component({
    selector: 'gol-ownership',
    templateUrl: 'ownership.component.html',
    styleUrls: ['../styles_form.css', 'ownership.component.css'],
})
export class OwnershipComponent extends AbstractFormPage implements OnInit {
    formGroups: FormGroup[];
    form: TopForm;
    signerGroup: FormGroup = new FormGroup({});

    signerSelect: Person = null;
    isOpen: boolean[];
    accordionMap: number[];

    get showOwnershipAdd(): boolean {
        return (this.application.hasEntityOwnership === true && this.application.hasTrust === true && this.application.hasIosWithPercent === true)
        || (this.application.hasEntityOwnership === true && this.application.hasTrust == false && this.application.hasIndividualsWithPercent == true)
        || (this.application.hasEntityOwnership === false && this.application.hasIndividualsWithPercent === true);
    }

    get showControllingPartyAdd(): boolean {
        return (this.application.hasEntityOwnership === true && this.application.hasTrust === true && this.application.hasIosWithPercent === false)
        || (this.application.hasEntityOwnership === true && this.application.hasTrust == false && this.application.hasIndividualsWithPercent == false)
        || (this.application.hasEntityOwnership === false && this.application.hasIndividualsWithPercent === false);
    }

    get possibleSigners(): Person[] {
        return this.application.borrowers.concat(this.application.additionalOwners);
    }

    get areAllInitialQuestionsAnswered(): boolean {
        return this.showOwnershipAdd || this.showControllingPartyAdd;
    }

    constructor(currentAppSvc: CurrentApplicationService, router: Router, utilities: UtilitiesService, history: HistoryService, notificationService: NotificationService) {
        super(currentAppSvc, router, utilities, history, notificationService);
        this.pageAlias = 'ownership';
        this.isOpen = [];
        this.formGroups = [];
        this.accordionMap = [];
        this.initPage();
    }

    ngOnInit(): void { }

    afterInitPage(): void {
        this.form = new TopForm();
        for (let i = 0; i < this.application.ownersList.length; ++i) {
            this.isOpen.push(false);
            this.accordionMap.push(i);
            const group = new FormGroup({});
            this.formGroups.push(group);
            this.form.addControl(this.accordionMap.length.toString(), group);
        }

        // Check for signer
        if (this.application.entity.signer) {
            // Signer could be borrower/owner
            const match = this.application.ownersList.find((o) => {
                return (o.fullName.toString() === this.application.entity.signer.fullName.toString() &&
                    o.email === this.application.entity.signer.email)
            });
            if (match != null) {
                this.signerSelect = match;
            } else {
                this.signerSelect = null;
            }
        }
    }

    private closeAllTabs(): void {
        for (let i = 0; i < this.isOpen.length; ++i) {
            this.isOpen[i] = false;
        }
    }

    private hasNonWhitespace(s: string) {
        return /[^\s]/g.test(s);
    }

    private saveSignerOnApplication(): void {
        if (!!this.signerSelect) {
            this.application.entity.signer = this.signerSelect;
            this.application.entity.signerTitle = this.signerSelect.signerTitle;
        }
    }

    public onSignerChange(): void {
        this.form.submitted = false;
        this.saveSignerOnApplication();
    }


    public add(isControllingParty: boolean = false): void {
        this.form.submitted = false;
        const p = new Person();
        p.isBorrower = false;
        p.isControllingParty = isControllingParty;
        this.application.additionalOwners.push(p);
        this.closeAllTabs();
        this.accordionMap.push(this.isOpen.length);
        this.isOpen.push(true);
        const group = new FormGroup({});
        this.formGroups.push(group);
        this.form.addControl(this.accordionMap.length.toString(), group);
        window.scrollTo(0, 0);
    }

    public delete(index: number, event: PointerEvent): void {
        const additionalOwnersIndex = index - this.application.borrowers.length;
        this.application.additionalOwners.splice(additionalOwnersIndex, 1);
        this.isOpen.splice(index, 1);
        this.formGroups.splice(index, 1);
        let found = null;
        this.accordionMap = this.accordionMap.map((mapping, idx) => {
            if (mapping === index) {

                // If signer is deleted, clear signer details
                if (!!this.application.entity && !!this.application.entity.signer
                    && index === this.application.additionalOwners.findIndex(x => x.fullName.toString() == this.signerSelect.fullName.toString()
                        && x.email === this.application.entity.signer.email)) {
                    this.signerSelect = null;
                    this.application.entity.signer = null;
                    this.application.entity.signerTitle = '';
                }

                found = true;
                this.form.removeControl((idx + 1).toString());
                return -1;
            } else {
                return found ? mapping - 1 : mapping;
            }
        });
        event.preventDefault();

        if (this.application.ownersList.length === 0) {
            this.add();
        }
    }

    public tabOpen(e: number): void {
        this.isOpen[this.accordionMap[e]] = true;
    }

    public tabClose(e: number): void {
        this.isOpen[this.accordionMap[e]] = false;
    }

    public showDisplayName(owner: Person): boolean {
        return this.hasNonWhitespace(owner.fullName.toString());
    }

    public isTotalOwnershipValid(): boolean {
        const ownerPercent = this.application.totalOwnership();
        return !(ownerPercent > 100);
    }

    public onEntityChanged(newValue: boolean){
        if (newValue) {
            this.application.hasIndividualsWithPercent = null;
            this.application.hasEntityOwnership = true;
            return;
        }
        if (newValue == undefined) {
            this.application.hasEntityOwnership = null;
            this.application.hasIndividualsWithPercent = null;
            this.application.hasTrust = null;
            this.application.hasIosWithPercent = null;
            return;
        }
        if (!newValue){
            this.application.hasEntityOwnership = false;
            this.application.hasTrust = null;
            this.application.hasIosWithPercent = null;
        }
    }

    public onTrustChanged(newValue: boolean){
        if (newValue) {
            this.application.hasIndividualsWithPercent = null;
            this.application.hasTrust = true;
            return;
        }
        if (newValue == undefined) {
            this.application.hasIndividualsWithPercent = null;
            this.application.hasTrust = null;
            this.application.hasIosWithPercent = null;
            return;
        }
        if (!newValue){
            this.application.hasTrust = false;
            this.application.hasIosWithPercent = null;
            return;
        }
    }

    isValidForm() {
        this.form.updateValueAndValidity();
        this.form.submitted = true;
        this.saveSignerOnApplication();

        if (this.form.valid
            && this.isTotalOwnershipValid()
            && this.signerSelect !== null
            && this.areAllInitialQuestionsAnswered) {
            return true;
        } else {
            this.scrollToError();
            return false;
        }
    }

    public submit(): void {
        if (this.isValidForm()) {
            this.next();
        } else {
            this.scrollToError();
        }
    }

    public previous() {
        if (this.isValidForm()) {
            this.back();
        }
    }

    //validate form and save (before navigating away)
    validateSaveFunction(newPageAlias: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const comp = this;
            if (comp.isValidForm()) {
                comp.application.currentPage = newPageAlias;
                resolve(comp.save());
            } else {
                reject(false);
            }
        });
    }
}
