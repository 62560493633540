import { Injectable } from '@angular/core';

@Injectable()
export class HistoryService {
    private history: string[] = [];

    public forward(path: string) {
        if (this.history.length === 0) {
            this.init(path);
        } else {
            this.history.push(path);
        }
    }

    public back(): string {
        if(this.history.length > 1) {
            this.history.pop();
        }
        return this.getCurrent();
    }

    public isEmpty() {
        return this.history.length < 1;
    }

    getCurrent() {
        return this.history[this.history.length - 1];
    }

    public init(path: string) {
        if (path.length === 0) {
            this.history = [];
        } else {
            this.history = [path];
        }
    }

    public Count(): number {
        return this.history.length;
    }
}
