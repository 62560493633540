import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../../../../app/models/user';
import { TopForm } from '../../../../app/form/top-form';
import { UserService } from '../../../../app/services/user.service';
import { AuthService } from '../../../services/auth.service';
import { ContactDetail } from '../../../../app/models/accounts/contact-detail';

@Component({
    selector: 'gol-preferences-name',
    templateUrl: '../../../../app/dashboard/preferences/name/preferences-name.component.html',
    styleUrls: ['../../../../app/dashboard/preferences/account-preferences.component.css']
})
export class PreferencesNameComponent implements OnInit {
    contactInfoForm: TopForm;
    formGroup: FormGroup;
    user: User;
    contactInfoChangeComplete = false;
    updateInfoError: string;
    contactDetails: ContactDetail;
    loading: boolean;
    error: boolean;


    constructor(private userService: UserService, private authService: AuthService) {
    }

    ngOnInit() {
        this.contactInfoForm = new TopForm({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
        });

        this.formGroup = new FormGroup({});
        this.user = this.userService.GetUser();
    }

    contactInfoSubmit() {
        this.authService.UpdateUser(this.user)
            .then(res => {
                this.contactInfoChangeComplete = true;
                this.updateInfoError = null;
            })
            .catch(rejected => {
                this.updateInfoError = rejected;
            });
    }
}
