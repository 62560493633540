import { Component, Input, AfterViewInit, ViewChildren, QueryList, OnInit } from '@angular/core';
import { NgModel, FormGroup } from '@angular/forms';
import { Person } from '../../models/person';
import { TopForm } from '../top-form';
import { hundredWithDecimalMask } from '../validation/custom-masks';
import * as moment from 'moment';
import { LookupService } from '../../services/lookup.service';

@Component({
    selector: 'gol-owner-detail',
    templateUrl: 'owner-detail.component.html',
    styleUrls: ['owner-detail.component.css']
})
export class OwnerDetailComponent implements OnInit, AfterViewInit {
    hundredMask = hundredWithDecimalMask;

    @ViewChildren(NgModel) inputChildren: QueryList<NgModel>;

    @Input() form: TopForm;
    @Input() group: FormGroup;
    @Input() readonly: boolean;
    @Input() mode = '';
    @Input() index: number;
    @Input() alreadyAddedOwners: Person[] = null;
    @Input() owner: Person = new Person();
    @Input() assumeIsControllingParty = false;

    maxDob: Date = moment().subtract(18, 'years').toDate();
    minDob: Date = new Date(1880, 0, 1);
    addressCountries: string[] = null;

    constructor(
        private lookupService: LookupService
    ) {}
    
    ngOnInit(): void {
        this.lookupService.getPermanentResidenceCountries()
            .then(resolve => {
                this.addressCountries = [''].concat(resolve);
            });
    }

    ngAfterViewInit(): void {
        if (this.group) {
            this.inputChildren.forEach((item) => {
                if (!this.group.contains(item.name)) {
                    this.group.addControl(item.name, item.control);
                }
            });
        }
    }

    public getTitlePrefix(): string {
        return (this.mode === 'borrower') ? 'Owner\'s ' : '';
    }

    public reAddConditionalControls(): void {
        setTimeout(() => {
            this.inputChildren.forEach((item) => {
                if (!this.group.contains(item.name)) {
                    this.group.addControl(item.name, item.control);
                }
            });
        });
    }
}
