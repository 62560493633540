import * as moment from 'moment';
type Page = { alias: string, name: string, route: string };

export class NavigationOptionsRegistration {

  public static Pages: Page[] = [
    { alias: "Profile", name: 'Profile', route: "/registration" },
    { alias: "Account", name: 'Account', route: "/registration/registration-linkaccount" },
    { alias: "Security", name: 'Security', route: "/registration/registration-security" },
    { alias: "Agreement", name: 'Agreement', route: "/registration/registration-agreement" },
    { alias: "Summary", name: 'Summary', route: "/registration/registration-summary" }
  ];

  public static RegistrationPages(): Page[] {
    return this.Pages;
  }
}
