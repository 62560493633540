import { Component, OnInit } from '@angular/core';

import { User } from '../../../models/user';
import { ContactDetail } from '../../../models/accounts/contact-detail';
import { TopForm } from '../../../form/top-form';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { AccountHttpService } from '../../../services/account-http.service';

@Component({
    selector: 'gol-preferences-alerts',
    templateUrl: 'preferences-alerts.component.html',
    styleUrls: ['../account-preferences.component.css', 'preferences-alerts.component.css']
})
export class PreferencesAlertsComponent implements OnInit {

    user: User;
    contactDetails: ContactDetail;
    loading: boolean;
    alertChangeComplete: boolean;
    newNumber: string;
    form: TopForm;
    textNumberError: boolean;
    newNumberRadio = 'newNumberRadio';
    error: boolean;

    constructor(private userService: UserService, private authService: AuthService, private accountService: AccountHttpService) {
        this.form = new TopForm();
    }

    ngOnInit() {
        this.getUserData();
        window.scrollTo(0, 0);
    }

    getUserData() {
        this.loading = true;
        this.accountService.getCustomerContactPreferences()
            .then((result) => {
                this.contactDetails = result;
            })
            .catch(() => {
                this.error = true;
            })
            .then(() => {
                this.loading = false;
            });
    }

    update() {
        const valid = this.verifyTextNumber();
        if (valid) {
            this.textNumberError = false;
            this.loading = true;
            this.accountService.updateCustomerContactPreferences(this.contactDetails)
                .then((result) => {
                    this.contactDetails = result;
                    this.alertChangeComplete = true;
                })
                .catch(() => {
                    this.error = true;
                })
                .then(() => {
                    this.loading = false;
                });
        } else {
            this.textNumberError = true;
        }
    }

    verifyTextNumber(): boolean {
        if (!this.contactDetails.UserPreferences.TextOptIn) {
            return true;
        }

        if (this.contactDetails.UserPreferences.PhoneNumber !== null
            && this.contactDetails.UserPreferences.PhoneNumber === this.newNumberRadio) {
            if (this.form.valid) {
                this.contactDetails.UserPreferences.PhoneNumber = this.newNumber;
                return true;
            } else {
                return false;
            }
        }

        if (this.contactDetails.UserPreferences.PhoneNumber == null || this.contactDetails.UserPreferences.PhoneNumber.length < 10) {
            return false;
        }

        return true;
    }

    resetForm() {
        this.newNumber = '';
        this.alertChangeComplete = false;
        window.scrollTo(0, 0);
    }
}
