import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

export function dateRangeValidator(min: Date, max: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const date: Date = control.value;
        const invalid = /invalid/i;
        let validity: any = null;

        if (date && (!(date instanceof Date) || invalid.test(date.toString()))) {
            validity = { 'invalidDate': true };
        } else if (max instanceof Date && date > max) {
            validity = { 'exceedsMax': max };
        } else if (min instanceof Date && date < min) {
            validity = { 'precedesMin': min };
        }

        return validity;
    };
}

@Directive({
    selector: '[golDateRangeValidator][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateRangeValidatorDirective, multi: true }]
})
export class DateRangeValidatorDirective implements Validator, OnChanges {
    @Input() min: any;
    @Input() max: any;
    private validatorFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.min || this.max) {
            if (this.min) {
                this.min.setHours(0, 0, 0, 0);
            }
            if (this.max) {
                this.max.setHours(0, 0, 0, 0);
            }

            this.validatorFn = dateRangeValidator(this.min, this.max);
        } else {
            this.validatorFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.validatorFn(control);
    }
}
