<div class="container stage main_container">
    <gol-side-nav selectedAlias="{{pageAlias}}" [validateSave]="validateSaveFunction.bind(this)"></gol-side-nav>
    <div class="row">
        <div class="col-sm-7">
            <h1>Financial Information - Income</h1>
        </div>
        <div class="col-sm-5 change-app">
            <gol-change-application-type-component></gol-change-application-type-component>
        </div>
    </div>
    <hr />
    <div>
        <div class="row">
            <div class="col-sm-6">
                <p class="text-small">All fields are required unless otherwise noted.</p>
            </div>
        </div>
        <div *ngIf="application" class="panel-component">
            <div class="row">
                <div class="col-md-6">
                    <h2>What do you grow?</h2>
                    <form (ngSubmit)="addCrop()" [formGroup]="crops.form" class="data-entry group" novalidate>
                        <ul *ngIf="crops.current">
                            <li class="group">
                                <label>Crop <gol-help-tip>Select an option from the list or type in a crop if it is not
                                        listed.</gol-help-tip></label>
                                <div class="autoCompleteWrapper">
                                    <gol-autocomplete DisplayTitle="Crop" [itemFactory]="cropFactory" [maxSize]="4"
                                        [form]="crops.form" [group]="crops.form" [value]="crops.cropName"
                                        [update]="crops.setCropName()" [showErrors]="false"></gol-autocomplete>
                                    <span *ngIf="crops.form.submitted && !crops.cropName" class="error">A crop is
                                        required</span>
                                </div>
                            </li>
                            <li class="group">
                                <label>Acres</label>
                                <input type="text" formControlName="acres" class="form-control"
                                    [ngModel]="crops.current.acres"
                                    (ngModelChange)="crops.current.acres = toNumber($event)" validate-number
                                    [textMask]="{mask: numbersOnlyMask, guide:false}">
                                <span *ngIf="crops.form.submitted && !crops.form.controls.acres.valid"
                                    class="error">Acres are required</span>
                            </li>
                            <li class="data-entry-yield group">
                                <label>Yield</label>
                                <input type="text" formControlName="yield" class="form-control"
                                    [ngModel]="crops.current.averageYield"
                                    (ngModelChange)="crops.current.averageYield = toNumber($event)" validate-number
                                    [textMask]="{mask: numbersOnlyMask, guide:false}">
                                <div class="autoCompleteWrapper units">
                                    <gol-autocomplete DisplayTitle="Units" [itemFactory]="yieldUnitsFactory"
                                        [maxSize]="4" [form]="crops.form" [group]="crops.form"
                                        [value]="crops.cropYieldUnits" [update]="crops.setYieldUnits()"
                                        [showErrors]="false"></gol-autocomplete>
                                </div>
                                <span *ngIf="crops.form.submitted && !crops.cropYieldUnits" class="error">Yield units
                                    are required</span>
                            </li>
                            <li class="group">
                                <label>Price</label>
                                <div class="autoCompleteWrapper">
                                    <gol-number-input formControlName="price" [(ngModel)]="crops.current.price"
                                        #cropPrice>
                                    </gol-number-input>
                                    <span
                                        *ngIf="crops.form.submitted && financialValidation.hasNoValueError($any(crops.form.controls['price']))"
                                        class="error">A price is required</span>
                                    <span
                                        *ngIf="crops.form.submitted && financialValidation.hasOutOfRangePriceError($any(crops.form.controls['price']))"
                                        class="error">Price must be less than
                                        {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                                </div>
                            </li>
                        </ul>
                        <button class="btn btn-primary">{{crops.editingCrop ? 'Update' : 'Add'}}</button>
                    </form>
                </div>
                <div class="col-md-6">
                    <ul *ngIf="application && application.income" class="financial-table">
                        <li class="header group">
                            <label>Crop</label>
                            <label>Revenue</label>
                        </li>
                        <li *ngFor="let crop of application.income.cropList" (click)="cropListUpdate(crop)"
                            [class.highlight]="crops.editingCrop === crop" class="group" title="Click to edit">
                            <label><i class="fa fa-trash" (click)="cropListRemove(crop)" title="Click to delete"></i><i
                                    class="fa fa-pencil"></i>{{crop.name}}</label>
                            <span>{{crop.revenue | currency:'USD':'symbol':'1.2-2'}}</span>
                        </li>
                        <li *ngIf="application.income.cropList && application.income.cropList.length === 0"
                            class="empty">No crops added</li>
                        <li *ngIf="application.income.cropList && application.income.cropList.length > 0"
                            class="total group" [class.error]="totalCropIncomeInvalid">
                            <label>Gross Crop Income</label>
                            <span>{{(application.income.grossCropIncome || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                            <span *ngIf="totalCropIncomeInvalid" class="error">Total must be less than
                                {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="application" class="panel-component">
            <div class="row">
                <div class="col-md-6">
                    <h2>What livestock do you raise?</h2>
                    <form (ngSubmit)="addLivestock()" [formGroup]="livestock.form"
                        class="data-entry data-entry-livestock group" novalidate>
                        <ul *ngIf="livestock.current">
                            <li>
                                <div class="group"><label>Livestock <gol-help-tip>Select an option from the list or
                                            enter a
                                            type of livestock if it is not listed.</gol-help-tip></label></div>
                                <div class="autoCompleteWrapper">
                                    <gol-autocomplete DisplayTitle="Livestock" [itemFactory]="livestockFactory"
                                        [maxSize]="4" [form]="livestock.form"
                                        [value]="livestock.livestockName" [update]="livestock.setLivestockName()"
                                        [showErrors]="false"></gol-autocomplete>
                                </div>
                                <span *ngIf="livestock.form.submitted && !livestock.livestockName"
                                    class="error">Livestock is required</span>
                            </li>
                            <li class="group">
                                <label>Head Count</label>
                                <input type="text" formControlName="count" class="form-control"
                                    [ngModel]="livestock.current.count"
                                    (ngModelChange)="livestock.current.count = toNumber($event)" validate-number
                                    [textMask]="{mask: numbersOnlyMask, guide:false}">
                                <span *ngIf="livestock.form.submitted && !livestock.form.controls.count.valid"
                                    class="error">Head count is required</span>
                            </li>
                            <li class="group">
                                <label>Value per Head</label>
                                <gol-number-input formControlName="price" [(ngModel)]="livestock.current.price"
                                    #livestockPrice></gol-number-input>
                                <span
                                    *ngIf="livestock.form.submitted && financialValidation.hasNoValueError($any(livestock.form.controls['price']))"
                                    class="error">Value per head is required</span>
                                <span
                                    *ngIf="livestock.form.submitted && financialValidation.hasOutOfRangePriceError($any(livestock.form.controls['price']))"
                                    class="error">Value must be less than
                                    {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                            </li>
                        </ul>
                        <button class="btn btn-primary">{{livestock.editingLivestock ? 'Update' : 'Add'}}</button>
                    </form>
                </div>
                <div class="col-md-6">
                    <ul *ngIf="application && application.income" class="financial-table">
                        <li class="header group">
                            <label>Livestock</label>
                            <label>Revenue</label>
                        </li>
                        <li *ngFor="let stock of application.income.livestockList" (click)="livestockListUpdate(stock)"
                            [class.highlight]="livestock.editingLivestock === stock" class="group"
                            title="Click to edit">
                            <label><i class="fa fa-trash" (click)="livestockListRemove(stock)"
                                    title="Click to delete"></i><i class="fa fa-pencil"></i>{{stock.name}}</label>
                            <span>{{stock.revenue | currency:'USD':'symbol':'1.2-2'}}</span>
                        </li>
                        <li *ngIf="application.income.livestockList && application.income.livestockList.length === 0"
                            class="empty">No livestock added</li>
                        <li *ngIf="application.income.livestockList && application.income.livestockList.length > 0"
                            class="total group" [class.error]="totalLivestockIncomeInvalid">
                            <label>Gross Livestock Income</label>
                            <span>{{(application.income.grossLivestockIncome || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                            <span *ngIf="totalLivestockIncomeInvalid" class="error">Total must be less than
                                {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="application" class="panel-component otherFarmIncome">
            <h2>Other Farm Income</h2>
            <p class="sub-text">(Milk proceeds, custom work, etc.)</p>
            <form [formGroup]="otherFarmIncomeForm" novalidate>
                <gol-number-input formControlName="otherFarmIncomeControl"
                    [(ngModel)]="application.income.otherFarmIncome">
                </gol-number-input>
                <span *ngIf="financialValidation.hasNoValueError(otherFarmIncomeControl)" class="error">Value per head
                    is required</span>
                <span *ngIf="financialValidation.hasInvalidNumberError(otherFarmIncomeControl)" class="error">Value is
                    not a whole number or is not a valid number.</span>
                <span *ngIf="financialValidation.hasOutOfRangePriceError(otherFarmIncomeControl)" class="error">Income
                    must be less than {{totalIncomeMax | currency:'USD':true:'1.2-2'}}</span>
            </form>
        </div>

        <div *ngIf="application" class="panel-component">
            <h2>Total Income</h2>
            <div class="panel-component-inner">
                <ul class="financial-table financial-table-summary">
                    <li class="header group">
                        <label>Income Type</label>
                        <label>Amount</label>
                    </li>
                    <li class="group">
                        <label>Gross Crop</label>
                        <span
                            [class.error]="totalCropIncomeInvalid">{{(application.income.grossCropIncome || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                    </li>
                    <li class="group">
                        <label>Gross Livestock</label>
                        <span
                            [class.error]="totalLivestockIncomeInvalid">{{(application.income.grossLivestockIncome || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                    </li>
                    <li class="group">
                        <label>Other Farm</label>
                        <span
                            [class.error]="totalOtherIncomeInvalid">{{(application.income.otherFarmIncome || 0) | currency:'USD':'symbol':'1.2-2'}}</span>
                    </li>
                    <li class="total group" [class.error]="totalIncomeIsInvalid">
                        <label>Total</label>
                        <span>{{application.income.totalFarmIncome || 0 | currency:'USD':'symbol':'1.2-2'}}</span>
                    </li>
                    <li *ngIf="totalIncomeIsInvalid" class="group error">
                        Warning: Total income must be less than {{totalIncomeMax | currency:'USD':true:'1.2-2'}}
                    </li>
                </ul>
                <div class="buttonContainer group" fxLayoutAlign="space-evenly">
                    <button type="button" class="btn btn-secondary back-btn" (click)="back()" fxFlex.lt-md="40">Back</button>
                    <button type="button" class="btn btn-primary" (click)="next()" fxFlex.lt-md="40">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <if-notification defaultLife="12000"></if-notification>
</div>